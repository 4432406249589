import Select from "../../common/Select/Select";

type PriceCorrectionProps = {
  label?: string;
  withDescription?: boolean;
  withSelect?: {
    label: string;
    value: any;
    note?: string;
    initialValue?: number;
  }[];
  className?: string;
  data: {
    description?: string;
    value: number;
    id?: string;
    initialValue?: number;
    type: "number" | "percent";
  };
  onChange: (data: {
    value: number;
    type: "number" | "percent";
    id?: string;
    initialValue?: number;
    description?: string;
  }) => void;
  size?: "sm" | "md" | "lg";
};

const getSizeMapping = (size: "sm" | "md" | "lg") => {
  switch (size) {
    case "sm":
      return "col-4";
    case "md":
      return "col-6";
    case "lg":
      return "col-8";
    default:
      return "col-4";
  }
};

const PriceCorrection = ({
  data,
  onChange,
  label,
  withDescription,
  withSelect,
  className,
  size = "sm",
}: PriceCorrectionProps) => {
  const handlePriceIncreaseType = (e: any) => {
    const type = e.target.value;
    onChange({ ...data, type });
  };

  const handlePriceIncrease = (e: any) => {
    const value = Number(e.target.value);
    onChange({ ...data, value });
  };
  const handleDescription = (e: any) => {
    const description = e.target.value;
    onChange({ ...data, description });
  };

  const idx = `priceIncreaseType-${Math.random()}`;
  const percentOptionIdx = `price-option-${Math.random()}`;
  const valueOptionIdx = `value-option-${Math.random()}`;

  return (
    <div className={`row ${className ? className : ""}`}>
      <div className="col">
        <div className="row">
          <div className="col">
            {label && (
              <label htmlFor={idx} className="mb-1">
                {label}
              </label>
            )}
          </div>
        </div>

        {withSelect && (
          <div className="row mb-2">
            <div className="col">
              <Select
                label="Вид"
                id="equipment"
                options={[{ label: "Избери", value: "-1" }, ...withSelect]}
                value={data.id || "-1"}
                onChange={(selectedValue, label) => {
                  const item = withSelect.find(
                    (item) => Number(item.value) === Number(selectedValue)
                  );
                  onChange({
                    ...data,
                    id: selectedValue,
                    description: label,
                    value: item?.initialValue || 0,
                  });
                }}
              />
            </div>
          </div>
        )}
        {withDescription && (
          <div className="row mb-2">
            <div className="col">
              <input
                type="text"
                value={data.description}
                placeholder="Описание"
                onChange={handleDescription}
                className="form-control"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className={getSizeMapping(size)}>
            <div className="form-group">
              <input
                id={idx}
                type="number"
                value={data.value}
                onChange={handlePriceIncrease}
                onFocus={(e) => e.target.select()}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-auto d-flex">
            <div className="d-flex align-items-end pb-2">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  value="number"
                  type="radio"
                  checked={data.type === "number"}
                  onChange={handlePriceIncreaseType}
                  id={valueOptionIdx}
                />
                <label className="form-check-label" htmlFor={valueOptionIdx}>
                  BGN
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="percent"
                  checked={data.type === "percent"}
                  onChange={handlePriceIncreaseType}
                  id={percentOptionIdx}
                />
                <label className="form-check-label" htmlFor={percentOptionIdx}>
                  %
                </label>
              </div>
            </div>
          </div>
          {withSelect &&
            data.id !== "-1" &&
            withSelect.find((s) => Number(s.value) === Number(data.id))
              ?.note && (
              <p className="mt-3 mb-0 text-muted">
                {
                  withSelect.find((s) => Number(s.value) === Number(data.id))
                    ?.note
                }
              </p>
            )}
        </div>
      </div>
    </div>
  );
};

export default PriceCorrection;
