import { useState, useEffect, useCallback } from "react";
import {
  Select,
  Button,
  IconLabel,
  Datepicker,
  OverlayLoader,
} from "../../components/common";
import {
  chargeProduct,
  getEvaluationData,
  openPDFGeneration,
  saveEvaluation,
  uploadFiles,
} from "../../api/main";
import { parseVehicle } from "../../utils/vehicles";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Summary from "./components/Summary";
import ImagesUpload from "./components/ImagesUpload/ImagesUpload";

const buildCorrectionOptions = (min: number, max: number, step = 5) => {
  const options = [];
  for (let i = max; i >= min; i -= step) {
    options.push({ value: String(i), label: `${i}%` });
  }
  return options;
};

type EvalCriteriaT = {
  price_id?: number;
  vin?: string;
  registration_number?: string;
  evaluation_data?: Date;
  first_registration_date?: Date;
  starting_price?: number;
  general_correction?: number;
};

type EvaluationProps = {
  priceId: number;
  evalCost: string | JSX.Element;
  automaticCharge?: boolean;
  vin?: string;
  regNumber?: string;
  onCharge?: () => void;
  onError?: (message: string) => void;
  correctionRange?: {
    min: number;
    max: number;
  };
};
const PRODUCT_NAME = "ins-value";

const Evaluation = ({
  priceId,
  evalCost,
  vin,
  regNumber,
  onError,
  onCharge,
  automaticCharge,
  correctionRange = { min: -40, max: 20 },
}: EvaluationProps) => {
  const [evalCriteria, setEvalCriteria] = useState<EvalCriteriaT>({
    price_id: priceId,
    vin: vin,
    first_registration_date: new Date(),
    registration_number: regNumber,
    general_correction: 0,
  });
  const correctionOptions = useCallback(() => {
    return buildCorrectionOptions(
      Number(correctionRange.min),
      Number(correctionRange.max)
    );
  }, [correctionRange]);

  const [evaluationData, setEvaluationData] = useState<any>({});

  const [archiveId, setArchiveId] = useState<number>();
  const [selectedFiles, setSelectedFiles] = useState<{
    saved: boolean;
    files: File[] | null;
    uploadedFileIds: number[];
  }>({
    saved: false,
    files: null,
    uploadedFileIds: [],
  });
  const [printingPDF, setPrintingPDF] = useState<boolean>(false);
  // NOTE: this is used to determine the version of the pdf
  // ONLY TEMPORARY REMOVE WHEN ANTONI SHOWS THE DEMO
  const [pdfVersion, setPdfVersion] = useState<string>("v1");

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);

  const saveEvaluationData = async (pdfV: string) => {
    setPrintingPDF(() => true);
    const recalculatedData = await recalculate();
    let uploadFilesIds: number[] = selectedFiles.uploadedFileIds;
    if (!selectedFiles.saved && selectedFiles.files?.length) {
      const uploadResponse = await uploadFiles(selectedFiles.files);
      uploadFilesIds = [...uploadResponse.uploaded_files_ids];
      setSelectedFiles(() => ({
        saved: true,
        files: null,
        uploadedFileIds: uploadFilesIds,
      }));
    }
    const { archive } = await saveEvaluation(
      PRODUCT_NAME,
      {
        ...recalculatedData,
        version: pdfV,
        additional_data: {
          image_ids: uploadFilesIds,
        },
      },
      archiveId
    );
    setArchiveId(() => archive.id);
    setPrintingPDF(() => false);
    return archive.id;
  };

  const recalculate = async () => {
    const response = await getEvaluationData(
      PRODUCT_NAME,
      String(priceId),
      evalCriteria
    );
    setEvaluationData(() => response.evaluation);
    return {
      eval_criteria: evalCriteria,
      eval_summary: response.evaluation,
    };
  };

  useEffect(() => {
    recalculate();
  }, [evalCriteria]);

  const fetchEvaldata = async () => {
    const response = await getEvaluationData(PRODUCT_NAME, String(priceId));

    const { vehicle } = response;
    const parsedData = parseVehicle(vehicle);
    const publishDate = new Date(parsedData.prices[0].publishedAt);
    const publishYear = publishDate.getFullYear();
    const firstRegistrationDate = new Date(publishYear, 6, 1);
    setEvaluationData(() => response.evaluation);
    setEvalCriteria((prevState) => ({
      ...prevState,
      first_registration_date: firstRegistrationDate,
      starting_price: parsedData.prices[0].price,
    }));
  };

  const charge = async (): Promise<boolean> => {
    // this indicates that the product is already charged
    if (archiveId) return true;

    try {
      const chargedResponse = await chargeProduct(PRODUCT_NAME);
      if ("error" in chargedResponse) {
        onError && onError(chargedResponse.error.message);
        return false;
      }
      onCharge && onCharge();
      return true;
    } catch (err: any) {
      if (err.response?.data?.error?.message) {
        onError && onError(err.response.data.error.message);
      }
      return false;
    }
  };

  const handleEvaluation = async (pdfV: string) => {
    const isCharged = await charge();
    if (!isCharged) {
      return;
    }

    const eval_id = await saveEvaluationData(pdfV);
    openPDFGeneration(PRODUCT_NAME, {
      eval_id: eval_id,
    });
  };

  useEffect(() => {
    fetchEvaldata();
  }, [priceId]);

  return (
    <>
      <ConfirmationModal
        title="Платена услуга"
        onClose={() => setIsConfirmModalVisible(() => false)}
        onConfirm={() => {
          setIsConfirmModalVisible(() => false);
          handleEvaluation(pdfVersion);
        }}
        message={
          <div>
            Сигурни ли сте, че искате да продължите? <br />
            {evalCost}
          </div>
        }
        isShown={isConfirmModalVisible}
      />
      {printingPDF && (
        <OverlayLoader message="Моля изчакайте докато генерираме вашият PDF документ." />
      )}
      <hr className="my-5" />
      <div className={"container pb-5"}>
        <div className="row">
          <div className="col" id="eval_data">
            <div className="row border rounded-1 border-dark mb-3 p-2 pt-2">
              <div className="col">
                <div className="row">
                  <div className="col-xl-3 col-md-4 ">
                    <Datepicker
                      label="Дата на първа регистрация"
                      name="date_1"
                      onChange={(date) =>
                        setEvalCriteria((prev) => {
                          return {
                            ...prev,
                            first_registration_date: date,
                          };
                        })
                      }
                      date={evalCriteria.first_registration_date || new Date()}
                    />
                    <p className="text-muted mb-0 mt-1">Формат: дд.мм.гггг</p>
                  </div>

                  <div className="col-xl-3 col-md-4 ">
                    <Select
                      id={"price_correction"}
                      value={String(evalCriteria.general_correction || 0)}
                      placeholder={"Изберете стойност"}
                      isLoading={isLoading}
                      onChange={(selectedValue) => {
                        setEvalCriteria((prev) => {
                          return {
                            ...prev,
                            general_correction: Number(selectedValue),
                          };
                        });
                      }}
                      options={correctionOptions()}
                      label={"Корекция на цената"}
                    />
                  </div>
                </div>

                <hr />

                {evaluationData.base_values &&
                  evaluationData.reg49_residual_values && (
                    <Summary
                      evaluationData={{
                        original_price:
                          evaluationData.base_values.original_price || 0,
                        base_residual_price:
                          evaluationData.reg49_residual_values.base || 0,
                        residual_price:
                          evaluationData.reg49_residual_values.residual_value ||
                          0,
                        correction:
                          evaluationData.reg49_residual_values
                            .general_correction || 0,
                        correction_percent:
                          evalCriteria.general_correction || 0,
                      }}
                    />
                  )}
                <ImagesUpload
                  onImagesSelected={(images) => {
                    setSelectedFiles(() => ({
                      saved: false,
                      files: images,
                      uploadedFileIds: [],
                    }));
                  }}
                />

                <hr />
                <div className="row">
                  {/* <div className="col-lg-4">
                    <Button type="primary" onClick={recalculate}>
                      <IconLabel name="calculator" content="Преизчисли" />
                    </Button>
                  </div> */}
                  {/* <div className="col-lg-3 offset-lg-6">
                    <Button
                      type="primary"
                      onClick={() => {
                        if (automaticCharge || archiveId) {
                          handleEvaluation("v1");
                          return;
                        }
                        setPdfVersion(() => "v1");
                        setIsConfirmModalVisible(() => true);
                      }}
                      // disabled={archiveId == null}
                    >
                      <span className="d-flex justify-content-center">
                        <span className="me-4">
                          <IconLabel name="printer" content="Печат V1" />{" "}
                        </span>
                      </span>
                    </Button>
                  </div> */}
                  <div className="offset-lg-9 col-lg-3">
                    <Button
                      type="primary"
                      onClick={() => {
                        if (automaticCharge || archiveId) {
                          handleEvaluation("v2");
                          return;
                        }
                        setPdfVersion(() => "v2");
                        setIsConfirmModalVisible(() => true);
                      }}
                    >
                      <span className="d-flex justify-content-center">
                        <span className="me-4">
                          <IconLabel name="printer" content="Печат" />{" "}
                        </span>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Evaluation;
