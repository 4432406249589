import { HistoryEntryT } from "../../../api/types";

const config = [
  {
    title: "Дата",
    render: (row: HistoryEntryT) => {
      return row.created_at || "N/A";
    },
  },
  {
    title: "VIN",
    render: (row: HistoryEntryT) => {
      return row.vin || "N/A";
    },
  },
  {
    title: "Регистрационен номер",
    render: (row: HistoryEntryT) => {
      return row.reg_number || "N/A";
    },
  },
  {
    title: "Продукт",
    render: (row: HistoryEntryT) => {
      return row.product.display_name || "N/A";
    },
  },
  {
    title: "Модул",
    render: (row: HistoryEntryT) => {
      return row.module.display_name || "N/A";
    },
  },
  {
    title: "Сума",
    render: (row: HistoryEntryT) => {
      if (row.cost === null) {
        // console.log(row);
        return "Лиценз";
      }
      return String(row.cost) === "0.00" ? "Безплатно" : row.cost;
    },
  },
];

export { config };
