import { useMemo } from "react";
import useEvaluationContext, {
  GeneralConditionT,
} from "../../../context/evaluation";

const GeneralConditionCorrection = () => {
  const { evalCriteria, setEvalCriteria } = useEvaluationContext();

  const handleRadioChange = (rowIndex: any, value: any) => {
    setEvalCriteria((prev) => {
      return {
        ...prev,
        general_condition: {
          ...(prev.general_condition || {}),
          [rowIndex]: value,
        },
      };
    });
  };

  const tableData: {
    id: "engine" | "body" | "paint" | "interior" | "trunk";
    label: string;
    values: number[];
  }[] = [
    { id: "engine", label: "Двигател", values: [-4, -2, 0, 2, 4] },
    { id: "body", label: "Каросерия", values: [-4, -2, 0, 2, 4] },
    { id: "paint", label: "Боядисване", values: [-4, -2, 0, 2, 4] },
    { id: "interior", label: "Интериор", values: [-4, -2, 0, 2, 4] },
    { id: "trunk", label: "Багажник", values: [-4, -2, 0, 2, 4] },
  ];

  const isChecked = useMemo(() => {
    return (rowId: keyof GeneralConditionT, value: number) => {
      if (
        evalCriteria.general_condition &&
        evalCriteria.general_condition[rowId]
      ) {
        return evalCriteria.general_condition[rowId] === value;
      }
      return value === 0;
    };
  }, [evalCriteria]);

  return (
    <div className="row py-3">
      <div className="col-12">
        <h6 className="">
        Корекция за общо състояние (Имайте предвид възрастта)
        </h6>
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th></th>
              <th>-4%</th>
              <th>-2%</th>
              <th>Средно</th>
              <th>+2%</th>
              <th>+4%</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td style={{ textAlign: "left" }}>{row.label}</td>
                {row.values.map((value, valueIndex) => (
                  <td key={valueIndex}>
                    <input
                      type="radio"
                      name={`radio-${row.id}`}
                      checked={isChecked(row.id, value)}
                      onChange={() => handleRadioChange(row.id, value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default GeneralConditionCorrection;
