import PriceCard from "../PriceCard/PriceCard";

export type Price = {
  name: string;
  price: string;
  is_internal: boolean;
};

type PriceProps = {
  prices: Array<Price>;
  mainModulePrice?: string;
};

const Prices = ({ prices, mainModulePrice }: PriceProps) => {
  return (
    <div
      id="prices"
      className="row my-5 text-center justify-content-center aei-shaded-bg p-5 rounded-2"
    >
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="mb-3">
            Цени на услугата
            <span>
              <small>*</small>
            </span>
          </h2>
        </div>

        <div className="col-12">
          <div className="row text-center justify-content-around">
            {mainModulePrice && (
              <PriceCard
                price={{ name: "На брой", price: mainModulePrice }}
                fullWidth
              />
            )}
            {prices
              .filter((price) => !price.is_internal)
              .map((price: any, index: Number) => {
                return <PriceCard key={index} price={price} />;
              })}
          </div>
        </div>
        <div className="col-12 text-center">
          <p className="mt-4">
            <small>
              <span>*</span>Всички цени са с включен ДДС.
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Prices;
