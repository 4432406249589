import { useEffect, useState } from "react";
import {
  getCompanyBranches,
  getCompanyData,
  getCompanyOfficesForBranch,
  getCompanyUsers,
} from "../../../api/main";
import { config } from "./tableConfig";
import {
  Button,
  ContentContainerWithSidebar,
  IconLabel,
  Input,
  Loader,
  Pagination,
  Select,
} from "../../../components/common";
import { UserEntryT } from "../../../api/types";
import LinkButton from "../../../components/common/Button/LinkButton";
import useAuthContext from "../../../context/authentication";

const Users = () => {
  const { company } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserEntryT[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loadingCompanyData, setLoadingCompanyData] = useState<boolean>(false);
  const [pagination, setSetPagination] = useState<{
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  } | null>(null);

  const [branches, setBranches] = useState<{ value: string; label: string }[]>([
    {
      value: "",
      label: "Всички",
    },
  ]);
  const [offices, setOffices] = useState<{ value: string; label: string }[]>([
    {
      value: "",
      label: "Всички",
    },
  ]);
  const fetchBranches = async () => {
    try {
      const { data } = await getCompanyBranches();

      setBranches(() => {
        return [
          {
            value: "",
            label: "Всички",
          },
          ...data.map((branch: { id: string; name: string }) => ({
            value: branch.id,
            label: branch.name,
          })),
        ];
      });
    } catch {}
  };
  const fetchOffices = async (branch: string | number | undefined) => {
    if (branch) {
      try {
        const { data } = await getCompanyOfficesForBranch(branch);
        const itemIdx = data.findIndex((office: { id: string }) => {
          return office.id === searchCriteria.office_id;
        });
        if (itemIdx === -1) {
          setSearchCriteria((prevState) => ({
            ...prevState,
            office_id: "",
          }));
        }
        setOffices(() => {
          return [
            {
              value: "",
              label: "Всички",
            },
            ...data.map((office: { id: string; name: string }) => ({
              value: office.id,
              label: office.name,
            })),
          ];
        });
      } catch {}
    }
  };
  const [searchCriteria, setSearchCriteria] = useState<{
    [key: string]: unknown;
  }>({});

  const fetchUsers = async (page: number = 1) => {
    setLoadingUsers(() => true);
    try {
      const response = await getCompanyUsers(searchCriteria, page);
      if (response) {
        if (response.data) {
          setUsers(() => response.data);
        }
        if (response.meta) {
          setSetPagination(() => response.meta);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingUsers(() => false);
  };

  useEffect(() => {
    // fetch users
    fetchUsers();
    fetchBranches();
  }, []);
  useEffect(() => {
    fetchOffices(searchCriteria.branch_id as string);
  }, [searchCriteria.branch_id]);

  const handleSearchUsers = async () => {
    setIsLoading(() => true);
    fetchUsers();
    setIsLoading(() => false);
  };

  const rows: any =
    users && users.length > 0 ? (
      users.map((row) => (
        <tr key={row.id}>
          {config.map((col) => (
            <td key={Math.random()}>{col.render(row)}</td>
          ))}
          <td>
            <LinkButton
              to={`/administration/users/${row.id}`}
              text={"Редактирай"}
              type="primary"
              size="sm"
            />
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={config.length + 1}>
          <div className="d-flex justify-content-center">
            Не бяха намерени резултати.
          </div>
        </td>
      </tr>
    );

  return (
    <ContentContainerWithSidebar>
      <div className="row">
        <div className="col-12">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">Потребители</h3>
              <div className="row my-3 align-items-center justify-content-around">
                <div className="col-xl-3 col-lg-6">
                  <Input
                    name="name"
                    label="Име на потребител"
                    placeholder="Име на потребител"
                    value={(searchCriteria?.name as string) || ""}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        name: value,
                      }));
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-6 ">
                  <Input
                    label="Имейл"
                    name="name"
                    placeholder="Имейл"
                    value={(searchCriteria?.email as string) || ""}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        email: value,
                      }));
                    }}
                  />
                </div>
                <div className="col-xl-2 col-lg-6 ">
                  <Select
                    label="Клон"
                    id="branch"
                    value={String(searchCriteria.branch_id || "")}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        branch_id: value,
                      }));
                    }}
                    options={branches}
                  />
                </div>
                <div className="col-xl-2 col-lg-6 ">
                  <Select
                    label="Офис"
                    id="office"
                    value={String(searchCriteria.office_id || "")}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        office_id: value,
                      }));
                    }}
                    options={offices}
                  />
                </div>
                <div className="col-xl-2 col-lg-6 ">
                  <Select
                    label="Статус"
                    id="status"
                    value={String(searchCriteria.status || "")}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        status: value,
                      }));
                    }}
                    options={[
                      {
                        value: "",
                        label: "Всички",
                      },
                      {
                        value: "active",
                        label: "Активни",
                      },
                      {
                        value: "inactive",
                        label: "Неактивни",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row justify-content-end mb-3">
                <div className="col-auto mt-3">
                  <Button
                    disabled={isLoading}
                    onClick={handleSearchUsers}
                    size="sm"
                    type="primary"
                    content="Търси"
                  />
                </div>
                <div className="col-auto mt-3">
                  <Button
                    onClick={() => setSearchCriteria(() => ({}))}
                    size="sm"
                    type="secondary"
                    content="Изчисти филтрите"
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between align-items-center mb-3">
                <div className="col-auto">
                  <div className="row">
                    <div className="col-auto ">
                      {loadingCompanyData ? (
                        <div className="mx-5 w-100">
                          <Loader size="medium" />
                        </div>
                      ) : (
                        <h5>Баланс: {company?.balance} лв.</h5>
                      )}
                    </div>
                    <div className="col-auto ">
                      {loadingCompanyData ? (
                        <div className="mx-5 w-100">
                          <Loader size="medium" />
                        </div>
                      ) : (
                        <h5>
                          Активни потребители: {company?.activeUsersCount}/
                          {company?.maxActiveUsers}
                          <span className="text-muted fs-6">
                            {" "}
                            (максимален брой потребители)
                          </span>
                        </h5>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-auto ">
                  <LinkButton
                    auto
                    to="/administration/users/create"
                    text={
                      <IconLabel
                        name="person-add"
                        sz={6}
                        content={"Създай потребител"}
                      />
                    }
                    type="primary"
                    size="sm"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-md-responsive">
                    <table className="table table-striped">
                      <thead className="sticky-top Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingUsers ? (
                          <tr>
                            <td colSpan={config.length + 1}>
                              <div className="d-flex justify-content-center p-5">
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          rows
                        )}
                      </tbody>
                      <tfoot className="Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col"></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                centered
                pagination={pagination}
                onChange={(page) => fetchUsers(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default Users;
