import "./PriceCard.css";

const PriceCard = ({ price, fullWidth }: any) => {
  return (
    <div
      className={`PriceCard ${
        fullWidth ? "col-10" : "col-lg-4 col-md-12 col-sm-12"
      } py-3 mx-1 my-2`}
    >
      <h5 className="">{price.name}</h5>
      <p className="Price mb-0">
        {price.price} {price.price.toLowerCase() !== "безплатно" && "лв."}
      </p>
    </div>
  );
};
export default PriceCard;
