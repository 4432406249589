import { Fragment, memo, useEffect, useState } from "react";
import "./VehicleDataTable.css";
import InfoButton from "./components/InfoButton/InfoButton";

import { config } from "./tableConfig";
import { VehicleT } from "../../../utils/vehicles";
import AdditionalInfo from "./components/AdditionalInfo/AdditionalInfo";

const VehicleDataTable = ({
  vin,
  data,
  product,
  columns,
  withMarketPrice,
  expandedRowRender,
  onEvaluationClick,
  onPrintClick,
  ...rest
}: any) => {
  const [collapsedRows, setCollapsedRows] = useState<string[]>([]);
  const toggleRow = (id: string) => {
    setCollapsedRows((rows) => {
      if (rows.includes(id)) {
        return rows.filter((row) => row !== id);
      } else {
        return [...rows, id];
      }
    });
  };

  useEffect(() => {
    setCollapsedRows([]);
  }, [data]);

  // Then, add an event listener to the collapsed row
  function handleCollapsedRowHoverIn(e: any) {
    const collapsedRow = e.target;
    const parentRow = collapsedRow.closest("tr"); // Find the parent row
    if (parentRow.previousSibling) {
      parentRow.previousSibling.classList.add("Table-parent-hovered"); // Add/remove the hover class to the parent row
    }
  }
  function handleCollapsedRowHoverOut(e: any) {
    const collapsedRow = e.target;
    const parentRow = collapsedRow.closest("tr"); // Find the parent row
    if (parentRow.previousSibling) {
      parentRow.previousSibling.classList.remove("Table-parent-hovered"); // Add/remove the hover class to the parent row
    }
  }

  const rows =
    data &&
    data.map((row: VehicleT, idx: number) => {
      const uniqueId = `uuid-${Math.random().toString(36).substring(2, 9)}`;
      const isStripped = idx % 2 === 0;
      const id = `row-${idx}`;
      const isCollapsed = collapsedRows.includes(id);
      return (
        <Fragment key={uniqueId}>
          <tr className={`${isStripped ? "Table-strip" : ""} Table-hover`}>
            {config.map((col) => (
              <td key={Math.random()}>{col.render(row)}</td>
            ))}
            <td>
              <InfoButton type="button" onClick={() => toggleRow(id)} />
            </td>
          </tr>

          <tr
            className={`hide-table-padding Table-hover-child ${
              isStripped ? `Table-strip` : ""
            }`}
          >
            {isCollapsed && (
              <td colSpan={12}>
                <div
                  id={uniqueId}
                  onMouseEnter={handleCollapsedRowHoverIn} // Add the event listener
                  onMouseLeave={handleCollapsedRowHoverOut}
                  onBlur={handleCollapsedRowHoverOut}
                >
                  <div className="">
                    <AdditionalInfo
                      withMarketPrice={withMarketPrice}
                      product={product}
                      vehicle={row}
                      vin={vin}
                      onPrintClick={(data) => {
                        // bubble up the event if needed (e.g. to print the whole table)
                        onPrintClick && onPrintClick(data);
                      }}
                      onEvaluationClick={(priceId) => {
                        // bubble up the event if needed (e.g. to start easy evaluation, or ins-value evaluation)
                        onEvaluationClick && onEvaluationClick(priceId);
                      }}
                    />
                  </div>
                </div>
              </td>
            )}
          </tr>
        </Fragment>
      );
    });

  return (
    <div>
      <div className="row">
        {data &&
          data[0] &&
          data[0].imagePaths.map((path: string, index: string) => {
            return (
              <div key={`img-${index}`} className="col-md-4">
                <img
                  className="img-fluid rounded mx-auto d-block img-thumbnail"
                  src={`${process.env.REACT_APP_API_URL}/${path}`}
                  alt="car"
                />
              </div>
            );
          })}
      </div>
      <div className="row">
        <div className="col">
          <div className="table-md-responsive">
            <table className="table">
              <thead className="sticky-top Table-header">
                <tr>
                  {config.map((col) => (
                    <th key={Math.random()} scope="col">
                      {col.title}
                    </th>
                  ))}
                  <th scope="col">Още</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
              <tfoot>
                <tr>
                  {config.map((col) => (
                    <th key={Math.random()} scope="col">
                      {col.title}
                    </th>
                  ))}
                  <th scope="col">Още</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VehicleDataTable);
