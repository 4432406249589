import PriceItem from "./Components/PriceItem";
import { MarketPriceItemT } from "../../../api/types";
import { countryCode2name } from "../../../utils/helpers";

type MarketPriceItemProps = {
  marketPriceItem: MarketPriceItemT;
};
const notFoundDataMessage = "Не е намерена информация за това ПС.";

const MarketPriceItem = ({ marketPriceItem }: MarketPriceItemProps) => {
  const showGraph =
    marketPriceItem.graph_price && marketPriceItem.graph_price.length > 0;

  // let links: Array<JSX.Element> = [];
  // const linkSource: Array<string> = showGraph
  //   ? marketPriceItem.graph_links
  //   : marketPriceItem.links;

  // if (linkSource && linkSource.length > 0) {
  //   links = linkSource.map((link) => {
  //     return (
  //       <li key={Math.floor(Math.random() * 10000)}>
  //         <a href={link} target="_blank" rel="noreferrer">
  //           {link}
  //         </a>
  //       </li>
  //     );
  //   });
  // } else {
  //   links.push(<li key={Math.floor(Math.random() * 10000)}>{notFoundDataMessage}</li>);
  // }

  return (
    <div className="row mt-3">
      <div className="col">
        <div className="row mb-0">
          <div className="col">
            {showGraph ? (
              <>
                <p className="mb-0">
                  <b>
                    Пазарна стойност спрямо кривата на овехтяване в{" "}
                    {countryCode2name[marketPriceItem.market]}:
                  </b>
                </p>
                <PriceItem price={marketPriceItem.graph_price} />
              </>
            ) : (
              <>
                <p className="mb-0">
                  <b>
                    Пазарна стойност в{" "}
                    {countryCode2name[marketPriceItem.market]}:
                  </b>
                </p>

                {marketPriceItem.products_count > 0 ? (
                  <>
                    <PriceItem price={marketPriceItem.median_price} />
                    <p className="mb-0 ms-3">
                      Цената е определена на база{" "}
                      {marketPriceItem.products_count} пазарни цени.
                    </p>
                  </>
                ) : (
                  <p className="ms-3">{notFoundDataMessage}</p>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <p className="mb-0">
              <b>Линкове към пазарните цени:</b>
            </p>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col">
            <ul className="list-unstyled mb-0 ms-3">{links}</ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MarketPriceItem;
