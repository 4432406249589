import { useEffect } from "react";
import useEvaluationContext from "../../../../context/evaluation";
import { formatNumber } from "../../../../utils/helpers";
import { Button, Icon, Input } from "../../../common";

type MileageCorrectionT = {};

const MileageCorrection = (props: MileageCorrectionT) => {
  const { evalCriteria, setEvalCriteria, evalSummary } = useEvaluationContext();
  useEffect(() => {
    if (evalCriteria.mileage === undefined && evalSummary.standart_mileage) {
      setEvalCriteria((prev) => {
        return {
          ...prev,
          mileage: Number(evalSummary.standart_mileage || 0),
        };
      });
    }
  }, [evalSummary.standart_mileage]);
  return (
    <div className="row py-3    ">
      <div className="col-12  ">
        <div className="row">
          <div className="col-xl-3 col-md-4">
            <Input
              type="number"
              value={evalCriteria.mileage || ""}
              onChange={(value) =>
                setEvalCriteria((prev) => {
                  return {
                    ...prev,
                    mileage: Number(value),
                  };
                })
              }
              onFocus={(e) => {
                e.target.select();
              }}
              name="mileage"
              label="Действителен пробег, km"
            />
          </div>
          <div className="col-xl-4 col-md-6 d-flex align-items-end">
            <div className="row mt-4">
              <div className="col-auto">
                <Button
                  type="primary"
                  onClick={() => {
                    setEvalCriteria((prev) => {
                      return {
                        ...prev,
                        mileage: Number(evalSummary.standart_mileage || 0),
                      };
                    });
                  }}
                >
                  <Icon name="arrow-left" sz={6} />
                </Button>
              </div>
              <div className="col-auto">
                <p className="m-0" style={{ fontSize: "1.2em" }}>
                  Стандартен пробег:{" "}
                  {formatNumber(evalSummary.standart_mileage)} km
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MileageCorrection;
