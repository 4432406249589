export type VehicleT = {
  submodel: string;
  model: string;
  make: string;
  doors: number;
  seats: number;
  autoTrans: boolean;
  imageHash: string;
  bodyType: {
    name: string;
    description: string;
  };
  vehicleType: {
    name: string;
  };
  engine: {
    capacity: number;
    power_kw: number;
    power_hp: number;
    name: string;
  };
  modelProdStartDate: string;
  modelProdEndDate: string;
  submodelProdStartDate: string;
  submodelProdEndDate: string;
  fuelName: string;
  wheelBase: number;
  netWeight: number;
  loadCapacity: number;
  totalWeight: number;
  length: number;
  width: number;
  height: number;
  latestPrice: number;
  imagePaths: string[];
  prices: {
    id: number;
    publishedAt: string;
    price: number;
    marketPrice?: any;
    standardOriginalOptions: {
      name: string;
      abbr: string;
      explanation: string;
      count: number;
      type?: "added" | "removed";
    }[];
    standardOptions: {
      id: number;
      name: string;
      abbr: string;
      explanation: string;
      count: number;
      type?: "added" | "removed";
    }[];
    additionalOptions: {
      id: number;
      name: string;
      abbr: string;
      explanation: string;
      price: number;
    }[];
  }[];
};

const parsePrices = (prices: any[]) => {
  const groups: any[] = [];
  prices.forEach((price) => {
    const index = groups.findIndex(
      (group: any) =>
        group.findIndex(
          (item: any) => item.published_at === price.published_at
        ) === -1
    );
    if (index === -1) {
      groups.push([price]);
    } else {
      groups[index].push(price);
    }
  });
  // merge all groups sequential in one group
  return groups.reduce((acc, group) => {
    if (acc.length === 0) {
      return group;
    }
    return [...acc, ...group];
  });
};

const parseVehicle = (vehicle: any): VehicleT => {
  let optionsPrev: any = [];

  const parsedPrices = parsePrices(vehicle.prices);

  const prices = parsedPrices.map((price: any) => {
    const parsedOriginalOptions: any = [];
    const standardOptions = price.options.filter((option: any) => {
      return option.pivot.type === "standart";
    });
    const additionalOptions = price.options.filter((option: any) => {
      return option.pivot.type === "additional";
    });

    const parsedAdditionalOptions = additionalOptions.map((option: any) => {
      const returnStructure = {
        id: option.id,
        name: option.name,
        abbr: option.abbr,
        explanation: option.explanation,
        cost: option.pivot.cost,
      };
      return returnStructure;
    });

    const parsedOptions = standardOptions
      .map((option: any) => {
        const returnStructure = {
          id: option.id,
          name: option.name,
          abbr: option.abbr,
          explanation: option.explanation,
          count: option.pivot.count,
        };
        parsedOriginalOptions.push(returnStructure);
        if (optionsPrev.length > 0) {
          const index = optionsPrev.findIndex(
            (item: any) => item.name === option.name
          );
          if (index !== -1) {
            optionsPrev.splice(index, 1);
            return null;
          } else {
            return {
              ...returnStructure,
              type: "added",
            };
          }
        }

        return returnStructure;
      })
      .filter((option: any) => option !== null);

    optionsPrev.forEach((option: any) => {
      parsedOptions.push({
        name: option.name,
        abbr: option.abbr,
        explanation: option.explanation,
        count: option.pivot.count,
        type: "removed",
      });
    });

    optionsPrev = [...standardOptions];

    return {
      id: price.id,
      publishedAt: price.published_at,
      price: price.bgn_price,
      standardOptions: parsedOptions,
      standardOriginalOptions: parsedOriginalOptions,
      additionalOptions: parsedAdditionalOptions,
    };
  });

  const latestPrice = prices[prices.length - 1];

  // Creates image folder path as it is in the server
  // ALFA ROMEO -> ALFA-ROMEO
  const normalizedMakeName = vehicle.make.name.replace(/\s/g, "-");

  // Check if the vehicle has automatic transmission
  const autoTrans = vehicle.gearbox?.abbr.includes("АС");

  return {
    submodel: vehicle.submodel.name || "",
    model: vehicle.model.name || "",
    make: vehicle.make.name || "",
    doors: vehicle.doors,
    seats: vehicle.seats,
    imageHash: vehicle.image_hash,
    autoTrans: autoTrans,
    imagePaths: [
      `vehicle_images/${normalizedMakeName}/${vehicle.image_hash}_f.jpg`,
      `vehicle_images/${normalizedMakeName}/${vehicle.image_hash}_p.jpg`,
      `vehicle_images/${normalizedMakeName}/${vehicle.image_hash}_t.jpg`,
    ],
    bodyType: {
      name: vehicle.body_type.name,
      description: vehicle.body_type.description,
    },
    vehicleType: {
      name: vehicle.body_type.vehicle_type?.name || "",
    },
    engine: {
      capacity: vehicle.engine.capacity,
      power_kw: vehicle.engine.power_kw,
      power_hp: vehicle.engine.power_hp,
      name: vehicle.engine.name,
    },
    modelProdStartDate: vehicle.model_prod_start_date,
    modelProdEndDate: vehicle.model_prod_end_date,
    submodelProdStartDate: vehicle.submodel_prod_start_date,
    submodelProdEndDate: vehicle.submodel_prod_end_date,
    fuelName: vehicle.engine.fuel_type.name,
    wheelBase: vehicle.wheel_base_mm,
    netWeight: vehicle.net_weight_kg,
    loadCapacity: vehicle.load_capacity_kg,
    totalWeight: vehicle.total_weight_kg,
    length: vehicle.length_mm,
    width: vehicle.width_mm,
    height: vehicle.height_mm,
    latestPrice: latestPrice.price,
    prices: prices,
  };
};

const parseVehicles = (vehicles: any[]): VehicleT[] => {
  const parsedVehicles = vehicles.map((vehicle) => {
    return parseVehicle(vehicle);
  });
  return parsedVehicles;
};

const groupByImageHash = (vehicles: VehicleT[]) => {
  const groupedVehicles: { [key: string]: VehicleT[] } = {};
  vehicles.forEach((vehicle) => {
    if (!groupedVehicles[vehicle.imageHash]) {
      groupedVehicles[vehicle.imageHash] = [];
    }
    groupedVehicles[vehicle.imageHash].push(vehicle);
  });

  return groupedVehicles;
};

export { parseVehicles, parseVehicle, groupByImageHash };
