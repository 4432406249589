import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

type LinkButtonProps = {
  text: string | JSX.Element;
  to: string;
  size?: "sm" | "md" | "lg";
  type: "primary" | "secondary";
  auto?: boolean;
  classes?: string;
};

const LinkButton = ({ to, text, type, size, auto, classes=""}: LinkButtonProps) => {
  return (
    <Link
      to={to}
      className={`${classes} btn Button Button-${type} ${size ? `btn-${size}` : ""} ${auto ? "Button-w-auto" : ""}`}
    >
      {text}
    </Link>
  );
};

export default LinkButton;
