import { partnerLogos } from "./config";
import styles from "./PartnerLogos.module.css";

/**
 * TODO: get all partners from the backend
 */
const PartnerLogos = () => {
  return (
    <div
      className={`row align-items-stretch gx-xl-5 gx-0 ${styles["container"]}`}
    >
      {partnerLogos.map((partner, idx) => {
        return (
          <div key={`logo-${idx + 1}`} className={`col-4 mb-xl-3 mb-0`}>
            <div
              className={`${styles["card-container"]} d-flex justify-content-center align-items-center p-3`}
            >
              <a href={partner.url} target="_blank" rel="noreferrer">
                <img
                  src={partner.logo}
                  alt={partner.alt}
                  className={`${styles["partner-logo"]} img-fluid`}
                />
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PartnerLogos;
