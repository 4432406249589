import React from "react";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

import styles from "./SideButton.module.css";

type SideButtonProps = {
  onClick?: () => void;
};

const SideButton: React.FC<SideButtonProps> = ({ onClick }) => {
  return (
    <>
      <Button
        type="primary"
        className={`${styles["side-button"]}`}
        onClick={onClick || (() => {})}
      >
        <Icon name="info-circle-fill" />
      </Button>
    </>
  );
};

export default SideButton;
