const initFields = [
  {
    id: "-1",
    key: "identifier",
    name: "Изходящ номер (при празна стойност се генерира системен)",
    value: null,
  },
  {
    id: "-3",
    key: "expert_registered_in_court",
    name: "Вещо лице регистрирано в съда",
    value: null,
  },
  {
    id: "-4",
    key: "expert_registered_in_court_number",
    name: "Вещо лице регистрирано в съда под номер",
    value: null,
  },
  {
    id: "0",
    key: "vin",
    name: "Рама Номер",
    value: null,
  },
  {
    id: "2",
    key: "make",
    name: "Марка",
    value: null,
  },
  {
    id: "3",
    key: "model",
    name: "Модел",
    value: null,
  },
  {
    id: "47",
    key: "engine_code",
    name: "Двигател номер",
    value: "НЕ Е ОТКРИТ",
  },
  {
    id: "5",
    key: "fuel_type",
    name: "Вид гориво",
    value: null,
  },
  {
    id: "8",
    key: "engine_capacity",
    name: "Ходов обем на двигател (ccm)",
    value: null,
  },
  {
    id: "9",
    key: "engine_power",
    name: "Мощност на двигател (kw/hp)",
    value: null,
  },
  {
    id: "4",
    key: "body_type",
    name: "Тип на Купето",
    value: null,
  },
  {
    id: "12",
    key: "doors",
    name: "Брой врати",
    value: null,
  },
  {
    id: "1",
    key: "production_year",
    name: "Година на производство",
    value: null,
  },
  {
    id: "72",
    key: "manufacturer",
    name: "Производител",
    value: null,
  },
  {
    id: "73",
    key: "manufacturer_address",
    name: "Адрес на производителя",
    value: null,
  },
  {
    id: "89",
    key: "manufacturer_country",
    name: "Държава на производство",
    value: null,
  },
  {
    id: "-2",
    key: "documents",
    name: "Използвани Документи",
    value: null,
  },
];


export { initFields };
