import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { useEffect, useMemo, useState } from "react";
import Button from "../../common/Button/Button";
import { Input } from "../../common";
import { initFields } from "./helpers";
import {
  chargeProduct,
  openPDFGeneration,
  saveTechData,
} from "../../../api/main";
import useAuthContext from "../../../context/authentication";
interface TechDataProps {
  automaticCharge?: boolean;
  vin: string;
  data: Array<{ id: string; name: string; value: string }>;
  onCharge?: () => void;
  configuration?: any;
}

const findValue = (
  data: Array<{ id: string; name: string; value: string }>,
  id: string
) => {
  return data.find((item) => String(item.id) === String(id))?.value || null;
};

const TechData = ({
  data,
  vin,
  onCharge,
  automaticCharge,
  configuration,
}: TechDataProps) => {
  const { user } = useAuthContext();
  const [techData, setTechData] =
    useState<
      Array<{ id: string; key: string; name: string; value: string | null }>
    >(initFields);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const [archiveId, setArchiveId] = useState<number>();

  const printPDF = async () => {
    const payload = {
      tech_data: techData.reduce(
        (a, b) => ({
          ...a,
          [b.key]: b.value,
        }),
        {}
      ),

      vin: vin,
    };
    let id = archiveId;

    if (!id) {
      await chargeProduct("tech-data");
      onCharge && onCharge();
    }

    const response = await saveTechData(payload, id);
    if (!id) {
      id = response.archive.id;
      setArchiveId(id);
    }

    openPDFGeneration("tech-data", { aid: id });
  };
  const costText = useMemo(() => {
    return configuration?.cost && !configuration?.licensed ? (
      <span className="text-price">Цена: {configuration.cost} лв.</span>
    ) : (
      ""
    );
  }, [configuration]);

  useEffect(() => {
    setArchiveId(()=>undefined)
    setTechData(() => {
      return initFields.map((item) => {
        if (item.id === "0") {
          return { ...item, value: vin };
        }
        if (
          item.key === "expert_registered_in_court" &&
          user?.additional_information?.expert_registered_in_court
        ) {
          return {
            ...item,
            value: user?.additional_information?.expert_registered_in_court,
          };
        }
        if (
          item.key === "expert_registered_in_court_number" &&
          user?.additional_information?.expert_registered_in_court_number
        ) {
          return {
            ...item,
            value:
              user?.additional_information?.expert_registered_in_court_number,
          };
        }
        return { ...item, value: findValue(data, item.id) || item.value };
      });
    });
  }, [data]);

  return (
    <div className=" border rounded-1 border-dark mb-3 pb-3 pt-2">
      {!automaticCharge ? (
        <ConfirmationModal
          title="Платена услуга"
          onClose={() => setIsConfirmModalVisible(() => false)}
          onConfirm={() => {
            printPDF();
            setIsConfirmModalVisible(() => false);
          }}
          message={
            <div>
              Сигурни ли сте, че искате да продължите? <br />
              {costText}
            </div>
          }
          isShown={isConfirmModalVisible}
        />
      ) : (
        <></>
      )}
      {techData.map((item) => (
        <div key={item.id} className="row">
          <div key={item.id} className="col-lg-6 offset-lg-3 col-xs-12 my-1">
            <Input
              id={item.id}
              label={item.name}
              name={item.name}
              value={item.value || ""}
              onChange={(value) => {
                // Update the value in the data array
                setTechData((oldData) => {
                  const newData = oldData.map((d) => {
                    if (d.id === item.id) {
                      return { ...d, value: value as any };
                    }
                    return d;
                  });
                  return newData;
                });
              }}
            />
          </div>
        </div>
      ))}
      <div className="row mt-3">
        <div className="col-lg-2 offset-lg-5 col-xs-12 my-1">
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              // Call a function to handle the updated data
              if (archiveId) {
                return printPDF();
              }
              setIsConfirmModalVisible(() => true);
            }}
          >
            Печат
          </Button>
        </div>
      </div>
    </div>
  );
};

// Function to handle the updated data
const handleDataUpdate = (
  updatedData: Array<{ id: string; name: string; value: string }>
) => {
  // Do something with the updated data
  // console.log(updatedData);
};

export default TechData;
