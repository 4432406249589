import { useEffect, useRef, useState } from "react";

import * as Plotly from "plotly.js-dist";

const calculatePrice = (x: number, data: any) => {
  const { degree, coef_a, coef_b, coef_c, coef_d } = data;
  if (degree === 3) {
    return (
      coef_a * Math.pow(x, 3) + coef_b * Math.pow(x, 2) + coef_c * x + coef_d
    );
  }
  if (degree === 2) {
    return coef_a * Math.pow(x, 2) + coef_b * x + coef_c;
  }
  return coef_a * x + coef_b;
};

const MarketPriceGraph = ({
  market,
  data,
  price,
  year,
  onGeneratedImage,
}: any) => {
  const chartRef = useRef(null);

  const [plotSaved, setPlotSaved] = useState(false);

  const generateImage = async () => {
    if (chartRef.current) {
      // Use toImage to export the chart as an image
      const image = await Plotly.toImage(chartRef.current, {
        format: "png",
        width: 1240,
        height: 500,
      });
      if (image && !plotSaved) {
        onGeneratedImage && onGeneratedImage(image);
        setPlotSaved(true);
      }
    }
  };

  useEffect(() => {
    const xData: number[] = [];
    const yData: number[] = [];
    const firstYear = data.x[0];
    const lastYear = data.x[data.x.length - 1];
    for (let i = firstYear; i <= lastYear; i++) {
      xData.push(i);
      yData.push(calculatePrice(i, data));
    }

    // Your chart data and layout
    const plData = [
      {
        name: `Крива на овехтяване (${market})`,
        type: "scatter",
        mode: "lines",
        line: { color: "darkblue" },
        x: xData,
        y: yData,
      },
      {
        name: "Цени на пазара",
        type: "scatter",
        mode: "markers",
        marker: { color: "green", size: 5 },
        x: data.x,
        y: data.y,
      },
      {
        name: "Цена за конкретният автомобил",
        type: "scatter",
        mode: "markers",
        marker: { color: "red", size: 10 },
        x: [Number(year)],
        y: [price],
      },
    ];

    const layout = {
      title: `Крива на овехтяване (${market})`,
      xaxis: {
        title: "Година на производство",
        tickformat: ".0f",
        type: "category",
      },
      yaxis: {
        title: "Цена",
        tickformat: ".0f",
        ticksuffix: " BGN",
      },
    };

    // Use Plotly.newPlot to create the initial chart
    Plotly.newPlot(chartRef.current, plData, layout);
    generateImage();
  }, []);

  return (
    <div className="row my-3">
      <div className="col">
        <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>
      </div>
    </div>
  );
};

export default MarketPriceGraph;
