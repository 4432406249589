import { useEffect, useState } from "react";
import { Button, Input } from "../../components";

import "./Register.css";
import { Loader } from "../../components/common";
import useAuthContext from "../../context/authentication";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import GoogleAuth from "../../components/Socials/GoogleAuth";

const Register = () => {
  useEffect(() => {
    document.title = "Регистрация в системата | AutoExpert International";
  }, []);

  // useState for firstName, lastName, email, password, confirmPassword
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user, register, errors, socialLogin } = useAuthContext();
  if (user) navigate("/");

  const handleSuccessGoogleLogin = async (token: string) => {
    try {
      const redirectTo = searchParams.get("redirect");
      await socialLogin("google", token, redirectTo);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateAccount = async () => {
    setIsLoading(() => true);
    try {
      const redirectTo = searchParams.get("redirect");
      await register(
        {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        },
        redirectTo
      );
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (e) {
      console.log(e);
    }
    setIsLoading(() => false);
  };

  let passwordError = null;
  let mailError = null;
  let phoneError = null;
  let firstNameError = null;
  let lastNameError = null;
  if (errors) {
    if (errors.email) {
      mailError = (
        <p className="text-danger mb-0  mt-1 fs-5">{errors.email[0]}</p>
      );
    }
    if (errors.password) {
      passwordError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.password[0]}</p>
      );
    }
    if (errors.first_name) {
      firstNameError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.first_name[0]}</p>
      );
    }
    if (errors.phone) {
      phoneError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.phone[0]}</p>
      );
    }
    if (errors.last_name) {
      lastNameError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.last_name[0]}</p>
      );
    }
  }

  return (
    <div className="container aei-fonts fs-5">
      <div className="row pb-5 justify-content-center">
        <div className="col-lg-8 ">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row mt-5">
                <div className="col">
                  <h2 className="text-center font-weight-bold">
                    Регистрация в системата
                  </h2>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col text-center">
                  <p className="text-muted">
                    След успешна регистрация балансът Ви ще бъде зареден с 10
                    лв. Това ви дава възможност да тествате нашите продукти. Ако
                    желаете да продължите да ползвате продуктите ни - можете да
                    добавите средства в баланса си.
                    <br /> За целта се обадете на тел.{" "}
                    <a className="aei-primary-link" href="tel:+359884662265">
                      +359 88 466 2265
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="first_name"
                    type="text"
                    placeholder="Име"
                    label="Име*"
                    value={firstName}
                    onChange={(value) => setFirstName(() => value as string)}
                  />
                  {firstNameError || ""}
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="last_name"
                    type="text"
                    placeholder="Фамилия"
                    label="Фамилия*"
                    value={lastName}
                    onChange={(value) => setLastName(() => value as string)}
                  />
                  {lastNameError || ""}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="phone"
                    type="text"
                    placeholder="08********"
                    label="Телефон"
                    value={phone}
                    onChange={(value) => setPhone(() => value as string)}
                  />
                  {phoneError || ""}
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="email"
                    type="email"
                    label="Е-мейл*"
                    placeholder="example@example.com"
                    value={email}
                    onChange={(value) => setEmail(() => value as string)}
                  />
                  {mailError || ""}
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="password"
                    type="password"
                    placeholder="*****"
                    label="Парола*"
                    value={password}
                    onChange={(value) => setPassword(() => value as string)}
                  />
                  {passwordError || ""}
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="confirm_password"
                    type="password"
                    placeholder="*****"
                    label="Потвърди парола*"
                    value={confirmPassword}
                    onChange={(value) =>
                      setConfirmPassword(() => value as string)
                    }
                  />
                </div>
              </div>
              <div className="row justify-content-center mb-2">
                <div className="col ">
                  <p className="text-muted fs-6">* Задължителни полета.</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-xs-12 col-md-6  order-md-1 order-0">
                  <div className="row">
                    <div className="col ">
                      {isLoading ? (
                        <div className="my-3">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          content="Регистрация"
                          type="primary"
                          className="fs-5"
                          onClick={() => handleCreateAccount()}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 order-md-0 order-1">
                  <div className="row ">
                    <div className="col-xs-12">
                      <Link
                        to="/login"
                        className="btn Button Button-secondary fs-5"
                      >
                        Назад
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-xs-12 col-md-6  order-md-1 order-0">
                  <div className="row">
                    <div className="col-xs-12 ">
                      <GoogleAuth onSuccess={handleSuccessGoogleLogin} />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 order-md-0 order-1"></div>
              </div>
              <div className="row justify-content-center ">
                <div className="col text-center">
                  <p>
                    Имате проблем със системата?{" "}
                    <a
                      className="aei-primary-link"
                      href="mailto:contact@auto-expert.biz"
                    >
                      Изпратете ни съобщение
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
