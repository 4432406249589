import { CompanyHistoryEntryT } from "../../../api/types";

type SearchableCallbackT = (
  value: string | string[],
  key: string | string[]
) => void;

const config = [
  {
    title: "Дата",
    searchable: false,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      return row.created_at || "N/A";
    },
  },
  {
    title: "Фирмено ID",
    searchable: false,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      return row.custom_identifier || "N/A";
    },
  },
  {
    title: "VIN",
    searchable: false,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      return row.vin || "N/A";
    },
  },
  {
    title: "Регистрационен номер",
    searchable: false,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      return row.reg_number || "N/A";
    },
  },
  {
    title: "Потребител",
    searchable: true,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      if (callback) {
        return (
          <a
            href="/"
            className="aei-primary-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              callback(row.user.id, "user_id");
            }}
          >
            {row.user.name || "N/A"}
          </a>
        );
      }
      return row.user.name || "N/A";
    },
  },
  {
    title: "Клон",
    searchable: true,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      if (callback) {
        return (
          <a
            href="/"
            className="aei-primary-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              callback(row.branch.id, "branch_id");
            }}
          >
            {row.branch.name || "N/A"}
          </a>
        );
      }
      return row.branch.name || "N/A";
    },
  },
  {
    title: "Офис",
    searchable: true,
    render: (row: CompanyHistoryEntryT, callback?: SearchableCallbackT) => {
      if (callback) {
        return (
          <a
            href="/"
            className="aei-primary-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              callback(
                [row.office.id, row.branch.id],
                ["office_id", "branch_id"]
              );
            }}
          >
            {row.office.name || "N/A"}
          </a>
        );
      }
      return row.office.name || "N/A";
    },
  },
  {
    title: "Продукт",
    render: (row: CompanyHistoryEntryT) => {
      return row.product.display_name || "N/A";
    },
  },
  {
    title: "Модул",
    render: (row: CompanyHistoryEntryT) => {
      return row.module.display_name || "N/A";
    },
  },
  {
    title: "Сума",
    render: (row: CompanyHistoryEntryT) => {
      if (row.cost === null) {
        return "Лиценз";
      }
      return String(row.cost) === "0.00" ? "Безплатно" : row.cost;
    },
  },
];

export { config };
