import { useEffect, useState } from "react";
import { Button, Input } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../api/main";
import LinkButton from "../../components/common/Button/LinkButton";

const ResetPassword = () => {
  const [success, setIsSuccess] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [genericError, setGenericError] = useState<string>("");
  const [mailError, setMailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [resetToken, setResetToken] = useState<string>("");

  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) {
      setResetToken(() => "");
      navigate("/");
    } else {
      setResetToken(() => token);
    }
  }, [searchParams]);

  const handleResetPassword = async () => {
    setMailError(() => "");
    setPasswordError(() => "");
    if (email === "") {
      setMailError("Моля, въведете е-мейл адрес.");
      return;
    }

    try {
      await resetPassword({
        email,
        password: password || "",
        password_confirmation: confirmPassword || "",
        token: resetToken,
      });
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setIsSuccess(() => true);
    } catch (e: any) {
      if (e?.response?.data?.errors?.email) {
        setMailError(e.response.data.errors.email[0]);
      }
      if (e?.response?.data?.errors?.password) {
        setPasswordError(e.response.data.errors.password[0]);
      }
      if (e?.response?.data?.errors?.resetPassword) {
        setGenericError(e.response.data.errors.resetPassword[0]);
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-md-5  text-center">
              <h1>Забравена парола</h1>
            </div>
          </div>
          {genericError !== "" && (
            <div className="row justify-content-center">
              <div className="col-md-5  ">
                <p className="alert alert-danger"> {genericError}</p>
              </div>
            </div>
          )}
          {!success ? (
            <>
              <div className="row justify-content-center my-3">
                <div className="col-md-5  ">
                  <Input
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    label="Е-мейл"
                    value={email}
                    onChange={(value) => setEmail(() => value as string)}
                  />
                  {mailError !== "" && (
                    <p className="text-danger mb-0  mt-1">{mailError}</p>
                  )}
                </div>
              </div>
              <div className="row justify-content-center my-3">
                <div className="col-md-5  ">
                  <Input
                    name="password"
                    type="password"
                    placeholder="*****"
                    label="Парола"
                    value={password}
                    onChange={(value) => setPassword(() => value as string)}
                  />
                  {passwordError !== "" && (
                    <p className="text-danger mb-0  mt-1">{passwordError}</p>
                  )}
                </div>
              </div>
              <div className="row justify-content-center my-3">
                <div className="col-md-5  ">
                  <Input
                    name="confirm_password"
                    type="password"
                    placeholder="*****"
                    label="Потвърди парола"
                    value={confirmPassword}
                    onChange={(value) =>
                      setConfirmPassword(() => value as string)
                    }
                  />
                </div>
              </div>
              <div className="row my-4 justify-content-center ">
                <div className="col-md-5   ">
                  <Button
                    content="Запази"
                    type="primary"
                    onClick={() => handleResetPassword()}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row justify-content-center my-3">
                <div className="col-md-5 ">
                  <h5>
                    Успешно променихте паролата си. Моля, влезте в системата.
                  </h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5 ">
                  <LinkButton text="Вход" to="/login" type="primary" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
