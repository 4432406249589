import { IconLabel } from "../";
import mainLogo from "../../../resources/images/aei-logo.svg";
import "./Header.css";

import { Link, useLocation } from "react-router-dom";
import { getApiBaseUrl, getIsExperimental } from "../../utils/env";
import useAuthContext from "../../context/authentication";
import { MobileSidebarNav } from "../page-components";

const Header = () => {
  const { user, logout } = useAuthContext();
  // get the current route
  const currentRoute = useLocation().pathname;
  return (
    <div className="Header-bg aei-fonts">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div
                className="col-12 pt-3 pe-3 d-none d-md-block"
                // style={{ marginBottom: "-20px" }}
              >
                <div className="d-flex justify-content-end align-items-center">
                  {user &&
                    ["company_super_admin", "company_admin"].findIndex(
                      (role) => role === user.role.name
                    ) !== -1 && (
                      <Link
                        to={"/administration/users"}
                        className="Header-link"
                        style={{ marginRight: "20px" }}
                      >
                        Администрация
                      </Link>
                    )}
                  {!user ? (
                    <Link
                      to={"/login"}
                      className="mx-5 Header-link d-flex align-items-center"
                    >
                      <div>
                        <IconLabel name="person" sz={1} content="" />
                      </div>
                      <span
                        className="text-center d-inline-block"
                        style={{ fontSize: "19px" }}
                      >
                        Вход
                      </span>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={"/profile/settings"}
                        className="Header-link no-hover pe-3"
                      >
                        Здравейте, {user.firstName} {user.lastName}
                      </Link>
                      <a
                        href="/logout"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          logout();
                        }}
                        className="Header-link no-hover pe-3"
                      >
                        Изход
                      </a>
                    </>
                  )}
                  <a href="tel:+359884662265" className="Header-nav-link-phone">
                    <IconLabel
                      name="telephone"
                      content={<b>+359 884 66 22 65</b>}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row d-none d-md-block">
              <div className="col-12 d-flex justify-content-center Header-xl-container">
                <div className="Header-logo-container">
                  <Link
                    to="/"
                    className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
                  >
                    <img
                      src={mainLogo}
                      className="Header-logo-image"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="navbar navbar-expand-md navbar-dark justify-content-center">
                  <div
                    className="container-xxl"
                    // style={{ marginLeft: "60px", marginRight: "60px" }}
                  >
                    <header className="d-flex flex-wrap justify-content-center mb-1 w-100">
                      <button
                        className="navbar-toggler collapsed justify-content-end my-auto"
                        style={{ height: "50px" }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navigationMenu"
                        aria-controls="navigationMenu"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div className="w-100">
                        <div
                          className="navbar-collapse collapse w-100"
                          id="navigationMenu"
                        >
                          <ul className="w-100 Header-nav navbar-nav align-items-center justify-content-center">
                            <li className="nav-item mx-md-2">
                              <Link
                                to={"/"}
                                className={`nav-link Header-nav-links ${
                                  currentRoute === "/" ? "active" : ""
                                }`}
                              >
                                Начало
                              </Link>
                            </li>
                            <li className="nav-item dropdown mx-md-2">
                              <a
                                id="softwear"
                                className={`nav-link Header-nav-links dropdown-toggle `}
                                href="/"
                                data-is-active={`${
                                  currentRoute.includes("/info")
                                    ? "true"
                                    : "false"
                                }`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Софтуер
                              </a>
                              <ul className="dropdown-menu Header-dropdown-menu">
                                <li className="dropdown-item">
                                  <Link
                                    to={
                                      user
                                        ? "/aei-new-demo"
                                        : "/info/aei-new-demo"
                                    }
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI New Demo
                                  </Link>
                                </li>

                                <li className="dropdown-item">
                                  <Link
                                    to={user ? "/aei-new" : "/info/aei-new"}
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI New
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={
                                      user
                                        ? "/aei-ins-value"
                                        : "/info/aei-ins-value"
                                    }
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI InsValue
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={user ? "/aei-vin" : "/info/aei-vin"}
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI VIN
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={
                                      user
                                        ? "/aei-market-price"
                                        : "/info/aei-market-price"
                                    }
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI MarketPrice
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={user ? "/aei-easy" : "/info/aei-easy"}
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI Easy
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={
                                      user
                                        ? "/aei-tech-data"
                                        : "/info/aei-tech-data"
                                    }
                                    className="nav-link Header-nav-links"
                                  >
                                    AEI TechData
                                  </Link>
                                </li>
                                <li className="dropdown-item position-relative Header-dropdown-nested">
                                  <h6 className="dropdown-toggle Header-dropdown-title">
                                    VIP Клиенти
                                  </h6>
                                  <ul className="dropdown-menu Header-dropdown-menu Header-dropdown-nested-items">
                                    <li className="dropdown-item">
                                      <a
                                        className="nav-link Header-nav-links"
                                        href="https://auto-expert.biz/clients/vig/"
                                      >
                                        ЗАД Булстрад
                                      </a>
                                    </li>
                                    <li className="dropdown-item">
                                      <Link
                                        to={
                                          user
                                            ? "/clients/ozk/identification"
                                            : "/clients/ozk/login"
                                        }
                                        className="nav-link Header-nav-links"
                                      >
                                        ОЗК Застраховане
                                      </Link>
                                    </li>

                                    <li className="dropdown-item">
                                      <a
                                        className="nav-link Header-nav-links"
                                        href="https://auto-expert.biz/apps/bg/np/"
                                      >
                                        Дженерали България
                                      </a>
                                    </li>
                                    <li className="dropdown-item">
                                      <Link
                                        to={
                                          user
                                            ? "/aei-easy"
                                            : "/clients/porsche/login"
                                        }
                                        className="nav-link Header-nav-links"
                                      >
                                        Порше България
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="dropdown-item position-relative Header-dropdown-nested">
                                  <h6 className="dropdown-toggle Header-dropdown-title">
                                    Безплатен
                                  </h6>
                                  <ul className="dropdown-menu Header-dropdown-menu Header-dropdown-nested-items">
                                    <li className="dropdown-item">
                                      <a
                                        className="nav-link Header-nav-links"
                                        href="https://auto-expert.biz/free/autodict.php"
                                      >
                                        Автомобилен речник
                                      </a>
                                    </li>
                                    <li className="dropdown-item">
                                      <a
                                        className="nav-link Header-nav-links"
                                        href="https://auto-expert.biz/free/vin-decoder.php"
                                      >
                                        Декодиране VIN (рама)
                                      </a>
                                    </li>
                                    <li className="dropdown-item">
                                      <a
                                        className="nav-link Header-nav-links"
                                        href="https://auto-expert.biz/free/vehicle-production-date-decoder.php"
                                      >
                                        Дата на производство
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            <li className="nav-item mx-md-2">
                              <a
                                href={`${getApiBaseUrl()}/offers/aei-offer.pdf`}
                                target="_blank"
                                rel="noreferrer"
                                className="nav-link Header-nav-links"
                              >
                                Цени
                              </a>
                            </li>
                            <li className="nav-item mx-md-2">
                              <Link
                                to={"/about-us"}
                                className={`nav-link Header-nav-links ${
                                  currentRoute === "/about-us" ? "active" : ""
                                }`}
                              >
                                За нас
                              </Link>
                            </li>
                            <li className="nav-item mx-md-2">
                              <a
                                href="tel:+359884662265"
                                className={`nav-link Header-nav-links `}
                              >
                                Контакти
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-md-none align-items-center justify-content-between p-4">
              <div className="col-2 ">
                <Link to="/" className="">
                  <img src={mainLogo} width={90} alt="Logo" />
                </Link>
              </div>
              <div className="col-2 ">
                <MobileSidebarNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
