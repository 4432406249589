import { HistoryEntryT } from "../../../api/types";

const config = [
  {
    title: "Приложение",
    render: (row: any) => {
      return row.display_name || "N/A";
    },
  },
];

export { config };
