import { formatNumber } from "../../../../utils/helpers";

type BaseSummaryProps = {
  evaluationData: {
    original_price: number;
    custom_price: number;
    transmission_correction: number;
    options_correction: number;
    superstructure_correction: number;
    steering_correction: number;
    starting_price: number;
  };
};

const BaseSummary = ({ evaluationData }: BaseSummaryProps) => {
  return (
    <>
      <hr />
      <div className="row">
        <div className="col-6">Цена в ново от АЕИ:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.original_price)} лв.
        </div>
      </div>
      {/* If the original_price !== user_price */}
      {evaluationData.custom_price &&
        evaluationData.custom_price !== 0 &&
        evaluationData.original_price !== evaluationData.custom_price && (
          <div className="row">
            <div className="col-6">Цена в ново от потребителя:</div>
            <div className="col-xs-6 col-md-3 text-end">
              * {formatNumber(evaluationData.custom_price)} лв.
            </div>
          </div>
        )}

      {evaluationData.transmission_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за авт. скоростна кутия:</div>
          <div className="col-xs-6 col-md-3 text-end">
            + {formatNumber(evaluationData.transmission_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.options_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за допълнително оборудване:</div>
          <div className="col-xs-6 col-md-3 text-end">
            + {formatNumber(evaluationData.options_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.superstructure_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за надстройка:</div>
          <div className="col-xs-6 col-md-3 text-end">
            + {formatNumber(evaluationData.superstructure_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.steering_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за десен волан:</div>
          <div className="col-xs-6 col-md-3 text-end">
            - {formatNumber(evaluationData.steering_correction)} лв.
          </div>
        </div>
      )}
      <div className="row fw-bold">
        <div className="col-6 ">Начална цена за изчисленията:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.starting_price)} лв.
        </div>
      </div>
    </>
  );
};

export default BaseSummary;
