import {
  formatNumber,
  generalCorrection2name,
} from "../../../../utils/helpers";

type Reg49ResidualSummaryProps = {
  evaluationData: {
    base: number;
    damage_correction: number;
    usage_correction: number;
    general_correction: number;
    general_condition_correction: {
      value: number;
      breakdown: {
        engine?: number;
        body?: number;
        paint?: number;
        interior?: number;
        trunk?: number;
      };
    };
    residual_value: number;
  };
};

/*  
    Example of evaluationData:
'reg49_residual_values' => [
    "base" => $this->getStartingResidualValue(),
    "damage_correction" => $this->damageCorrection,
    "usage_correction" => $this->usageCorrection,
    "residual_value" => $this->residualReg49Value,
], */

const Reg49ResidualSummary = ({
  evaluationData,
}: Reg49ResidualSummaryProps) => {
  let breakdown: JSX.Element[] = [];

  if (evaluationData.general_condition_correction.breakdown) {
    breakdown = Object.entries(
      evaluationData.general_condition_correction.breakdown
    )
      .filter(([key, value]) => value !== 0)
      .map(([key, value]) => {
        return (
          <div className="row" key={key}>
            <div className="col-5 offset-1">
              - корекция за {generalCorrection2name[key]}:
            </div>
            <div className="col-xs-6 col-md-3 text-end">
              {value >= 0 ? "+" : "-"} {formatNumber(Math.abs(value))} лв.
            </div>
          </div>
        );
      });
  }
  return (
    <>
      <hr />
      <div className="row">
        <div className="col-6">
          Овехтена стойност по метода на Наредба 49 на КФН:
        </div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.base)} лв.
        </div>
      </div>
      {evaluationData.damage_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за повреди и липси:</div>
          <div className="col-xs-6 col-md-3 text-end">
            - {formatNumber(evaluationData.damage_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.general_correction !== 0 && (
        <div className="row">
          <div className="col-6">
            {evaluationData.general_correction > 0 ? "+" : "-"} корекция на
            цената :
          </div>
          <div className="col-xs-6 col-md-3 text-end">
            {formatNumber(evaluationData.general_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.usage_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за начин на употреба:</div>
          <div className="col-xs-6 col-md-3 text-end">
            - {formatNumber(evaluationData.usage_correction)} лв.
          </div>
        </div>
      )}
      {breakdown.length > 0 && (
        <>
          <div className="row">
            <div className="col-6">- корекция за общо състояние:</div>
            <div className="col-xs-6 col-md-3 text-end">
              {evaluationData.general_condition_correction.value > 0
                ? "+"
                : "-"}{" "}
              {formatNumber(
                Math.abs(evaluationData.general_condition_correction.value)
              )}{" "}
              лв.
            </div>
          </div>
          {breakdown}
        </>
      )}
      <div className="row fw-bold">
        <div className="col-6 ">
          Действителна стойност по метода на Наредба 49 на КФН:
        </div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.residual_value)} лв.
        </div>
      </div>
    </>
  );
};

export default Reg49ResidualSummary;
