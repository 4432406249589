import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { config } from "./tableConfig";
import { Button, Input } from "../../../components";
import { getHistory, openHistoryPDFGeneration } from "../../../api/main";
import {
  Loader,
  Datepicker,
  Pagination,
  ContentContainerWithSidebar,
} from "../../../components/common";

const initCriteria = {
  from: new Date(`${new Date().getFullYear() - 1}-01-01`),
  to: new Date(),
};

const History = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<any[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<{
    [key: string]: unknown;
  }>(initCriteria);
  const [pagination, setSetPagination] = useState<{
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  } | null>(null);
  const handleSearchHistory = async () => {
    // console.log(searchCriteria);
    setIsLoading(() => true);
    fetchHistory();
    setIsLoading(() => false);
  };

  const fetchHistory = async (page?: number) => {
    setIsLoading(() => true);

    const response = await getHistory(searchCriteria, page);
    setHistory(() => []);
    setSetPagination(() => null);
    if (response.data) {
      setHistory(() => response.data);
    }
    if (response.meta) {
      setSetPagination(() => response.meta);
    }

    setIsLoading(() => false);
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const rows: any =
    history && history.length > 0 ? (
      history.map((row) => (
        <tr key={row.id}>
          {config.map((col) => (
            <td key={Math.random()}>{col.render(row)}</td>
          ))}
          <td>
            <div className="d-flex">
              {row.can_edit && (
                <Link
                  to={"/aei-easy/" + row.id + "/edit"}
                  className="btn Button Button-secondary btn-sm mx-1"
                >
                  Редакция
                </Link>
              )}
              {!["internal-vin-decoding"].includes(row.module.name) ? (
                <Button
                  type="primary"
                  content="Печат"
                  size="sm"
                  onClick={() => {
                    openHistoryPDFGeneration(row.id);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={config.length + 1}>
          <div className="d-flex justify-content-center">
            Не бяха намерени резултати.
          </div>
        </td>
      </tr>
    );

  return (
    <ContentContainerWithSidebar>
      <div className="row">
        <div className="col-12">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">История</h3>
              <div className="row my-3 align-items-center justify-content-center">
                <div className="col-xl-3 col-lg-6 mt-3">
                  <Input
                    name="vin"
                    placeholder="VIN"
                    value={(searchCriteria?.vin as string) || ""}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        vin: value,
                      }));
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-6 mt-3">
                  <Input
                    name="reg_number"
                    placeholder="Рег. номер"
                    value={(searchCriteria?.reg_number as string) || ""}
                    onChange={(value) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        reg_number: value,
                      }));
                    }}
                  />
                </div>
                <div className="col-auto mt-3">
                  <p className="fw-bold m-0">Период</p>
                </div>
                <div className="col-sm-4   col-xl-2 col-lg-3 mt-3">
                  <Datepicker
                    date={(searchCriteria?.from as Date) || new Date()}
                    onChange={(date) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        from: date,
                      }));
                    }}
                  />
                </div>
                <div className="col-auto mt-3 ">-</div>
                <div className="col-sm-4   col-xl-2 col-lg-3 mt-3">
                  <Datepicker
                    date={(searchCriteria?.to as Date) || new Date()}
                    onChange={(date) => {
                      setSearchCriteria((prevState) => ({
                        ...prevState,
                        to: date,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto mt-3">
                  <Button
                    disabled={isLoading}
                    onClick={handleSearchHistory}
                    size="sm"
                    type="primary"
                    content="Търси"
                  />
                </div>
                <div className="col-auto mt-3">
                  <Button
                    onClick={() => setSearchCriteria(() => initCriteria)}
                    size="sm"
                    type="secondary"
                    content="Изчисти филтрите"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  Общ брой записи: {pagination?.total || 0}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-md-responsive">
                    <table className="table table-striped">
                      <thead className="sticky-top Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={config.length + 1}>
                              <div className="d-flex justify-content-center p-5">
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          rows
                        )}
                      </tbody>
                      <tfoot className="Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col"></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                centered
                pagination={pagination}
                onChange={(page) => fetchHistory(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default History;
