import { useEffect, useState } from "react";

import "./EmailVerification.css";
import { Link, useParams } from "react-router-dom";
import { Alert, Loader } from "../../components/common";
import useAuthContext from "../../context/authentication";
import { verifyAccount } from "../../api/main";

const EmailVerification = () => {
  const { user } = useAuthContext();
  useEffect(() => {
    document.title = "Потврърждение на акаунт | AutoExpert International";
  }, []);

  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleVerification = async () => {
    setIsLoading(() => true);
    try {
      const response = await verifyAccount(code || "");
      // console.log(response);
    } catch (e: any) {
      if (e.response.data.errors.verification) {
        setErrorMessage(() => e.response.data.errors.verification);
      }
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    handleVerification();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          {isLoading ? (
            <>
              <div className="row">
                <div className="col-12">
                  <h1>
                    Моля изчакайте докато процеса на потвърждение завърши.
                  </h1>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <Loader />
                </div>
              </div>
            </>
          ) : (
            <>
              {errorMessage ? (
                <Alert type="danger">{errorMessage}</Alert>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h1>
                        Благодарим за търпението, Вашият акаунт беше потвърден и
                        вече можете да използвате нашите услуги.
                      </h1>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      {!user ? (
                        <div className="row">
                          <div className="col-md-4 offset-md-4">
                            <Link
                              to={"/login"}
                              className="btn Button Button-primary"
                            >
                              Влезте в акаунта си.
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-4 offset-md-4">
                            <Link
                              to={"/profile/settings"}
                              className="btn Button Button-primary"
                            >
                              Моят профил
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
