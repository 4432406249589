import { useEffect, useState } from "react";
import Input from "../../common/Input/Input";
import PriceCorrection from "../PriceCorrection/PriceCorrection";
import SteeringWheelPosition from "./components/SteeringWheelPosition";
import { getResourceSuperstructures } from "../../../api/main";
import useEvaluationContext from "../../../context/evaluation";
import GeneralConditionCorrection from "../GeneralConditionCorrection/GeneralConditionCorrection";
import VehicleOptions from "../VehicleOptions/VehicleOptions";
import MileageCorrection from "./components/MillageCorrection";
import EndModelCorrection from "./components/EndModelCorrection";

const shouldVisualiseEndModelCorrection = (
  endModelDate?: string | Date,
  evalDate?: string | Date
) => {
  if (!endModelDate || !evalDate) {
    return false;
  }
  const vehicleDate = new Date(endModelDate);
  const evaluationDate = new Date(evalDate);
  return vehicleDate > evaluationDate;
};

const initialSideOptions = [
  {
    text: "Ляво",
    penalty: 0,
    selected: true,
  },
  {
    text: "Дясно (цената се намалява с 30%)",
    penalty: 30,
    selected: false,
  },
  {
    text: "Дясно (цената се намалява с 40%)",
    penalty: 40,
    selected: false,
  },
  {
    text: "Дясно (цената се намалява с 50%)",
    penalty: 50,
    selected: false,
  },
];
const initialSuperstructData: {
  value: number;
  type: "percent" | "number";
  description?: string;
} = {
  value: 0,
  type: "percent",
  description: "",
};

type PriceCorrectionsProps = {
  withAutomaticTransmission: boolean;
  withSuperstructure: boolean;
};

const PriceCorrections = ({
  withAutomaticTransmission,
  withSuperstructure,
}: PriceCorrectionsProps) => {
  const { evalCriteria, setEvalCriteria, vehicleData } = useEvaluationContext();

  const [superstructures, setSuperstructures] = useState<
    {
      label: string;
      value: string;
      note?: string;
      initialValue?: number;
    }[]
  >([]);

  const [selectedSide, setSelectedSide] = useState<
    {
      text: string;
      penalty: number;
      selected: boolean;
    }[]
  >(initialSideOptions);

  useEffect(() => {
    if (evalCriteria.superstructure_correction === undefined) {
      setSuperStructureData(() => ({ ...initialSuperstructData }));
    }
    // console.log(evalCriteria.steering_correction);
    if (evalCriteria.steering_correction === undefined) {
      setSelectedSide(() => [...initialSideOptions]);
    } else {
      setSelectedSide((old) => {
        return old.map((item) => {
          if (item.penalty === evalCriteria.steering_correction) {
            return {
              ...item,
              selected: true,
            };
          }
          return {
            ...item,
            selected: false,
          };
        });
      });
    }
  }, [evalCriteria]);

  const [superStructureData, setSuperStructureData] = useState<{
    value: number;
    type: "percent" | "number";
    description?: string;
  }>(initialSuperstructData);

  const handleSelectedSteeringSide = (value: number) => {
    setSelectedSide((old) => {
      return old.map((item) => {
        if (item.penalty === value) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
          selected: false,
        };
      });
    });
    setEvalCriteria((prev) => {
      return {
        ...prev,
        steering_correction: value as 0 | 30 | 40 | 50, // TODO: fix this
      };
    });
  };

  const fetchSuperstructures = async () => {
    const response = await getResourceSuperstructures();
    if (response) {
      setSuperstructures(() => {
        return response.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
            initialValue: item.percentage,
            note: item.note,
          };
        });
      });
    }
  };

  useEffect(() => {
    if (withSuperstructure) {
      fetchSuperstructures();
    }
  }, [withSuperstructure]);

  const onSuperstructureChange = (data: any) => {
    setSuperStructureData(data);
    setEvalCriteria((old) => {
      return {
        ...old,
        superstructure_correction_comment:
          data.description === undefined
            ? old.superstructure_correction_comment
            : data.description,
        superstructure_correction: {
          value: data.value,
          type: data.type,
        },
      };
    });
  };

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body card-body-margin-reset">
            <h4 className="text-center">Корекции</h4>
            <hr />
            <VehicleOptions />
            <div className="row">
              <div className="col-12 stripped-rows">
                {withSuperstructure && (
                  <PriceCorrection
                    className=" py-3"
                    label="Надстройка"
                    withSelect={superstructures}
                    withDescription
                    data={superStructureData}
                    onChange={onSuperstructureChange}
                  />
                )}
                {withAutomaticTransmission && (
                  <PriceCorrection
                    className=" py-3"
                    label="Корекция за автоматична скоростна кутия"
                    data={{
                      value: evalCriteria.transmission_correction?.value || 0,
                      type:
                        evalCriteria.transmission_correction?.type || "number",
                    }}
                    onChange={(data) => {
                      setEvalCriteria((old) => {
                        return {
                          ...old,
                          transmission_correction: {
                            value: data.value,
                            type: data.type,
                          },
                        };
                      });
                    }}
                  />
                )}
                <div className="row py-3">
                  <SteeringWheelPosition
                    items={selectedSide}
                    onChange={handleSelectedSteeringSide}
                  />
                </div>
                {evalCriteria && (
                  <>
                    <PriceCorrection
                      className="py-3"
                      label="Корекция за повреди и липси"
                      withDescription
                      data={{
                        description:
                          evalCriteria.damage_correction_comment || "",
                        value: evalCriteria.damage_correction?.value || 0,
                        type: evalCriteria.damage_correction?.type || "number",
                      }}
                      onChange={(data) => {
                        setEvalCriteria((old) => {
                          return {
                            ...old,
                            damage_correction_comment:
                              data.description === undefined
                                ? old.damage_correction_comment
                                : data.description,
                            damage_correction: {
                              value: data.value,
                              type: data.type,
                            },
                          };
                        });
                      }}
                    />
                    <PriceCorrection
                      className="py-3"
                      label="Корекция за начин на употреба (напр. taxi, линейка и др.)"
                      withDescription
                      data={{
                        description:
                          evalCriteria.usage_correction_comment || "",
                        value: evalCriteria.usage_correction?.value || 0,
                        type: evalCriteria.usage_correction?.type || "number",
                      }}
                      onChange={(data) => {
                        setEvalCriteria((old) => {
                          return {
                            ...old,
                            usage_correction_comment:
                              data.description === undefined
                                ? old.usage_correction_comment
                                : data.description,
                            usage_correction: {
                              value: data.value,
                              type: data.type,
                            },
                          };
                        });
                      }}
                    />
                    {evalCriteria.system_evaluation && <MileageCorrection />}
                    {evalCriteria.system_evaluation &&
                      shouldVisualiseEndModelCorrection(
                        vehicleData?.modelProdEndDate,
                        evalCriteria?.evaluation_data
                      ) && <EndModelCorrection />}
                    <GeneralConditionCorrection />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCorrections;
