import { MarketPriceItemT } from "../../../../api/types";

type PriceItemT =
  | MarketPriceItemT["median_price"]
  | MarketPriceItemT["graph_price"];

type PriceItemProps = {
  price: PriceItemT;
  withNoTax?: boolean;
};

const PriceItem = ({ price, withNoTax }: PriceItemProps) => {
  return (
    <>
      {price[0] && price[0].no_tax_price && withNoTax ? (
        <p className="mb-0 ms-3">
          {price.map((price, index) => {
            if (index === 0) {
              return (
                <span key={index}>
                  {Number(price?.no_tax_price.toFixed(0)).toLocaleString()}{" "}
                  {price.currency}{" "}
                </span>
              );
            }

            return (
              <span key={index}>
                ({Number(price?.no_tax_price.toFixed(0)).toLocaleString()}{" "}
                {price.currency})
              </span>
            );
          })}{" "}
          <b>без ДДС</b>.
        </p>
      ) : null}

      <p className="mb-0 ms-3">
        {price.map((price, index) => {
          if (index === 0) {
            return (
              <span key={index}>
                {Number(price.price.toFixed(0)).toLocaleString()}{" "}
                {price.currency}{" "}
              </span>
            );
          }

          return (
            <span key={index}>
              ({Number(price.price.toFixed(0)).toLocaleString()}{" "}
              {price.currency})
            </span>
          );
        })}{" "}
        <b>с ДДС</b>.
      </p>
    </>
  );
};

export default PriceItem;
