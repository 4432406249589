import { useMemo, useState } from "react";
import Button from "../../common/Button/Button";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

interface SearchButtonProps {
  disabled?: boolean;
  filtersConfirmMessage?:
    | string
    | JSX.Element[]
    | JSX.Element
    | null
    | undefined;
  configurations?: any;
  onConfirm: () => void;
  withConfirmation?: boolean;
}

const SearchButton = ({
  onConfirm,
  filtersConfirmMessage,
  disabled,
  configurations,
  withConfirmation = true,
}: SearchButtonProps) => {
  const [showFiltersConfirmModal, setShowFiltersConfirmModal] = useState(false);
  const [showChargeConfirmModal, setSetChargeConfirmModal] = useState(false);

  // Opens the filters modal, this will be the first modal to be opened
  const showFiltersModal = () => {
    setShowFiltersConfirmModal(() => true);
  };

  // Closes all the modal that have been opened
  const handleModalClose = () => {
    setSetChargeConfirmModal(() => false);
    setShowFiltersConfirmModal(() => false);
  };

  // Closes the filters modal and opens the charge confirmation modal
  const handleFiltersConfirmModal = () => {
    setSetChargeConfirmModal(() => true);
    setShowFiltersConfirmModal(() => false);
  };
  // Initializes the search and closes the modal
  // this will charge the user for the search
  const handleSearchConfirmation = () => {
    handleModalClose();
    onConfirm();
  };

  // Creates and saves the cost text until the configurations are loaded or changed
  const costText = useMemo(() => {
    if (!configurations?.cost) return "";
    const { cost } = configurations;
    return <span className="text-price">Цена {cost} лв.</span>;
  }, [configurations]);

  return (
    <>
      {withConfirmation ? (
        <>
          <ConfirmationModal
            title="Избрани данни за МПС"
            isShown={showFiltersConfirmModal}
            message={filtersConfirmMessage}
            onConfirm={handleFiltersConfirmModal}
            onClose={handleModalClose}
          />
          <ConfirmationModal
            title="Платена услуга"
            isShown={showChargeConfirmModal}
            message={
              <div>
                Сигурни ли сте, че искате да продължите?
                <br />
                {costText}
              </div>
            }
            onConfirm={handleSearchConfirmation}
            onClose={handleModalClose}
          />
        </>
      ) : null}
      <div className="row justify-content-between">
        <div className="col-auto">
          <p className="text-muted">
            Услугата се таксува на всяко търсене. {costText}
          </p>
        </div>
        <div className="col-auto">
          <Button
            type="primary"
            disabled={disabled}
            onClick={withConfirmation ? showFiltersModal : onConfirm}
          >
            Търси
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchButton;
