import { useEffect, useState } from "react";
import {
  FieldErrorMsg,
  Icon,
  ImageUploadInput,
} from "../../../../components/common";
import styles from "./ImagesUpload.module.css";

type ImagesUploadProps = {
  onImagesSelected?: (images: File[]) => void;
};

const ImagesUpload = ({ onImagesSelected }: ImagesUploadProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [imagePreviews, setImagePreviews] = useState<
    { dataURL: string; id: number }[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setImagePreviews(() => []);

    if (selectedFiles) {
      const previews: { dataURL: string; id: number }[] = [];
      selectedFiles.forEach((file, idx) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push({ dataURL: reader.result as string, id: idx });
          setImagePreviews(() => [...previews]);
        };
        reader.readAsDataURL(file);
      });

      onImagesSelected && onImagesSelected(selectedFiles);
    }
  }, [selectedFiles]);

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="text-center">Качване на снимки</h4>
            <h6 className="text-center">
              Формат: .png, .jpg, .jpeg Максимален размер: 2МБ.
            </h6>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <ImageUploadInput
                      multiple={true}
                      maxSize={2}
                      onErrorImageSelect={(message) => {
                        setError(() => message);
                      }}
                      accept="image/png, image/jpeg, image/jpg"
                      onImagesSelected={(images) => {
                        setSelectedFiles(() => Array.from(images || []));
                      }}
                    />
                    <FieldErrorMsg errors={error ? [error] : []} />
                    <div className="mt-2">
                      {imagePreviews.map((preview, index) => (
                        <div
                          key={index}
                          className={`me-2 mb-2 ${styles["preview-images-container"]}`}
                        >
                          <span
                            className={styles["remove-image"]}
                            onClick={() => {
                              setSelectedFiles((prev) => {
                                if (!prev) return null;
                                const files = [...(prev || [])];
                                files.splice(preview.id, 1);
                                return files;
                              });
                            }}
                          >
                            <Icon
                              name="trash"
                              className={`${styles["icon"]}`}
                            />
                          </span>
                          <img
                            className=" img-thumbnail img-responsive"
                            src={preview.dataURL}
                            alt={`Preview of ${index + 1} uploaded file`}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesUpload;
