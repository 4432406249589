import googleBtnLogo from "../../../resources/socials/google/normal.png";

declare var google: any;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

interface GoogleAuthProps {
  onSuccess: (accessToken: string) => void;
  type?: "login" | "register";
}

const GoogleAuth = ({ onSuccess }: GoogleAuthProps) => {
  const handleAccessTokenResponse = (response: any) => {
    const { access_token } = response;
    if (access_token) {
      onSuccess(access_token);
    }
  };

  const getAccessToken = () => {
    google.accounts.oauth2
      .initTokenClient({
        client_id: GOOGLE_CLIENT_ID,
        scope: "profile email",
        callback: handleAccessTokenResponse,
      })
      .requestAccessToken();
  };

  return (
    <button
      id="google-button"
      onClick={getAccessToken}
      // className="fs-4"
      style={{
        backgroundColor: "white",
        border: "1px solid #ccc",
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        position: "relative",
        width: "100%",
        height: 43,
      }}
    >
      <img
        src={googleBtnLogo}
        style={{ position: "absolute", top: 0, left: 0 }}
        alt="google logo"
        width={40}
      />
      <div style={{ flexGrow: 1, fontWeight: "bold", color: "#87837E" }}>
        Продължи с Google
      </div>
    </button>
  );
};

export default GoogleAuth;
