import { Link, useLocation } from "react-router-dom";
import styles from "./MobileSidebarNav.module.css"; // Import CSS module
import { Icon, IconLabel } from "../../common";
import { getApiBaseUrl } from "../../../utils/env";
import { Offcanvas } from "bootstrap";
import menuHamburger from "../../../../resources/assets/hamburger.svg";
import useAuthContext from "../../../context/authentication";

const links = [
  {
    title: "НАЧАЛО",
    path: "/",
  },
  {
    title: "СОФТУЕР",
    children: [
      {
        title: "AEI New Demo",
        path: "/info/aei-new-demo",
        auth_path: "/aei-new-demo",
      },
      {
        title: "AEI New",
        path: "/info/aei-new",
        auth_path: "/aei-new",
      },
      {
        title: "AEI InsValue",
        path: "/info/aei-ins-value",
        auth_path: "/aei-ins-value",
      },
      {
        title: "AEI VIN",
        path: "/info/aei-vin",
        auth_path: "/aei-vin",
      },
      {
        title: "AEI MarketPrice",
        path: "/info/aei-market-price",
        auth_path: "/aei-market-price",
      },
      {
        title: "AEI Easy",
        path: "/info/aei-easy",
        auth_path: "/aei-easy",
      },
      {
        title: "AEI TechData",
        path: "/info/aei-tech-data",
        auth_path: "/aei-tech-data",
      },
      {
        title: "VIP Клиенти",
        children: [
          {
            title: "ЗАД Булстрад",
            url: "https://auto-expert.biz/clients/vig/",
          },
          {
            title: "ОЗК Застраховане",
            path: "/clients/ozk/login",
            auth_path: "/clients/ozk/identification",
          },
          {
            title: "Дженерали България",
            url: "https://auto-expert.biz/apps/bg/np/",
          },
          {
            title: "Порше България",
            path: "/clients/porsche/login",
            auht_path: "/aei-easy",
          },
        ],
      },
      {
        title: "Безплатен",
        children: [
          {
            title: "Автомобилен речник",
            url: "https://auto-expert.biz/free/autodict.php",
          },
          {
            title: "Декодиране VIN (рама)",
            url: "https://auto-expert.biz/free/vin-decoder.php",
          },
          {
            title: "Дата на производство",
            url: "https://auto-expert.biz/free/vehicle-production-date-decoder.php",
          },
        ],
      },
    ],
  },
  {
    title: "ЦЕНИ",
    url: `${getApiBaseUrl()}/offers/aei-offer.pdf`,
    blank: true,
  },
  {
    title: "ЗА НАС",
    path: `/about-us`,
  },
  {
    title: "КОНТАКТИ",
    url: "tel:+359884662265",
  },
];

const MobileSidebarNav = () => {
  const location = useLocation();
  const { user, logout } = useAuthContext();
  const closeCanvas = () => {
    const bsOffcanvas = Offcanvas.getInstance(
      document.querySelector("#MobileSidebarNavFlyout") as HTMLElement
    );
    bsOffcanvas?.toggle();
  };

  const choosePath = (link: any) => {
    return user ? link.auth_path : link.path;
  };

  const renderLinks = (links: Array<any>) => {
    return links.map((link, idx) => {
      if (link.children) {
        const colapseId = `collapse_id_${Math.round(Math.random() * 100000)}`;
        return (
          <li key={idx} className="nav-item">
            <a
              data-bs-toggle="collapse"
              href={`#${colapseId}`}
              role="button"
              target={link.blank ? "_blank" : "_self"}
              rel="noreferrer"
              className={`nav-link no-hover d-inline-block ${
                styles["MobileSidebarNav-container-link"]
              } ${
                location.pathname === link.path &&
                styles["MobileSidebarNav-active"]
              }`}
            >
              {link.title}
            </a>
            <div className="collapse" id={colapseId}>
              <ul className="nav flex-column">{renderLinks(link.children)}</ul>
            </div>
          </li>
        );
      }

      if (link.url) {
        return (
          <li key={idx} className="nav-item">
            <a
              href={link.url}
              target={link.blank ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={() => {
                closeCanvas();
              }}
              className={`nav-link no-hover d-inline-block ${
                styles["MobileSidebarNav-container-link"]
              } ${
                location.pathname === link.path &&
                styles["MobileSidebarNav-active"]
              }`}
            >
              {link.title}
            </a>
          </li>
        );
      }
      if (link.path) {
        const path = link.auth_path ? choosePath(link) : link.path;

        return (
          <li key={idx} className="nav-item">
            <Link
              to={path}
              onClick={() => {
                closeCanvas();
              }}
              className={`nav-link no-hover d-inline-block ${
                styles["MobileSidebarNav-container-link"]
              } ${
                location.pathname === link.path &&
                styles["MobileSidebarNav-active"]
              }`}
            >
              {link.title}
            </Link>
          </li>
        );
      }
      return <></>;
    });
  };

  return (
    <>
      <a
        className={`d-md-none text-white ${styles["MobileSidebarNav-hamburger"]}`}
        data-bs-toggle="offcanvas"
        href="#MobileSidebarNavFlyout"
        role="button"
        aria-controls="MobileSidebarNavFlyout"
      >
        <img
          src={menuHamburger}
          alt="menu"
          className={styles["MobileSidebarNav-hamburger-icon"]}
        />
      </a>
      <div
        className="offcanvas offcanvas-start w-100 p-0 m-0"
        tabIndex={-1}
        data-bs-backdrop="false"
        id="MobileSidebarNavFlyout"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body p-0">
          <div
            className={`flex-column flex-shrink-0  full-width  ${styles["MobileSidebarNav-container"]} w-100 mt-5`}
          >
            <button
              type="button"
              className="btn position-absolute top-0 end-0 p-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Icon name="x" sz={1} className={`${styles["x-button"]}`} />
            </button>
            <ul className="nav nav-pills flex-column mb-auto">
              {renderLinks(links)}
              <li className="nav-item" style={{ marginTop: "16px" }}>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  {user &&
                    ["company_super_admin", "company_admin"].findIndex(
                      (role) => role === user.role.name
                    ) !== -1 && (
                      <Link
                        to={"/administration/users"}
                        className={styles["MobileSidebarNav-container-link"]}
                      >
                        Администрация
                      </Link>
                    )}
                  {!user ? (
                    <Link
                      to={"/login"}
                      onClick={() => {
                        closeCanvas();
                      }}
                      className={`${styles["MobileSidebarNav-container-login"]} d-flex position-relative`}
                    >
                      <div className={styles["login-icon"]}>
                        <IconLabel name="person" sz={1} content="" />
                      </div>
                      <span className="text-center d-inline-block">ВХОД</span>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={"/profile/settings"}
                        onClick={() => {
                          closeCanvas();
                        }}
                        className={styles["MobileSidebarNav-container-login"]}
                      >
                        Здравейте, {user.firstName} {user.lastName}
                      </Link>
                      <a
                        href="/logout"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          logout();
                        }}
                        className={styles["MobileSidebarNav-container-login"]}
                      >
                        Изход
                      </a>
                    </>
                  )}
                </div>
              </li>
              <li className={`nav-item ${styles["phone"]}`}>
                <a
                  href="tel:+359884662265"
                  className={`Header-nav-link-phone py-1 `}
                >
                  <IconLabel
                    name="telephone"
                    content={<b>+359 884 66 22 65</b>}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSidebarNav;
