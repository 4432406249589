import { useEffect } from "react";

import styles from "./AboutUs.module.css";
import carImage from "../../../resources/images/about-us-baner.png";
import topRight from "../../../resources/assets/about-us-top-right.svg";
import middle from "../../../resources/assets/about-us-middle.svg";
import backgroundAsset from "../../../resources/assets/about-us-background.svg";
import productsBanner from "../../../resources/assets/about-us-products.svg";

const AboutUs = () => {
  useEffect(() => {
    document.title = "За Нас | AutoExpert International";
  }, []);

  return (
    <div className="position-relative">
      <img
        src={topRight}
        className={`${styles["top-right-component"]}`}
        alt=""
      />
      <div className={`container aei-fonts ${styles["container"]}`}>
        <div className="row position-relative mb-5">
          <div className="col-12">
            <h1>Кои сме ние?</h1>
          </div>
          <div className="col-12">
            <div className={`${styles["text-bubble"]} position-relative`}>
              Ние сме водещ информационен брокер специализиран в събирането,
              обработката и продажбата на висококачествена информация за
              автомобили в България. Нашата мисия е да предоставим на нашите
              клиенти надеждни и актуални данни, които да им помогнат да вземат
              информирани решения.
              <br />
              <br />
              <b>AutoExpert International (AEI)</b> - вашият надежден партньор в
              света на автомобилната информация!
            </div>
            <img src={carImage} className={`${styles["car-image"]}`} alt="" />
          </div>
          <img
            src={middle}
            className={`${styles["middle-component"]}`}
            alt=""
          />
        </div>
      </div>
      <div
        className="full-width-container aei-fonts"
        style={{
          backgroundImage: `url(${backgroundAsset})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row mt-5">
            <div className={`col-12 ${styles["text-content-container"]}`}>
              <h3>Какво предлагаме?</h3>
              <ul>
                <li>
                  Над 40 вида технически характеристики на автомобилите като:
                  марка, модел, модификация, обем на двигателя, мощност в kW и
                  к.с., вид гориво, вид каросерия, брой врати и места и др.
                </li>
                <li>
                  В нашите бази с данни има информация за автомобилите внасяни в
                  България от официалните вносители на автомобили от 01.01.1995
                  г. до днес
                </li>
                <li>Снимки на всички тези автомобили</li>
                <li>Цени в ново състояние</li>
                <li>Декодиране на номер на рама/шаси/VIN</li>
                <li>
                  Различни методи за определяне действителната стойност на
                  автомобили: метод на АЕИ, метод на наредба 49 на КФН към МФ на
                  Р. България, метод на пазарните аналози
                </li>
                <li>VIP и API решения за корпоративни клиенти</li>
              </ul>
              <h3>Защо да изберете нас?</h3>
              <ul>
                <li>
                  <b>Надеждност:</b> Нашите данни са събрани от официални
                  вносители на автомобили
                </li>
                <li>
                  <b>Актуалност:</b> Гарантираме, че информацията, която
                  предоставяме, е винаги актуална и съобразена с последните
                  промени на пазара. Информацията се обновява през всеки април,
                  август и декември
                </li>
                <li>
                  <b>Професионализъм:</b> Екипът ни се състои от експерти с
                  дългогодишен опит в областта на автомобилната индустрия и
                  анализа на данни
                </li>
                <li>
                  <b>Индивидуален подход:</b> Всяка услуга е съобразена с
                  конкретните нужди на нашите клиенти, за да осигурим максимална
                  удовлетвореност
                </li>
              </ul>
              <h3>Как работим?</h3>
              <ul>
                <li>
                  <b>Събиране на данни:</b> Използваме най-новите технологии и
                  методологии за събиране на информация от различни източници
                </li>
                <li>
                  <b>Анализ и обработка:</b> Нашите специалисти обработват
                  данните, като ги анализират и проверяват за точност
                </li>
                <li>
                  <b>Предоставяне на информация:</b> Предоставяме готови анализи
                  и решения на нашите клиенти чрез лесен за използване интерфейс
                </li>
              </ul>
              <h3>Свържете се с нас</h3>
              <p>
                Нека направим първата стъпка към успешното ви автомобилно
                решение сега! Свържете се с нас и открийте как можем да Ви
                помогнем да постигнете своите цели.
              </p>
              <p>тел. +359 884 66 22 65, имейл: contact@auto-expert.biz </p>
              <p>
                <b>АутоЕксперт Интернешънъл</b> - точната информация води до
                правилни решения!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`container aei-fonts ${styles["container"]}`}>
        <div className="row position-relative mt-5">
          <div className="col-12">
            <h1 className=" text-center">Софтуер за нашите клиенти</h1>
          </div>
          <div className="col-12">
            <img
              src={productsBanner}
              className={`img-fluid ${styles["products-banner"]}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
