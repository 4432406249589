import { Fragment } from "react";
import { Header } from "./../components";
import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../context/authentication";
import ContactUsForm from "../components/page-components/ContactUsForm/ContactUsForm";
import { AdminSidebarMenu } from "../components/page-components";
import { ErrorProvider } from "../context/error";

const AdministrationLayout = () => {
  const { nightMode } = useAuthContext();
  const { user } = useAuthContext();
  if (
    !["company_super_admin", "company_admin"].includes(
      user?.role.name || "user"
    )
  )
    return <Navigate to="/" replace={true} />;

  return (
    <Fragment>
      <Header></Header>
      <div
        className={`${
          nightMode ? "Theme-dark" : "Theme-light"
        } main-container position-relative`}
      >
        <main className="container-fluid" style={{ minHeight: "100vh" }}>
          <ErrorProvider>
            <ContactUsForm />
            <AdminSidebarMenu />
            <Outlet />
          </ErrorProvider>
        </main>
      </div>
    </Fragment>
  );
};

export default AdministrationLayout;
