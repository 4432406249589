import { useEffect } from "react";
import {
  formatNumber,
  generalCorrection2name,
} from "../../../../utils/helpers";

type AEIResidualSummaryProps = {
  evaluationData: {
    base: number;
    damage_correction: number;
    usage_correction: number;
    general_condition_correction: {
      value: number;
      breakdown: {
        engine?: number;
        body?: number;
        paint?: number;
        interior?: number;
        trunk?: number;
      };
    };
    mileage_correction: number;
    residual_value: number;
  };
};
/* Example data:
      'system_residual_values' => [
          "base" => $this->getStartingResidualValue(),
          "damage_correction" => $this->damageCorrection,
          "usage_correction" => $this->usageCorrection,
          "general_condition_correction" => $this->generalConditionCorrection,
          "mileage_correction" => $this->getMileageCorrection(),
          "residual_value" => $this->residualValue,
      ],
    */

const AEIResidualSummary = ({ evaluationData }: AEIResidualSummaryProps) => {
  let breakdown: JSX.Element[] = [];

  if (evaluationData.general_condition_correction.breakdown) {
    breakdown = Object.entries(
      evaluationData.general_condition_correction.breakdown
    )
      .filter(([key, value]) => value !== 0)
      .map(([key, value]) => {
        return (
          <div className="row" key={key}>
            <div className="col-5 offset-1">
              - корекция за {generalCorrection2name[key]}:
            </div>
            <div className="col-xs-6 col-md-3 text-end">
              {value >= 0 ? "+" : "-"} {formatNumber(Math.abs(value))} лв.
            </div>
          </div>
        );
      });
  }

  return (
    <>
      <hr />
      <div className="row">
        <div className="col-6">Овехтена стойност по метода на АЕИ:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.base)} лв.
        </div>
      </div>
      {evaluationData.damage_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за повреди и липси:</div>
          <div className="col-xs-6 col-md-3 text-end">
            - {formatNumber(evaluationData.damage_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.usage_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за начин на употреба:</div>
          <div className="col-xs-6 col-md-3 text-end">
            - {formatNumber(evaluationData.usage_correction)} лв.
          </div>
        </div>
      )}
      {evaluationData.mileage_correction !== 0 && (
        <div className="row">
          <div className="col-6">- корекция за пробег:</div>
          <div className="col-xs-6 col-md-3 text-end">
            {evaluationData.mileage_correction > 0 ? "+" : "-"}{" "}
            {formatNumber(Math.abs(evaluationData.mileage_correction))} лв.
          </div>
        </div>
      )}
      {breakdown.length > 0 && (
        <>
          <div className="row">
            <div className="col-6">- корекция за общо състояние:</div>
            <div className="col-xs-6 col-md-3 text-end">
              {evaluationData.general_condition_correction.value >= 0
                ? "+"
                : "-"}{" "}
              {formatNumber(
                Math.abs(evaluationData.general_condition_correction.value)
              )}{" "}
              лв.
            </div>
          </div>
          {breakdown}
        </>
      )}
      <div className="row fw-bold">
        <div className="col-6">Действителна стойност по метода на АЕИ:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.residual_value)} лв.
        </div>
      </div>
    </>
  );
};

export default AEIResidualSummary;
