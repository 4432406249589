import { useEffect, useState } from "react";
import ImageUploadInput from "../../../common/ImageUploadInput/ImageUploadInput";
import useEvaluationContext from "../../../../context/evaluation";
import { FieldErrorMsg } from "../../../common";

const AdditionalEvalImagesUpload = () => {
  const { selectedFiles, setSelectedFiles } = useEvaluationContext();
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // console.log(selectedFiles);
    setImagePreviews(() => []);
    if (selectedFiles) {
      const previews: string[] = [];
      Array.from(selectedFiles).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result as string);
          setImagePreviews(() => [...previews]);
        };
        reader.readAsDataURL(file);
      });
    }
  }, [selectedFiles]);

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="text-center">Качване на снимки</h4>
            <h6 className="text-center">
              Формат: .png, .jpg, .jpeg Максимален размер: 2МБ.
            </h6>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <ImageUploadInput
                      multiple={true}
                      maxSize={2}
                      onErrorImageSelect={(message) => {
                        setError(() => message);
                      }}
                      accept="image/png, image/jpeg, image/jpg"
                      onImagesSelected={(images) => {
                        setSelectedFiles(() => images);
                      }}
                    />
                    <FieldErrorMsg errors={error ? [error] : []} />
                    <div className="mt-2">
                      {imagePreviews.map((preview, index) => (
                        <img
                          className="me-2 mb-2 img-thumbnail img-responsive"
                          key={index}
                          src={preview}
                          alt={`Preview of ${index + 1} uploaded file`}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalEvalImagesUpload;
