import { useState } from "react";
import { Button, Input } from "../../components";
import { requestResetLink } from "../../api/main";
import LinkButton from "../../components/common/Button/LinkButton";

const RequestResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [success, setIsSuccess] = useState<boolean>(false);
  const [mailError, setMailError] = useState<string>("");

  const handleRequestResetPassword = async () => {
    setMailError(() => "");
    if (email === "") {
      setMailError(() => "Моля, въведете е-мейл адрес.");
      return;
    }

    try {
      await requestResetLink(email);
      setEmail("");
      setIsSuccess(() => true);
    } catch (e: any) {
      if (e?.response?.data?.errors?.email) {
        setMailError(() => e.response.data.errors.email[0]);
      }
    }
  };

  return (
    <div className="container aei-fonts fs-5">
      <div className="row justify-content-center">
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-md-6  text-center">
              <h1>Забравена парола</h1>
            </div>
          </div>
          {!success ? (
            <>
              <div className="row justify-content-center">
                <div className="col-md-6 ">
                  <h5>
                    Моля, въведете Е-мейл адреса, с който сте регистрирани в
                    нашата система и ще получите линк за генериране на нова
                    парола.
                  </h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6  ">
                  <Input
                    sizetype="lg"
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    label="Е-мейл"
                    value={email}
                    onChange={(value) => setEmail(() => value as string)}
                  />
                  {mailError !== "" && (
                    <p className="text-danger mb-0  mt-2 fs-6">{mailError}</p>
                  )}
                </div>
              </div>
              <div className="row my-4 justify-content-center ">
                <div className="col-md-6   ">
                  <Button
                    content="Изпрати"
                    type="primary"
                    className="fs-5"
                    onClick={() => handleRequestResetPassword()}
                  />
                </div>
              </div>
              <div className="row justify-content-center ">
                <div className="col-md-6 ">
                  <p className="text-muted fs-6">
                    Ако не получите съобщение, моля, провере папка спам или се{" "}
                    <a
                      className="aei-primary-link "
                      href="mailto:contact@auto-expert.biz"
                    >
                      свържете с нас
                    </a>
                    .
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row justify-content-center my-3">
                <div className="col-md-6 ">
                  <h5>
                    Изпратихме Ви линк за генериране на нова парола на посочения
                    от вас е-мейл адрес.
                  </h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5 ">
                  <LinkButton text="Вход" to="/login" type="primary" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestResetPassword;
