import { useState } from "react";
import Loader from "../../../../common/Loader/Loader";
import Button from "../../../../common/Button/Button";
import { getMarketPrice } from "../../../../../api/main";
import useAuthContext from "../../../../../context/authentication";
import { MarketPriceItemT } from "../../../../../api/types";
import MarketPriceItem from "../../../MarketPriceItem/MarketPriceItem";

const MarketPrice = ({
  product,
  priceId,
  vin,
  onMarketFetch,
  preloadedData,
}: any) => {
  const { getUserBalance } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [marketPrice, setMarketPrice] =
    useState<MarketPriceItemT[]>(preloadedData);

  const loadMarketPrice = async () => {
    setError(() => undefined);
    setIsLoading(() => true);
    try {
      const response = await getMarketPrice(product, priceId, vin);
      getUserBalance();
      setMarketPrice(() => response);
      onMarketFetch(response);
    } catch (e: any) {
      setError(() => e.response.data.message);
    }
    setIsLoading(() => false);
  };

  let marketPriceComponent: any = <></>;
  if (marketPrice) {
    marketPriceComponent = marketPrice.map((marketPriceItem, index) => (
      <MarketPriceItem key={index} marketPriceItem={marketPriceItem} />
    ));
  }
  if (error) {
    marketPriceComponent = (
      <div className="row mt-3">
        <div className="col">
          <div className="row mb-0">
            <div className="col">
              <p className="mb-0">
                <b>Грешка:</b>
              </p>
              <p className="ms-3 mb-0">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-2">
          <div className="mb-1">
            {!marketPrice && (
              <Button
                type="primary"
                content="Пазарна цена"
                size="sm"
                onClick={loadMarketPrice}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="row my-5">
          <div className="col-2">
            <Loader />
          </div>
        </div>
      ) : (
        marketPriceComponent
      )}
    </>
  );
};

export default MarketPrice;
