import styles from "./InfoCard.module.css";
import LinkButton from "../../../../components/common/Button/LinkButton";
import { useId } from "react";
import openMoreIcon from "../../../../../resources/assets/open-more.svg";

interface InfoCardProps {
  title: string;
  content: string | JSX.Element;
  asset?: JSX.Element;
  collapse_asset?: JSX.Element;
  redirectTo?: string;
}

const InfoCard = ({
  title,
  content,
  asset,
  collapse_asset,
  redirectTo,
}: InfoCardProps) => {
  const collapseId = useId();

  return (
    <div className={`${styles["card-container"]} position-relative`}>
      {asset ? asset : null}
      <div className={`row p-3  text-center h-100`}>
        <div className="col-12 d-flex flex-column">
          <div className="row justify-content-between pt-2 mb-0">
            <div className="col-xs-12">
              <h2
                className={`font-weight-bold text-end text-md-center ${styles["card-title"]}`}
              >
                <a
                  data-bs-toggle="collapse"
                  href={`#${collapseId}`}
                  style={{ zIndex: 2 }}
                  className="float-start d-block d-md-none"
                >
                  <img src={openMoreIcon} alt="arrow-down" width={30} />
                </a>

                {title}
              </h2>
            </div>
          </div>
          <div className="row d-none d-md-flex flex-column flex-grow-1">
            <div className="col mt-3 d-flex ">
              <div className={`${styles["card-content"]}  px-3 mb-3`}>
                {content}
              </div>
            </div>
            {redirectTo && (
              <div className="col-auto text-right">
                <LinkButton
                  to={redirectTo}
                  text={"Към приложението"}
                  type="primary"
                  classes={`${styles["card-button"]} Button-semi-rounded`}
                  auto
                />
              </div>
            )}
          </div>

          <div className="row collapse no-animation d-md-none" id={collapseId}>
            <div className="col mt-3">
              {collapse_asset ? collapse_asset : null}
              <div className={`${styles["card-content"]} px-3`}>{content}</div>
              {redirectTo && (
                <div className="col-auto text-right">
                  <LinkButton
                    to={redirectTo}
                    text={"Към приложението"}
                    type="primary"
                    classes="Button-semi-rounded"
                    // size="lg"
                    auto
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
