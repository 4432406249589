import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "../../common";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 5;

const getItemStyle = (draggableStyle: any, nightMode: any = false) => {
  const commonStyle = {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "4px",
  };

  if (nightMode) {
    return {
      ...commonStyle,
      // change background colour if dragging
      background: "#010125",
      borderLeft: "3px solid #b1b1b1",
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }

  return {
    ...commonStyle,
    // change background colour if dragging
    background: "#f2f2f2",
    borderLeft: "3px solid #b1b1b1",
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
const getListStyle = (isDraggingOver: any, nighMode: any = false) => {
  const commonStyle = {
    padding: grid,
    minHeight: "500px",
  };
  if (nighMode) {
    return {
      background: isDraggingOver ? "#242843" : "#141530",
      ...commonStyle,
    };
  }

  return {
    background: isDraggingOver ? "#f6f6f6" : "#ffffff",
    ...commonStyle,
  };
};

type DragableListProps = {
  darkMode?: boolean;
  leftListTitle?: string;
  rightListTitle?: string;
  leftList: Array<{ id: string; content: string }>;
  rightList: Array<{ id: string; content: string }>;
  onDragResult?: (
    leftList: Array<{ id: string; content: string }>,
    rightList: Array<{ id: string; content: string }>
  ) => void;
};
const DragableList = ({
  darkMode,
  leftListTitle,
  rightListTitle,
  leftList,
  rightList,
  onDragResult,
}: DragableListProps) => {
  const [state, setState] = useState([leftList, rightList]);

  const onDragEnd = (dragResult: any) => {
    const { source, destination } = dragResult;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState: any = [...state];
      newState[sInd] = items;
      setState(() => newState);
      onDragResult && onDragResult(newState[0], newState[1]);
      return;
    }
    const result = move(state[sInd], state[dInd], source, destination);
    const newState = [...state];
    newState[sInd] = result[sInd];
    newState[dInd] = result[dInd];

    setState(() => newState);
    onDragResult && onDragResult(newState[0], newState[1]);
  };

  return (
    <div>
      <div className="d-flex">
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <div
              className="d-flex flex-column"
              style={{ width: "100%" }}
              key={Math.random()}
            >
              {ind === 0 && <h4 className="text-center">{leftListTitle}</h4>}
              {ind === 1 && <h4 className="text-center">{rightListTitle}</h4>}
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, darkMode)}
                    {...provided.droppableProps}
                  >
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              provided.draggableProps.style,
                              darkMode
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <Icon name="grip-vertical" sz={4} />
                              {item.content}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragableList;
