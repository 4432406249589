import { useState, memo, useRef, useEffect } from "react";
import "./AdditionalInfo.css";
import { Modal } from "bootstrap";

import MarketPrice from "./MarketPrice";
import { VehicleT } from "../../../../../utils/vehicles";
import Button from "../../../../common/Button/Button";
import Icon from "../../../../common/Icon/Icon";
import BasicTooltip from "../../../../common/BasicTooltip/BasicTooltip";
import Badge from "../../../../common/Badge/Badge";
import { openPDFGeneration } from "../../../../../api/main";
import { formatDate } from "../../../../../utils/helpers";
import { ProductTypeT } from "../../../../../api/types";

const product2EvaluationCTA = (product: string) => {
  switch (product) {
    case "easy":
    case "vip-ozk":
    case "vip-vig":
      return "Оценка";
    case "ins-value":
      return "Избери";
    default:
      return "Печат";
  }
};

const priceRow = (price: any) => {
  return (
    <>
      <div className="col-auto">
        <p className="mb-0">{price.publishedAt}</p>
      </div>
      <div className="col-auto">
        <p className="mb-0">{price.price.toLocaleString()} лв.</p>
      </div>
    </>
  );
};

const renderButton = (
  price: any,
  product: string,
  vin?: string,
  onPrintClick?: any,
  onEvaluationClick?: any
) => {
  return (
    <div className="mb-1">
      {!["easy", "vip-ozk", "vip-vig", "ins-value"].includes(product) ? (
        <Button
          type="primary"
          content={product2EvaluationCTA(product)}
          size="sm"
          onClick={() =>
            onPrintClick &&
            onPrintClick({
              id: price.id,
              vin: vin || "",
            })
          }
        />
      ) : (
        <Button
          type="primary"
          content={product2EvaluationCTA(product)}
          size="sm"
          onClick={() => {
            onEvaluationClick && onEvaluationClick(price.id);
          }}
        />
      )}
    </div>
  );
};

interface AdditionalInfoProps {
  product: ProductTypeT;
  vehicle: VehicleT;
  withMarketPrice?: boolean;
  onEvaluationClick?: (price_id: number) => void;
  onPrintClick?: (data: { [key: string]: any }) => void;
  vin?: string;
}

const AdditionalInfo = ({
  product,
  vehicle,
  vin,
  onPrintClick,
  withMarketPrice,
  onEvaluationClick,
}: AdditionalInfoProps) => {
  const [decodedOptions, setDecodedOptions] = useState<
    VehicleT["prices"][0]["standardOriginalOptions"] | null
  >(null);
  const modalRef = useRef(null);
  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    // Initialize the modal
    const bsModal = new Modal(modal, {});

    // Handle cleanup when the component unmounts
    return () => bsModal.dispose();
  }, []);

  const showDecodeModal = (
    options: VehicleT["prices"][0]["standardOriginalOptions"]
  ) => {
    document.body.classList.add("modal-open");
    setDecodedOptions(options);
  };
  const handleModalClose = () => {
    setDecodedOptions(null);
    document.body.classList.remove("modal-open");
  };

  const modal = decodedOptions ? (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row justify-items-between">
                  <div className="col-auto">
                    <h5 className="modal-title">Декодиране на опции</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <Button onClick={handleModalClose} type="primary" size="sm">
                      <Icon name="x" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body">
              {decodedOptions.map((option, index) => {
                let abbr =
                  option.count > 1
                    ? `${option.abbr}-${option.count}`
                    : option.abbr;
                const name =
                  option.count > 1
                    ? `${option.count} бр. ${option.name}`
                    : option.name;
                return (
                  <p key={index} className="mb-1">
                    {abbr}: {name}
                  </p>
                );
              })}
            </div>
            <div className="modal-footer">
              <Button
                type="primary"
                content="Затвори"
                size="sm"
                onClick={handleModalClose}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={handleModalClose}
      ></div>
    </>
  ) : (
    ""
  );
  const historyPrice =
    vehicle &&
    vehicle.prices.map((price, index) => {
      const options = price.standardOptions.map((option) => {
        let abbr =
          option.count > 1 ? `${option.abbr}-${option.count}` : option.abbr;
        const name =
          option.count > 1 ? `${option.count} бр. ${option.name}` : option.name;

        if (option.type && option.type === "added") {
          abbr = `+ ${abbr}`;
        } else if (option.type && option.type === "removed") {
          abbr = `- ${abbr}`;
        }
        return (
          <BasicTooltip
            key={option.name}
            id={`opt-${Math.random().toString(36).substring(2, 9)}`}
            tip={name}
            clean
          >
            <Badge className="px-2 mx-1" key={name} type={option.type}>
              {abbr}
            </Badge>
          </BasicTooltip>
        );
      });
      let optionsComponent = options;
      if (index === 0 && options.length === 0) {
        optionsComponent = [
          <>Не е налична информация за стандартно оборудване</>,
        ];
      }
      const isLastOprtion =
        ((options.length || options.length === 0) &&
          vehicle.prices[index + 1]?.standardOptions.length) ||
        index === vehicle.prices.length - 1;

      return (
        <div key={`${price.publishedAt}-${price.price}`} className="row mb-1 ">
          <div className="col">
            <div className="row justify-content-between ">
              <div className={"col-9"}>{optionsComponent}</div>
              {priceRow(price)}
            </div>
            {isLastOprtion ? (
              <>
                <div className="row justify-content-between mb-3 mt-2">
                  <div className="col-2">
                    <div className="mb-1">
                      <Button
                        type="primary"
                        content="Декодирай"
                        size="sm"
                        onClick={() => {
                          showDecodeModal(price.standardOriginalOptions);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row justify-content-end">
                      <div className="col-6">
                        {renderButton(
                          price,
                          product,
                          vin,
                          onPrintClick,
                          onEvaluationClick
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {withMarketPrice && index === vehicle.prices.length - 1 && (
                  <MarketPrice
                    product={product}
                    priceId={price.id}
                    vin={vin}
                    onMarketFetch={(data: any) => {
                      vehicle.prices[index].marketPrice = data;
                    }}
                    preloadedData={vehicle.prices[index].marketPrice}
                  />
                )}
                <hr />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    });

  return (
    <div className="container-fluid">
      {modal}
      <div className="row p-3">
        <div className="col">
          <div className="row">
            <div className="col">
              <p>
                Моделът се произвежда от{" "}
                {formatDate(vehicle.modelProdStartDate)} до{" "}
                {vehicle.modelProdEndDate.startsWith("2155") ||
                !vehicle.modelProdEndDate
                  ? "момента"
                  : formatDate(vehicle.modelProdEndDate)}
                . Подмоделът се произвежда от{" "}
                {formatDate(vehicle.submodelProdStartDate)} до{" "}
                {vehicle.submodelProdEndDate.startsWith("2155") ||
                !vehicle.submodelProdEndDate
                  ? "момента"
                  : formatDate(vehicle.submodelProdEndDate)}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">{historyPrice}</div>
              </div>
              {/* {withoutPriceBreakdown ? (
                <div className="row ">
                  <div className="col-2 offset-10">
                    {renderButton(
                      vehicle.prices[vehicle.prices.length - 1],
                      product,
                      vin,
                      onPrintClick,
                      onEvaluationClick
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AdditionalInfo);
