import React, { createContext, useContext, useEffect, useState } from "react";

import { getEvaluationData } from "../api/main";
import { VehicleT, parseVehicle } from "../utils/vehicles";
import { MarketPriceItemT, ProductTypeT } from "../api/types";

type TypedCorrectionT = {
  type: "number" | "percent";
  value: number;
};

export type GeneralConditionT = {
  engine?: number;
  body?: number;
  paint?: number;
  interior?: number;
  trunk?: number;
};

type EvalCriteriaT = {
  price_id?: number;
  vin?: string;
  registration_number?: string;
  evaluation_data?: Date;
  first_registration_date?: Date;
  starting_price?: number;
  transmission_correction?: TypedCorrectionT;
  superstructure_correction_comment?: string;
  superstructure_correction?: TypedCorrectionT;
  options_correction?: TypedCorrectionT;
  steering_correction?: 0 | 30 | 40 | 50;
  force_production_end?: boolean;
  reg49_evaluation?: boolean;
  system_evaluation?: boolean;
  mileage?: number;
  general_condition?: GeneralConditionT;
  damage_correction_comment?: string;
  damage_correction?: TypedCorrectionT;
  usage_correction_comment?: string;
  usage_correction?: TypedCorrectionT;
  description?: string;
  reg49?: {
    vehicle_type_id: string;
    country_id?: string;
  };
};

interface EvaluationContextType {
  evalCriteria: EvalCriteriaT;
  product: ProductTypeT;
  evalSummary: any;
  setProduct: React.Dispatch<React.SetStateAction<ProductTypeT>>;
  setEvalCriteria: React.Dispatch<React.SetStateAction<EvalCriteriaT>>;
  isLoading: boolean;
  initialLoad: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  vehicleData?: VehicleT | null;
  setVehicleData?: React.Dispatch<React.SetStateAction<VehicleT | null>>;
  errors: { [key: string]: string[] } | null;
  setErrors: React.Dispatch<
    React.SetStateAction<{ [key: string]: string[] } | null>
  >;
  marketPrices: MarketPriceItemT[];
  setMarketPrices: React.Dispatch<React.SetStateAction<MarketPriceItemT[]>>;
  selectedFiles: FileList | null;
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>;
  setAdditionalData: React.Dispatch<React.SetStateAction<any>>;
  additionalData: any;
  evaluate: () => any;
}

export const EvaluationContext = createContext<EvaluationContextType>({
  product: "easy",
} as EvaluationContextType);

export const EvaluationProvider: React.FC<any> = ({
  children,
  initEvalCriteria,
  initEvalSummary,
  initAdditionalData,
  editMode: editModeProp,
}: any) => {
  const [editMode, setEditMode] = useState<boolean>(editModeProp);
  const [evalCriteria, setEvalCriteria] = useState<EvalCriteriaT>(
    initEvalCriteria || {}
  );
  const [evalSummary, setEvalSummary] = useState<any>(initEvalSummary || null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vehicleData, setVehicleData] = useState<VehicleT | null>(null);
  const [product, setProduct] = useState<ProductTypeT>("easy");
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(
    null
  );
  const [marketPrices, setMarketPrices] = useState<MarketPriceItemT[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [additionalData, setAdditionalData] = useState<any>(initAdditionalData || {});

  useEffect(() => {
    evaluate();
  }, [evalCriteria.reg49_evaluation, evalCriteria.system_evaluation]);

  useEffect(() => {
    init();
  }, [evalCriteria.price_id]);

  const evaluate = async () => {
    try {
      if (evalCriteria.price_id) {
        const evalCriteriaCopy = { ...evalCriteria };
        delete evalCriteriaCopy.price_id;

        if (!validateEvalCriteria(evalCriteriaCopy)) {
          throw new Error("Invalid eval criteria");
        }

        const response = await getEvaluationData(
          product,
          String(evalCriteria.price_id),
          evalCriteriaCopy
        );
        const { evaluation } = response;
        setEvalSummary(() => evaluation);
        return evaluation;
      }
    } catch (error) {
      return null;
    }
    setIsLoading(false);
  };

  const validateEvalCriteria = (evalCriteria: EvalCriteriaT) => {
    const errors: { [key: string]: string[] } = {};
    let isValid = true;
    if (
      evalCriteria.first_registration_date &&
      evalCriteria.evaluation_data &&
      evalCriteria.first_registration_date >= evalCriteria.evaluation_data
    ) {
      errors["first_registration_date"] = [
        "Дата на първа регистрация трябва да е преди датата на оценка",
      ];
      isValid = false;
    }

    setErrors(() => errors);

    return isValid;
  };

  const init = async () => {
    if (editMode) return await editLoad();

    await initialLoad();
  };

  const editLoad = async () => {
    try {
      const response = await getEvaluationData(
        product,
        String(evalCriteria.price_id)
      );
      // console.log(response);
      const { vehicle } = response;
      const parsedData = parseVehicle(vehicle);

      setVehicleData(() => parsedData);
    } catch (error) {}
    setIsLoading(false);
  };

  const initialLoad = async () => {
    try {
      if (evalCriteria.price_id) {
        const response = await getEvaluationData(
          product,
          String(evalCriteria.price_id)
        );
        // console.log(response);
        const { vehicle, evaluation } = response;
        const parsedData = parseVehicle(vehicle);

        setEvalSummary(() => evaluation);
        setVehicleData(() => parsedData);
        const publishDate = new Date(parsedData.prices[0].publishedAt);
        const publishYear = publishDate.getFullYear();
        const firstRegistrationDate = new Date(publishYear, 6, 1);

        setEvalCriteria((prevState) => ({
          price_id: prevState.price_id,
          registration_number: prevState.registration_number,
          vin: prevState.vin,
          first_registration_date: firstRegistrationDate,
          evaluation_data: new Date(),
          starting_price: parsedData.prices[0].price,
          // reg49_evaluation: true,
        }));
      }
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <EvaluationContext.Provider
      value={{
        product,
        setProduct,
        evalCriteria,
        initialLoad,
        setEvalCriteria,
        isLoading,
        setIsLoading,
        vehicleData,
        errors,
        marketPrices,
        setMarketPrices,
        evaluate,
        evalSummary,
        selectedFiles,
        setSelectedFiles,
        setErrors,
        setAdditionalData,
        additionalData,
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};

/**
 * Custom hook to use the evaluation context
 *
 * @returns {EvaluationContext} The evaluation context
 */
export default function useEvaluationContext() {
  return useContext(EvaluationContext);
}
