import { Modal } from "bootstrap";
import { useEffect, useRef } from "react";
import Button from "../common/Button/Button";
import Icon from "../common/Icon/Icon";

interface ErrorModalProps {
  title?: string;
  isShown?: boolean;
  disabled?: boolean;
  message?: string | JSX.Element[] | JSX.Element | null | undefined;
  onConfirm?: () => void;
  onClose?: () => void;
}

const ErrorModal = ({
  onClose,
  onConfirm,
  title,
  message,
  isShown,
}: ErrorModalProps) => {
  const modalRef = useRef(null);
  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    // Initialize the modal
    const bsModal = new Modal(modal, {});

    // Handle cleanup when the component unmounts
    return () => bsModal.dispose();
  }, []);

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  const modal = isShown ? (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row justify-items-between">
                  <div className="col-auto">
                    <h5 className="modal-title">
                      <Icon name="exclamation-triangle me-2" sz={4} />
                      {title}
                    </h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <Button onClick={onClose} type="primary" size="sm">
                      <Icon name="x" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {message ? <div className="modal-body"> {message}</div> : <></>}
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Button
                      type="primary"
                      content="Затвори"
                      size="sm"
                      onClick={onClose}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  ) : (
    <></>
  );
  return modal;
};

export default ErrorModal;
