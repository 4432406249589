import "./AutoExpertEasyEdit.css";
import { useEffect, useState } from "react";
import { getEvalEntryForEdit, getProduct } from "../../api/main";
import { ProductT, ProductTypeT } from "../../api/types";
import Loader from "../../components/common/Loader/Loader";
import ProductAccessDenied from "../../components/page-components/ProductAccessDenied/ProductAccessDenied";
import VehicleEvaluation from "../../components/page-components/VehicleEvaluation/VehicleEvaluation";
import { EvaluationProvider } from "../../context/evaluation";
import { useNavigate, useParams } from "react-router-dom";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

const PRODUCT_NAME = "easy";

// TODO: extract fetch logic to a custom hook
const AutoExpertEasy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "AEI Easy Edit | AutoExpert International";
  }, []);
  const { evalId } = useParams();

  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductT>();

  const [evaluationPriceId, setEvaluationPriceId] = useState<string>("");
  const [evaluation, setEvaluation] = useState<any>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const fetchProductConfig = async () => {
    try {
      setPageIsLoading(() => true);
      const response = await getProduct(PRODUCT_NAME);
      setProduct(() => response);
      if (response.can_use === false) {
        setPageIsLoading(() => false);
        return setAccessDenied(() => true);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setAccessDenied(() => true);
      }
    }
    setPageIsLoading(() => false);
  };

  const fetchEvaluationData = async () => {
    try {
      setPageIsLoading(() => true);
      const response = await getEvalEntryForEdit(
        PRODUCT_NAME,
        evalId as string
      );
      const { data: evalData } = response;
      // console.log(evalData);

      setEvaluationPriceId(() => evalData.eval_criteria.price_id);
      setEvaluation(() => {
        const data = { ...evalData };
        data.eval_criteria.evaluation_data = new Date(
          data.eval_criteria.evaluation_data
        );
        data.eval_criteria.first_registration_date = new Date(
          data.eval_criteria.first_registration_date
        );
        return data;
      });
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setAccessDenied(() => true);
      }
      if (err.response && err.response.status === 404) {
        setErrorMessage(
          () =>
            err.response.data.error.message || "Не може да се намери оценката"
        );
      }
    }
    setPageIsLoading(() => false);
  };

  useEffect(() => {
    fetchProductConfig();
    fetchEvaluationData();
  }, []);

  return accessDenied ? (
    <ProductAccessDenied product={product} />
  ) : pageIsLoading ? (
    <Loader />
  ) : evaluation ? (
    <>
      <EvaluationProvider
        initEvalCriteria={evaluation.eval_criteria}
        initEvalSummary={evaluation.eval_summary}
        initAdditionalData={evaluation.additional_data}
        editMode
      >
        <VehicleEvaluation
          product={product?.name as ProductTypeT}
          priceId={evaluationPriceId}
          archive={evalId as any}
          vin={evaluation.eval_criteria.vin || ""}
          regNumber={evaluation.eval_criteria.registration_number || ""}
          onBackClick={() => {
            navigate("/profile/history");
          }}
          editMode
        />
      </EvaluationProvider>
    </>
  ) : errorMessage ? (
    <>
      <ErrorModal
        message={errorMessage}
        title="Упс..."
        isShown
        onClose={() => {
          navigate("/profile/history");
        }}
        onConfirm={() => {
          navigate("/profile/history");
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default AutoExpertEasy;
