import { useCallback, useEffect, useState } from "react";
import {
  Loader,
  Button,
  FieldErrorMsg,
  ImageUploadInput,
  ContentContainerWithSidebar,
} from "../../../components/common";
import { getCompanyData, uploadCompanyBanner } from "../../../api/main";
import useAuthContext from "../../../context/authentication";

type SettingsProps = {};
type CompanyConfig = {
  pdfBannerPath?: string;
};

const Settings = (props: SettingsProps) => {
  const { company, refreshCompanyData } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [errors, setErrors] = useState<{ selectedFiles?: string }>({});

  const uploadBannerImage = async () => {
    if (!selectedFiles) {
      setErrors((prev) => ({
        ...prev,
        selectedFiles: "Моля изберете файл",
      }));
      return;
    }
    setIsLoading(true);
    try {
      await uploadCompanyBanner(selectedFiles[0]);
      refreshCompanyData();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentContainerWithSidebar>
      <div className="row">
        <div className="col-8">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">Настройки</h3>
              {isLoading ? (
                <div className="row my-5 ">
                  <div className="col">
                    <Loader />
                  </div>
                </div>
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="pdfBannerPath"
                          className="form-label fw-bold"
                        >
                          Качи банер за PDF
                        </label>
                        <ImageUploadInput
                          //   multiple={true}
                          //   maxSize={2}
                          onErrorImageSelect={(message) => {
                            setErrors((prev) => ({
                              ...prev,
                              selectedFiles: message,
                            }));
                          }}
                          accept="image/png, image/jpeg, image/jpg"
                          onImagesSelected={(images) => {
                            setSelectedFiles(() => images);
                          }}
                        />
                        {errors.selectedFiles && (
                          <FieldErrorMsg errors={[errors.selectedFiles]} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="offset-md-10 col-md-2">
                      <Button
                        type="primary"
                        className="btn btn-primary"
                        onClick={uploadBannerImage}
                      >
                        Качи
                      </Button>
                    </div>
                  </div>
                  {company?.configurations?.pdfBannerPath && (
                    <div className="row mb-2">
                      <div className="col-12">
                        <label
                          htmlFor="pdfBannerPath"
                          className="form-label fw-bold"
                        >
                          Текущ банер за PDF
                        </label>
                      </div>
                      <div className="col-12">
                        <img
                          src={company?.configurations?.pdfBannerPath}
                          alt="pdf banner"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default Settings;
