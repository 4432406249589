import { ProductT } from "../../../api/types";
import LicensePricing from "../LicensePricing/LicensePrising";

type ProductAccessDeniedProps = {
  product?: ProductT;
};

const ProductAccessDenied = ({ product }: ProductAccessDeniedProps) => {
  const additionalComponents =
    product?.additional &&
    product?.additional.map((item: any, index: string) => {
      if (item.name === "license_pricing") {
        return <LicensePricing key={index} data={item.data} />;
      }
      return <></>;
    });

  return (
    <div className="row justify-content-center">
      <div className="col-6">
        <div className="card card-themed">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h3 className="card-title">
                  Нямате достъп до{" "}
                  {product ? product.display_name : "този продукт"}
                </h3>
                <p className="card-text">
                  За да получите достъп до{" "}
                  {product ? product.display_name : "този продукт"}, моля
                  свържете се с нас:
                </p>
              </div>
            </div>
            <div className="row my-2">
              <div className="col">
                Телефон:{" "}
                <a className="aei-primary-link" href="tel:+359884662265">
                  +359 884 66 22 65
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                Имейл:{" "}
                <a
                  className="aei-primary-link"
                  href="mailto:contact@auto-expert.biz"
                >
                  contact@auto-expert.biz
                </a>
              </div>
            </div>
            <>{additionalComponents}</>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAccessDenied;
