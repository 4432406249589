import "./AutoExpertEasyLanding.css";

import ozkLogo from "../../../resources/images/partners/ozk-logo.png";
import dziLogo from "../../../resources/images/partners/dzi-logo.png";
import preview from "../../../resources/images/laptop-easy-preview.png";
import porscheLogo from "../../../resources/images/partners/porsche-logo.png";
import bulstradLogo from "../../../resources/images/partners/bulstrad-logo.png";
import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";

const PRODUCT_NAME = "easy";

const descriptionData = {
  title:
    "Специализирано онлайн приложение за изготвяне на оценъчна автотехническа експертиза.",
  description:
    "Приложението предоставя възможност за изготвяне на автотехническа експертиза съгласно методиката на Наредба 49 на КФН и/или метода на пазарните цени.",
  showStats: true,
};

const clients = [
  {
    name: "bulstrad",
    url: "https://www.bulstrad.bg/",
    logo: bulstradLogo,
  },
  {
    name: "DZI",
    url: "https://www.dzi.bg/",
    logo: dziLogo,
  },
  {
    name: "OZK",
    url: "https://www.ozk.bg/",
    logo: ozkLogo,
  },
  {
    name: "Porsche Bulgaria",
    url: "http://www.porschebulgaria.bg/",
    logo: porscheLogo,
  },
];

// TODO: extract fetch logic to a custom hook
const AutoExpertEasyLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "АутоЕксперт Лесна Оценка на МПС",
        description: (
          <>
            <p>
              С този продукт Вие можете да направите задълбочена оценъчна
              автотехническа експертиза (АТЕ). Това става само в 2 стъпки. В
              първата се извършва идентификация на превозното средство, а във
              втория - изчисляване на неговата действителна стойност. Резултът
              се оформя в професионална АТЕ (PDF файл), която можете да
              предоставите на всеки, който има нужда от нея.
            </p>
            <p>
              Продуктът е предназначен за търговци на автомобили, авто експерти,
              застрахователи, лизингови компании, банки, данъчни служби, митници
              и др.
            </p>
          </>
        ),
        image: preview,
        loginUrl: "/login?redirect=aei-easy",
      }}
      description={descriptionData}
      clients={clients}
    />
  );
};

export default AutoExpertEasyLanding;
