import { useEffect, useState } from "react";

import { IconLabel } from "../../../components";
import Loader from "../../../components/common/Loader/Loader";
import { ContentContainerWithSidebar } from "../../../components/common";
import { getProducts } from "../../../api/main";

import { config } from "./tableConfig";
import LinkButton from "../../../components/common/Button/LinkButton";
import useAuthContext from "../../../context/authentication";
import { Navigate } from "react-router-dom";

export interface Item {
  id: string;
  content: string;
}

const Applications = () => {
  const { company, user } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>([]);

  const fetchProducts = async () => {
    setIsLoading(() => true);
    const response = await getProducts();
    if (response) {
      setProducts(() => response);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const rows = products.map((row) => (
    <tr key={row.id}>
      {config.map((col) => (
        <td key={Math.random()}>{col.render(row)}</td>
      ))}
      <td width={"20%"}>
        <LinkButton
          type="primary"
          size="sm"
          to={
            ["company_super_admin", "company_admin"].includes(
              user?.role.name || "user"
            )
              ? `/administration/applications/${row.name}`
              : `/profile/applications/${row.name}`
          }
          text={
            <IconLabel name="gear" type="bi" sz={5} content={"Конфигурация"} />
          }
        />
      </td>
    </tr>
  ));
  if (
    !["company_super_admin", "company_admin"].includes(
      user?.role.name || "user"
    ) &&
    !company?.isIndividual
  )
    return <Navigate to="/" replace={true} />;
  return (
    <ContentContainerWithSidebar>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">Приложения</h3>
              <div className="row">
                <div className="col-12">
                  <div className="table-md-responsive">
                    <table className="table table-striped">
                      <thead className="sticky-top Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col">Опции</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={config.length + 1}>
                              <div className="d-flex justify-content-center p-5">
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          rows
                        )}
                      </tbody>
                      <tfoot className="Table-header">
                        <tr>
                          {config.map((col) => (
                            <th key={Math.random()} scope="col">
                              {col.title}
                            </th>
                          ))}
                          <th scope="col">Опции</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default Applications;
