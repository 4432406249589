import "./AdminSidebarMenu.css";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../common";

const links = [
  {
    title: "Настройки",
    path: "/administration/settings",
  },
  {
    title: "Потребители",
    path: "/administration/users",
  },
  {
    title: "Приложения",
    path: "/administration/applications",
  },
  {
    title: "История",
    path: "/administration/history",
  },
];

const AdminSidebarMenu = () => {
  const location = useLocation();
  const renderLinks = () => {
    return links.map((link, idx) => {
      return (
        <li key={idx} className="nav-item">
          <Link
            to={link.path}
            className={`nav-link no-hover ${
              location.pathname === link.path && "active"
            }`}
          >
            {link.title}
          </Link>
        </li>
      );
    });
  };

  return (
    <>
      <div className="flex-column flex-shrink-0 p-3 position-absolute SidebarMenu-container d-none d-md-flex">
        <div className="d-flex align-items-center justify-content-center mb-3 text-white ">
          <span className="fs-4">Администрация</span>
        </div>
        <ul className="nav nav-pills flex-column mb-auto">{renderLinks()}</ul>
        <hr />
      </div>
      <a
        className="btn Button Button-primary Button-w-auto d-md-none SidebarMenu-hamburger text-white"
        data-bs-toggle="offcanvas"
        href="#sideBarMenuFlyout"
        role="button"
        aria-controls="sideBarMenuFlyout"
      >
        <Icon name="list" />
      </a>
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="sideBarMenuFlyout"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <div className="flex-column flex-shrink-0 p-3 position-absolute SidebarMenu-container full-width ">
            <div className="d-flex align-items-center justify-content-center mb-3 text-white ">
              <span className="fs-4">Администрация</span>
            </div>
            <button
              type="button"
              className="btn position-absolute top-0 end-0 p-2 text-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Icon name="x" sz={1} />
            </button>
            <ul className="nav nav-pills flex-column mb-auto">
              {renderLinks()}
            </ul>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebarMenu;
