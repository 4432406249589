import { Input } from "../../../common";
import useEvaluationContext from "../../../../context/evaluation";
import { useState } from "react";
const PLACEHOLDER_MESSAGE =
  "напр.:Тази оценка беше направена във връзка с щета No ... от 01.07.2023";
const EvalInformation = () => {
  const { evalCriteria, setEvalCriteria } = useEvaluationContext();
  const [placeholder, setPlaceholder] = useState(PLACEHOLDER_MESSAGE);
  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="text-center">Информация за експертизата</h4>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="row py-3">
                    <div className="col-12">
                      <Input
                        type="text"
                        value={evalCriteria.description || ""}
                        placeholder={placeholder}
                        onFocus={() => {
                          setPlaceholder(() => "");
                        }}
                        onBlur={() => {
                          setPlaceholder(() => PLACEHOLDER_MESSAGE);
                        }}
                        onChange={(value) => {
                          setEvalCriteria((old) => {
                            return {
                              ...old,
                              description: String(value),
                            };
                          });
                        }}
                        name="description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvalInformation;
