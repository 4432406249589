import DBStatCard from "../../../DBStatCard/DBStatCard";
import Loader from "../../../common/Loader/Loader";

export type DescriptionProps = {
  isLoading?: boolean;
  showStats?: boolean;
  stats?: any;
  title?: string;
  description?: string | JSX.Element;
};

const Description = ({
  isLoading,
  showStats,
  stats,
  title,
  description,
}: DescriptionProps) => {
  return (
    <div className="order-0 pt-5 pe-5 rounded-1 ">
      <div className="row mb-5 pt-4">
        <div className="col">
          <h1 className="text-center">{title}</h1>
        </div>
      </div>
      <div className="row ">
        <div className="col">
          <p>{description}</p>
        </div>
      </div>

      {showStats && (
        <div className="row">
          <div className="col">
            {isLoading ? (
              <div
                className="row text-center justify-content-around"
                style={{ fontSize: ".9rem" }}
              >
                <Loader />
              </div>
            ) : (
              <div
                className="row text-center justify-content-around"
                style={{ fontSize: ".9rem" }}
              >
                <div className="col-lg-3 col-md-6 my-3">
                  <DBStatCard
                    title="марки"
                    value={stats?.makeCount || "-"}
                    condensed
                  />
                </div>
                <div className="col-lg-3 col-md-6 my-3">
                  <DBStatCard
                    title="модела"
                    value={stats?.modelCount || "-"}
                    condensed
                  />
                </div>
                <div className="col-lg-3 col-md-6 my-3">
                  <DBStatCard
                    title="подмодела"
                    value={stats?.submodelCount || "-"}
                    condensed
                  />
                </div>
                <div className="col-lg-3 col-md-6 my-3">
                  <DBStatCard
                    title="цени"
                    value={stats?.priceCount || "-"}
                    condensed
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Description;
