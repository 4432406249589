import React, { useState } from "react";
import Input from "../../common/Input/Input";

type RegistraionNumberInputProps = {
  onChange: (value: string) => void;
};

const RegistrationNumberInput = ({ onChange }: RegistraionNumberInputProps) => {
  const [regNumber, setRegNumber] = useState("");

  const handleRegNumberInput = (value: string | number) => {
    value = String(value).toUpperCase();
    setRegNumber(String(value));
    onChange(value);
  };

  return (
    // <div className="row">
    <>
      <div className="col-lg-3 col-md-4">
        <div className="row align-items-end">
          <div className="">
            <Input
              label="Регистрационен номер (А)"
              name="reg_number"
              value={regNumber}
              // placeholder="B1234AB"
              onChange={handleRegNumberInput}
            />
          </div>
        </div>
      </div>
    </>
    // </div>
  );
};

export default RegistrationNumberInput;
