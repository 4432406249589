import { useEffect, useState } from "react";
import useAuthContext, { UserEditData } from "../../../context/authentication";

import { Button, Input } from "../../../components";
import Loader from "../../../components/common/Loader/Loader";
import NightModeToggle from "../../../components/page-components/NightModeToggle/NightModeToggle";
import { Alert, ContentContainerWithSidebar } from "../../../components/common";
import { requestVerification } from "../../../api/main";

const Settings = () => {
  const { user, editProfile, errors } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationRequestLoading, setVerificationRequestLoading] =
    useState<boolean>(false);
  const [verificationRequestMessage, setVerificationRequestMessage] = useState<
    string | null
  >(null);
  const [localErrors, setLocalErrors] = useState<{
    [key: string]: string[];
  }>({});
  const [successMessage, setSuccessMessage] = useState<string>();
  const [userData, setUserData] = useState<UserEditData>();
  useEffect(() => {
    setUserData(() => {
      return {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        phone: user?.phone || "",
      };
    });
  }, [user]);


  const handleEditProfile = async () => {
    setSuccessMessage(() => undefined);
    if (!userData) return;
    if (userData.password && userData.password !== userData.confirmPassword) {
      setLocalErrors(() => ({
        ...localErrors,
        password: ["Паролите не съвпадат"],
      }));
      return;
    }
    setIsLoading(() => true);
    const response = await editProfile(userData);
    if (response?.message) {
      setSuccessMessage(() => response?.message);
    }
    setIsLoading(() => false);
  };

  const resetPasswordErrors = () => {
    if (localErrors.password) {
      setLocalErrors((old) => {
        const newLocalErrors = { ...old };
        delete newLocalErrors.password;
        return newLocalErrors;
      });
    }
  };

  const handleRequestVerification = async () => {
    setVerificationRequestLoading(() => true);
    try {
      const response = await requestVerification();
      setVerificationRequestMessage(() => response.success.verification);
    } catch (e: any) {
      setVerificationRequestMessage(() => e.response.data.errors.verification);
    }
    setVerificationRequestLoading(() => false);
  };

  return (
    <ContentContainerWithSidebar>
      {!user?.email_verified_at ? (
        <div className="row">
          <div className="col-12">
            <Alert type="warning">
              <p>
                Вашият акаунт все още не е потвърден. Моля, потвърдете Вашия
                акаунт, за да получите достъп до нашите услуги. Ако не сте
                получили мейл за потвърждение, можете да заявите нов.
              </p>
              <div className="row">
                <div className="col-md-4">
                  {verificationRequestLoading ? (
                    <div className="my-3">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {verificationRequestMessage ? (
                        verificationRequestMessage
                      ) : (
                        <Button
                          type="primary"
                          onClick={handleRequestVerification}
                        >
                          Заяви мейл за потвърждение
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Alert>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">Настройки</h3>
              <div className="row my-3">
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <NightModeToggle />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6 col-md-12 ">
                  <div className="form-group">
                    <Input
                      label="Имейл"
                      placeholder="Имейл"
                      type="email"
                      disabled
                      noReset
                      value={user?.email || ""}
                      id="profile-email"
                      name="profile-email"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 ">
                  <div className="form-group">
                    <Input
                      label="Телефон"
                      type="text"
                      value={userData?.phone || ""}
                      id="phone"
                      name="phone"
                      onChange={(phone) => {
                        setUserData((old) => {
                          return { ...old, phone } as UserEditData;
                        });
                      }}
                    />
                  </div>
                  {errors?.phone && (
                    <div className="col-12 mt-2">
                      <p className="alert alert-danger mb-0">
                        {errors.phone?.join(", ")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Input
                      label="Име"
                      placeholder="Име"
                      type="text"
                      value={userData?.firstName || ""}
                      id="firstName"
                      name="firstName"
                      onChange={(firstName) => {
                        setUserData((old) => {
                          return { ...old, firstName } as UserEditData;
                        });
                      }}
                    />
                  </div>
                  {errors?.first_name && (
                    <div className="col-12 mt-2">
                      <p className="alert alert-danger mb-0">
                        {errors.first_name?.join(", ")}
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Input
                          label="Фамилия"
                          placeholder="Фамилия"
                          type="text"
                          value={userData?.lastName || ""}
                          id="lastName"
                          name="lastName"
                          onChange={(lastName) => {
                            setUserData((old) => {
                              return { ...old, lastName } as UserEditData;
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {errors?.last_name && (
                      <div className="col-12 mt-2">
                        <p className="alert alert-danger mb-0">
                          {errors.last_name?.join(", ")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Input
                      label="Парола"
                      placeholder="Парола"
                      type="password"
                      id="password"
                      name="password"
                      value={userData?.password || ""}
                      onChange={(password) => {
                        resetPasswordErrors();
                        setUserData((old) => {
                          return { ...old, password } as UserEditData;
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Input
                      label="Потвърдете паролата"
                      placeholder="Потвърдете паролата"
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={userData?.confirmPassword || ""}
                      onChange={(confirmPassword) => {
                        resetPasswordErrors();
                        setUserData((old) => {
                          return {
                            ...old,
                            confirmPassword,
                          } as UserEditData;
                        });
                      }}
                    />
                  </div>
                </div>
                {localErrors.password && (
                  <div className="col-12 mt-2">
                    <p className="alert alert-danger mb-0">
                      {localErrors.password?.join(", ")}
                    </p>
                  </div>
                )}
                {errors?.password && (
                  <div className="col-12 mt-2">
                    <p className="alert alert-danger mb-0">
                      {errors.password?.join(", ")}
                    </p>
                  </div>
                )}
              </div>
              {successMessage && (
                <div className="row">
                  <div className="col-12">
                    <p className="alert alert-success mb-0">{successMessage}</p>
                  </div>
                </div>
              )}
              <div className="row justify-content-end align-items-center">
                {isLoading && (
                  <div className="col-1 ">
                    <Loader />
                  </div>
                )}
                <div className="col-auto mt-3">
                  <Button
                    disabled={isLoading}
                    onClick={handleEditProfile}
                    type="primary"
                    content="Запази"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default Settings;
