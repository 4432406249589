import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  buildSelectOptions,
  findClosestOption,
  findClosestRange,
  findExactOption,
  findSimilarOption,
} from "./utils/main";
import { getFilterOptions } from "../../../api/main";
// import { Select as AEISelect } from "../../common/Select";
import styles from "./DynamicSelect.module.css";
import { getBodyTypeIcon } from "../../../utils/helpers";
/**
 * Checks if the select should be disabled
 *
 * @param options
 * @param grouped
 * @returns boolean
 */
const isSingleOptionView = (options: any, grouped = false): boolean => {
  try {
    if (grouped && options.length === 1) {
      return options[0].options.length === 1;
    }
    return options.length === 1;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const findSelectedOption = (
  selectedOption: string,
  options: any
): null | { value: string | number; label: string; selected?: boolean } => {
  let result = null;
  for (let i = 0; i < options.length; i++) {
    if (String(options[i].value) === String(selectedOption)) {
      result = options[i];
      break;
    }
    if (options[i].hasOwnProperty("options")) {
      result = findSelectedOption(selectedOption, options[i].options);
      if (result) {
        break;
      }
    }
  }

  return result;
};
const { Option, MultiValueLabel } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    {props.data.icon && (
      <img
        src={props.data.icon}
        style={{ width: 36, marginRight: "10px" }}
        alt={props.data.label}
      />
    )}
    {props.data.label}
  </Option>
);

const MultiValueLabelWithIcon = (props: any) => (
  <MultiValueLabel {...props}>
    {props.data.icon && (
      <img
        src={props.data.icon}
        style={{ width: 24, marginRight: "10px" }}
        alt={props.data.label}
      />
    )}
    {props.data.label}
  </MultiValueLabel>
);

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    maxHeight: "39px", // Set the fixed height
    overflow: "hidden", // Hide overflow
    display: "flex", // Use flexbox
    flexWrap: "nowrap", // Prevent wrapping
    flexDirection: "row",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: "39px", // Set the fixed height
    overflowX: "hidden", // Enable horizontal scrolling
    overflowY: "hidden", // Hide vertical overflow
    whiteSpace: "nowrap", // Prevent text wrapping
    flexWrap: "nowrap",
  }),
  option: (provided: any, state: any) => {
    return ({
      ...provided,
      backgroundColor: state.isSelected ? "#010125" : state.isFocused ? "#f1f4f8" : "white",
      color: state.isSelected ? "white" : "#333",
    });
  },
  multiValue: (base: any) => ({
    ...base,
    flex: "none",
    background: "#fff",
    border: `1px solid #ccc`,
    borderRadius: `5px`,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: "1201",
  }),

};

/**
 * Returns the placeholder text
 *
 * @param isLastField
 * @param isActiveField
 * @param withDefaultAll
 * @returns string
 */
const getPlaceholderText = (
  isLastField?: boolean,
  isActiveField?: boolean,
  withDefaultAll?: boolean
) => {
  if (isLastField || withDefaultAll) {
    return !isActiveField ? "Всички" : "";
  }
  return "Избери";
};

const fromSimilarityLabels = (
  name: string,
  label: string,
  options: { label: string; value: string }[],
  alternatives?: Array<string>
): string => {
  if (label === null || label === undefined) return "";
  if (
    [
      "engine_capacity",
      "engine_power_kw",
      "engine_power_hp",
      "engine_power",
    ].includes(name)
  ) {
    return findClosestRange(label, options);
  }
  if (name === "doors") {
    const val = options.find((option: any) =>
      option.label.includes(label)
    )?.value;
    return val || "";
  }
  if (name === "year") {
    return findExactOption(label, options);
  }
  let result = findSimilarOption(label, options);
  if (!result && alternatives) {
    for (let i = 0; i < alternatives.length; i++) {
      result = findSimilarOption(alternatives[i], options);
      if (result) {
        break;
      }
    }
  }

  return result || "";
};

const DynamicSelect = ({
  field,
  product,
  inputValue,
  isMultiple,
  options,
  onChange,
  onError,
  withVinMatcher,
  onLabelChange,
  withDefaultAll,
  withoutLastFieldAdditionalOption,
  withSimilarityLabels,
  criterion,
  isLastField,
  ...props
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);

  const [filteredOptions, setFilteredOptions] = useState(options || []);
  const [rawOptions, setRawOptions] = useState(options || []);
  const fetchOptions = async (field: any) => {
    setIsLoading(true);
    try {
      const response = await getFilterOptions(product, field.name, criterion);
      if ("error" in response) {
        onError(response.error.message);
        setIsLoading(false);
        return;
      }
      setRawOptions(() => response);

      setFilteredOptions(() => {
        const options = buildSelectOptions(response, field.sort, field.grouped);
        const hasFilterAll = options.findIndex(
          (option: any) => option.value === "-1" || option.value === -1
        );

        if (
          isLastField &&
          (!withoutLastFieldAdditionalOption ||
            (options.length > 1 && hasFilterAll === -1))
        ) {
          options.unshift({ label: "Всички", value: -1, selected: false });
        }

        // If the product is market-price and the value is empty, add the "Всички" option
        if (product === "market-price" && withVinMatcher) {
          const val = getValueFromDecodedVinFilter();
          if (
            (val === "" || val === undefined || val === null) &&
            options.length === 1 &&
            hasFilterAll === -1
          ) {
            options.unshift({ label: "Всички", value: -1, selected: false });
          }
        }
        // Body type filter should have icons for each option
        if (field.name === "body_type") {
          options.forEach((element: any) => {
            element.icon = getBodyTypeIcon(element.label);
          });
        }

        const finalOptions = options.map((option: any) => {
          if (option.value === "-1" || option.value === -1) {
            option.selected = true;
          }
          return option;
        });

        return finalOptions;
      });
    } catch (error) {
      onError(
        "Възникна грешка при изискването на филтри, моля опитайте отново по-късно. Ако продължите да виждате това съобщение, моля свържете се с нас."
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  };

  const handleChange = (
    selectedOption: string,
    manualChange: boolean = false
  ) => {
    const idx = rawOptions.findIndex(
      (option: any) => String(option.id) === String(selectedOption)
    );
    if (idx === -1 && String(selectedOption) !== "-1") {
      return;
    }
    const selectedOptionData = findSelectedOption(
      selectedOption,
      filteredOptions
    );
    setValue(() => selectedOptionData);

    onChange(
      field.name,
      String(selectedOption),
      manualChange,
      rawOptions[idx]?.value || ""
    );
  };

  const handleMultiSelectChange = (
    selectedValue: Array<any>,
    manualChange: boolean = false
  ) => {
    console.log()
    selectedValue = selectedValue.map((option: any) => {
      return findSelectedOption(option.value, filteredOptions);
    }).filter((option: any) => option);
    setValue(() => selectedValue);
    if(selectedValue.length === 0) {
      return;
    }
    onChange(
      field.name,
      selectedValue.map((option: any) => option.value),
      manualChange
    );
  };

  const handlePreSelectedOption = () => {
    try {
      const selectedItem = filteredOptions.findIndex(
        (option: any) => option.selected
      );
      if (selectedItem !== -1 && field.active) {
        handleChange(filteredOptions[selectedItem].value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getValueFromDecodedVinFilter = () => {
    const { decodedVinFilter } = field;
    if (!decodedVinFilter) return "";

    const { name, value, label, alternatives, values } = decodedVinFilter;

    if (withSimilarityLabels) {
      return fromSimilarityLabels(name, label, filteredOptions, alternatives);
    }
    if (
      [
        "engine_capacity",
        "engine_power_kw",
        "engine_power_hp",
        "engine_power",
      ].includes(name)
    ) {
      return findClosestOption(name, value, filteredOptions);
    }

    return value || values;
  };

  useEffect(() => {
    const { decodedVinFilter } = field;
    if (decodedVinFilter && filteredOptions.length > 0) {
      const val = getValueFromDecodedVinFilter();
      // console.log(val, field.name);
      if (val === "" || val === undefined || val === null) {
        handlePreSelectedOption();
      } else {
        Array.isArray(val) ? handleMultiSelectChange(val) : handleChange(val);
      }
    } else if (value === "" || value === undefined || value === null) {
      handlePreSelectedOption();
    }
  }, [field.decodedVinFilter, filteredOptions]);

  useEffect(() => {
    if (filteredOptions.length === 1) {
      !filteredOptions[0].hasOwnProperty("options") &&
        handleChange(filteredOptions[0].value);
      filteredOptions[0].hasOwnProperty("options") &&
        filteredOptions[0].options.length === 1 &&
        handleChange(filteredOptions[0].options[0].value);
    }
  }, [filteredOptions]);

  useEffect(() => {
    const foundCriterion = String(
      Object.keys(criterion).findIndex((key) => field.name === key)
    );
    if (foundCriterion === "-1") {
      setValue(() => null);
      setFilteredOptions(() => []);
      field.active && fetchOptions(field);
    }
  }, [criterion]);

  useEffect(() => {
    onLabelChange && rawOptions.length > 0 && onLabelChange(field.name, value);
  }, [value, rawOptions]);

  return (
    <div className={`col-md-6 col-xl-${field.width} mb-3`}>
      <div className={styles["SelectLabel"]}>{field.label}</div>
      <Select
        {...props}
        loadingMessage={() => "Зареждане..."}
        noOptionsMessage={() => "Няма намерени резултати"}
        options={filteredOptions}
        isLoading={isLoading}
        value={value ? value : null}
        isDisabled={
          !field.active || isSingleOptionView(filteredOptions, field.grouped)
        }
        onChange={(selectedValue) => {
          if (isMultiple) {
            let selectedValues: Array<{
              value: string | number;
              label: string;
              selected?: boolean;
            }> = selectedValue as Array<any>;
            if (selectedValues.length > 1) {
              selectedValues = selectedValues.filter(
                (option) => option.value !== "-1"
              );
            }
            handleMultiSelectChange(selectedValues, value !== null);
          } else {
            // console.log(selectedValue, value);
            handleChange(
              (selectedValue as { value: string; label: string }).value,
              value !== null
            );
          }
        }}
        styles={customStyles}
        placeholder={getPlaceholderText(
          isLastField,
          field.active,
          withDefaultAll
        )}
        isMulti={isMultiple}
        components={{
          Option: IconOption,
          MultiValueLabel: MultiValueLabelWithIcon
          
        }}
      ></Select>
    </div>
  );
};

export default DynamicSelect;
