export type Client = {
    name: string;
    url: string; 
    logo: string;
}

type ClientProps = {
    clients: Array<Client>
}

const VIPClients = ({clients}: ClientProps) => {

    return <div className="full-width-container mt-5 mb-2">
    <div className="container pb-3">
      <div className="row mb-4">
        <div className="col text-center">
          <h2>VIP клиенти</h2>
        </div>
      </div>
      <div className="row text-center justify-content-around">
        <div
            id="partner-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
            >
            <div className="carousel-inner">
                <div className="carousel-item active">
                <div className="d-flex flex-wrap justify-content-around align-items-center">
                    {clients.map((client: Client, index: any) => {
                        return <span key={index}> 
                            <a
                                href={client.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className=""
                                    width={200}
                                    src={client.logo}
                                    alt={client.name + " logo"}
                                />
                            </a>
                        </span>
                    })}
                </div>
                </div>
            </div>
            {clients.length > 5 && <> 
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#partner-carousel"
                    data-bs-slide="prev"
                >
                    <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#partner-carousel"
                    data-bs-slide="next"
                >
                    <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </>
            }
        </div>

      </div>
    </div>
  </div>
}

export default VIPClients;