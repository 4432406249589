type SteeringWheelPositionТ = {
  items: {
    text: string;
    penalty: number;
    selected: boolean;
  }[];
  onChange: (selectedSidePenalty: number) => void;
};

const SteeringWheelPosition = ({ items, onChange }: SteeringWheelPositionТ) => {
  const handleSelectedSteeringSide = (e: any) => {
    const type = e.target.value;
    onChange(Number(type));
  };

  return (
    <div className="col-auto ">
      <label className="mb-1">Корекция за позиция на волана</label>
      <div className="row pb-2 px-2">
        {items.map((item, index) => {
          return (
            <div className="form-check me-3 col-xl-auto" key={index}>
              <input
                className="form-check-input"
                type="radio"
                value={item.penalty}
                checked={item.selected}
                onChange={handleSelectedSteeringSide}
                id={item.text}
              />
              <label className="form-check-label" htmlFor={item.text}>
                {item.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SteeringWheelPosition;
