// import preview from "../../../resources/images/laptop-new-preview.png";
import previewVideo from "../../../resources/videos/aei-new-promo.webm";
import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";

const PRODUCT_NAME = "new";
const descriptionData = {
  title:
    "Специализирано онлайн приложение за определяне на цена в ново състояние на МПС",
  description:
    "Приложението предоставя информация за цените  на нови МПС предлагани от официалните вносители в България от 1995 година до момента.",
  showStats: true,
};

const AutoExpertNewLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "Цени на нови МПС в България",
        description: (
          <>
            <p>
              Само тук Вие ще намерите 40+ технически данни, снимки и цени на
              МПС. Информацията е от 01.01.1995 г. до момента и е за МПС,
              произвеждани или внасяни в България от официални вносители.
            </p>
            <p>
              Цени на нови МПС в България е единствения източник на информация,
              посочен в{" "}
              <a href="https://www.nbbaz.bg/files/mf/5a25cb329f9b022072f31f3c99fe6c0b_Regulation_49.pdf">
                Наредба 49 на КФН към МФ
              </a>
              . За повече информация посетете Национално бюро на българските
              автомобилни застрахователи{" "}
              <a href="https://www.nbbaz.bg/files/mf/5a25cb329f9b022072f31f3c99fe6c0b_Regulation_49.pdf">
                тук
              </a>
              .
            </p>
            <p>
              Продуктът е предназначен за търговци на автомобили, авто експерти,
              застрахователи, лизингови компании, банки, данъчни служби, митници
              и др.
            </p>
          </>
        ),
        // image: preview,
        video: previewVideo,
        loginUrl: "/login?redirect=aei-new",
      }}
      description={descriptionData}
      // prices={prices}
    />
  );
};

export default AutoExpertNewLanding;
