import useEvaluationContext from "../../../../context/evaluation";
import { Switch } from "../../../common";

type EndModelCorrectionT = {};

const EndModelCorrection = (props: EndModelCorrectionT) => {
  const { evalCriteria, setEvalCriteria } = useEvaluationContext();
  return (
    <div className="row py-3    ">
      <div className="col-12  ">
        <div className="row">
          <div className="col-12 ">
            <Switch
              checked={evalCriteria.force_production_end || false}
              onChange={() => {
                setEvalCriteria((prev) => {
                  return {
                    ...prev,
                    force_production_end: !prev.force_production_end,
                  };
                });
              }}
            >
              Корекция за край на модела
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndModelCorrection;
