const buildSelectOptions = (
  rawOptions: any,
  sortMode: "asc" | "desc" = "asc",
  grouped: boolean = false
) => {
  rawOptions.sort(sorter(sortMode));
  rawOptions = reorderSpecialOption(rawOptions);

  rawOptions = rawOptions.filter((option: any, index: number) => {
    return rawOptions.findIndex((obj: any) => obj.id === option.id) === index;
  });

  if (grouped) {
    const groups: any = [];
    rawOptions.forEach((option: any) => {
      const optionStruct = { value: option.id, label: option.value };
      const groupIndex = groups.findIndex(
        (group: any) => group.label === optionStruct.label.toString().charAt(0)
      );
      if (groupIndex === -1) {
        groups.push({
          label: optionStruct.label.toString().charAt(0),
          options: [optionStruct],
        });
        return;
      }
      groups[groupIndex].options.push(optionStruct);
    });
    return groups;
  } else {
    return rawOptions.map((option: any) => ({
      value: option.id.toString(),
      label: option.value.toString(),
    }));
  }
};

/**
 * Sorts options by value
 *
 * @param sortMode
 * @returns Function
 */
const sorter = (sortMode: "asc" | "desc") => {
  if (sortMode === "asc") {
    return (a: any, b: any) => {
      const aVal = normalizeValue(a.value);
      const bVal = normalizeValue(b.value);

      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    };
  }
  return (a: any, b: any) => {
    const aVal = normalizeValue(a.value);
    const bVal = normalizeValue(b.value);

    if (aVal > bVal) return -1;
    if (aVal < bVal) return 1;
    return 0;
  };
};

/**
 * Normalizes value to number if possible
 *
 * @param value
 * @returns String|Number
 */
const normalizeValue = (value: any) => {
  try {
    const items = value.split("-");
    if (items.length === 1) {
      return value;
    }

    if (!Number.isSafeInteger(items[0])) {
      return parseInt(value);
    }
  } catch (e) {}

  return value;
};

/**
 * Finds similar option
 *
 * @param selectedOption
 * @param options
 * @returns String
 */
const findSimilarOption = (selectedOption: any, options: any) => {
  let maxSimilarity = 0;
  let similarOption = null;

  const hasNestedOptions = options.some((option: any) =>
    Array.isArray(option?.options)
  );

  const normalizedOptions =
    options.length > 0 && hasNestedOptions
      ? options.map((option: any) => option.options || []).flat()
      : options;

  const normalizedSelectedOption = selectedOption
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[!@#$%^&*()\-_=+\[\]{};:\'\"\\.<>?\/ ]/g, "");

  normalizedOptions.forEach((option: any) => {
    const similarity = jaroWinklerSimilarity(
      option.label
        .toString()
        .toLowerCase()
        .trim()
        .replace(/[!@#$%^&*()\-_=+\[\]{};:\'\"\\.<>?\/ ]/g, ""),
      normalizedSelectedOption
    );
    if (similarity > maxSimilarity) {
      maxSimilarity = similarity;
      similarOption = option;
    }
  });
  if (maxSimilarity < 0.9 || similarOption === null) {
    return null;
  }
  return (similarOption as any).value;
};

/**
 * Finds exact option
 *
 * @param selectedOption
 * @param options
 * @returns String
 */
const findExactOption = (selectedOption: any, options: any) => {
  try {
    const normalizedOptions =
      options.length > 0 && options[0].options
        ? options.map((option: any) => option.options).flat()
        : options;
    const option = normalizedOptions.find((option: any) => {
      return (
        option.label.toString().toLowerCase() ===
        selectedOption.toString().toLowerCase()
      );
    });
    if (option) {
      return option.value;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

/**
 * Finds closest option to selected option
 *
 * @param fieldName
 * @param selectedOption
 * @param options
 * @returns String
 */
const findClosestOption = (
  fieldName: string,
  selectedOption: any,
  options: any
) => {
  try {
    if (fieldName === "engine_capacity") {
      const closestOption = options.find((option: any) => {
        return (
          (option.value as number) === (selectedOption as number) ||
          ((option.value as number) < (selectedOption as number) + 50 &&
            (option.value as number) > (selectedOption as number) - 50)
        );
      });
      if (closestOption) {
        return closestOption.value;
      }
    }
    if (fieldName === "engine_power_kw" || fieldName === "engine_power_hp") {
      const closestOption = options.find((option: any) => {
        return (
          (option.value as number) === (selectedOption as number) ||
          ((option.value as number) < (selectedOption as number) * 1.05 &&
            (option.value as number) > (selectedOption as number) * 0.95)
        );
      });
      if (closestOption) {
        return closestOption.value;
      }
    }
    if (fieldName === "engine_power" && selectedOption) {
      const powerHp = selectedOption.split("/")[0];

      const closestOption = options.find((option: any) => {
        const optionPowerHp = option.value.split("/")[0];
        return (
          (optionPowerHp as number) === (powerHp as number) ||
          ((optionPowerHp as number) < (powerHp as number) * 1.05 &&
            (optionPowerHp as number) > (powerHp as number) * 0.95)
        );
      });
      if (closestOption) {
        return closestOption.value;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return "";
};

/**
 * Finds closest option to selected option
 *
 * @param fieldName
 * @param selectedOption
 * @param options
 * @returns String
 */
const findClosestRange = (selectedOption: any, options: any) => {
  try {
    selectedOption = parseInt(selectedOption);
    const closestOption = options.find((option: any) => {
      const minMaxRange = option.label.split("-");
      if (minMaxRange.length === 1) {
        return (
          (selectedOption as number) === (parseInt(minMaxRange[0]) as number)
        );
      }

      if (minMaxRange.length !== 2) {
        return false;
      }
      let [min, max] = minMaxRange;
      min = parseInt(min);
      max = parseInt(max);
      return (
        (min as number) <= (selectedOption as number) &&
        (max as number) >= (selectedOption as number)
      );
    });
    if (closestOption) {
      return closestOption.value;
    }
  } catch (e) {
    console.log(e);
  }
  return "";
};

/**
 * Moves special option to the top of the list
 *
 * @param options
 * @returns Array
 */
function reorderSpecialOption(options: any) {
  const noDataOption = options.find(
    (option: any) => normalizeValue(option.id) === 0
  );
  if (noDataOption) {
    options.splice(options.indexOf(noDataOption), 1);
    options.push(noDataOption);
  }

  const allOption = options.find(
    (option: any) => normalizeValue(option.id) === -1
  );
  if (allOption) {
    options.splice(options.indexOf(allOption), 1);
    options.unshift(allOption);
  }

  return options;
}

function jaroWinklerSimilarity(str1: string, str2: string): number {
  if (str1 === str2) return 1.0;
  const length1 = str1.length;
  const length2 = str2.length;

  let commonChars = 0;
  let transpositions = 0;
  const maxDistance = Math.floor(length1 / 2) - 1;

  // Finding common characters
  for (let i = 0; i < length1; i++) {
    const start = Math.max(0, i - maxDistance);
    const end = Math.min(i + maxDistance + 1, length2);

    for (let j = start; j < end; j++) {
      if (str1[i] === str2[j]) {
        commonChars++;

        if (i !== j) {
          transpositions++;
        }
        break;
      }
    }
  }

  // Calculate Jaro similarity
  if (commonChars === 0) {
    return 0.0;
  }

  const jaroSimilarity =
    (commonChars / length1 +
      commonChars / length2 +
      (commonChars - transpositions) / commonChars) /
    3;

  // Calculate Jaro-Winkler similarity
  let prefixLength = 0;
  const maxPrefixLength = Math.min(4, length1, length2);

  for (let i = 0; i < maxPrefixLength; i++) {
    if (str1[i] === str2[i]) {
      prefixLength++;
    } else {
      break;
    }
  }

  const jaroWinklerSimilarity =
    jaroSimilarity + 0.1 * prefixLength * (1 - jaroSimilarity);

  return jaroWinklerSimilarity;
}
export {
  buildSelectOptions,
  findClosestOption,
  findSimilarOption,
  findClosestRange,
  findExactOption,
};
