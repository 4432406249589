import styles from "./DBStatProgress.module.css";

interface DBStatProgressProps {
  title: string;
  value: number | string;
  percentage?: number;
}

const DBStatProgress = ({ title, value, percentage }: DBStatProgressProps) => {
  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-12">
          <h2 className={`${styles["title"]} fw-bold mb-0`}>
            {value} {title}{" "}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={`progress ${styles["progress"]}`}>
            <div
              className={`progress-bar ${styles["progress-bar"]}`}
              style={{
                width: percentage !== undefined ? `${percentage}%` : "100%",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DBStatProgress;
