import { useEffect, useState } from "react";

import { ProductTypeT } from "../../../api/types";
import useEvaluationContext from "../../../context/evaluation";
import NightModeToggle from "../NightModeToggle/NightModeToggle";
import PriceCorrections from "../PriceCorrections/PriceCorrections";
import { formatNumber, scrollIntoView } from "../../../utils/helpers";
import EvaluationSummary from "../EvaluationSummary/EvaluationSummary";
import AdditionalEvalImagesUpload from "./components/AdditionaEvalImagesUpload";
import MarketPriceEvaluation from "../MarketPriceEvaluation/MarketPriceEvaluation";
import {
  uploadFiles,
  saveEvaluation,
  openPDFGeneration,
} from "../../../api/main";
import {
  Icon,
  Input,
  Loader,
  Button,
  Switch,
  IconLabel,
  Datepicker,
  FixedFooter,
  OverlayLoader,
} from "../../common";
import AdditionalEvalNotes from "./components/AdditionalEvalNotes";
import EvalInformation from "./components/EvalInformation";

const superstructureBodyTypes = [
  "Товарни автомобили",
  "Специални автомобили",
  "Специализирани автомобили",
];

type VehicleEvaluationProps = {
  product: ProductTypeT;
  priceId: string;
  regNumber?: string;
  archive?: number;
  vin?: string;
  editMode?: boolean;
  onBackClick?: (archiveId: number) => void;
};

const VehicleEvaluation = ({
  vin,
  product,
  priceId,
  regNumber,
  onBackClick,
  editMode,
  archive,
}: VehicleEvaluationProps) => {
  const {
    errors,
    evaluate,
    isLoading,
    setProduct,
    initialLoad,
    evalSummary,
    vehicleData,
    evalCriteria,
    marketPrices,
    selectedFiles,
    additionalData,
    setEvalCriteria,
  } = useEvaluationContext();

  useEffect(() => {
    if (product) {
      setProduct(product);
    }
  }, [product, setProduct]);

  const [archiveId, setArchiveId] = useState<number | null>(archive || null);
  const [printingPDF, setPrintingPDF] = useState<boolean>(false);

  const [withMarketPriceEvaluation, setWithMarketPriceEvaluation] =
    useState<boolean>(false);

  const saveEvaluationData = async () => {
    const payload = {
      eval_criteria: evalCriteria,
      eval_summary: evalSummary,
    };
    const { archive } = await saveEvaluation(product, payload);
    setArchiveId(() => archive.id);
  };

  useEffect(() => {
    // fetchvehicleData();
    setEvalCriteria((prev) => ({
      ...prev,
      price_id: Number(priceId),
      registration_number: regNumber,
      vin: vin,
    }));
    setTimeout(() => {
      scrollIntoView("#eval_heading");
    }, 100);
  }, []);

  useEffect(() => {
    if (evalSummary !== null && archiveId === null) saveEvaluationData();
  }, [evalSummary, archiveId]);

  /**
   * @description This function is called when the user clicks the "Печат" button
   */
  const printPdf = async () => {
    setPrintingPDF(true);
    try {
      const evaluation = await evaluate();
      if (!evaluation) {
        throw new Error("No evaluation data");
      }
      let uploadFilesIds: number[] = [];
      if (selectedFiles) {
        const uploadResponse = await uploadFiles(selectedFiles);
        uploadFilesIds = [...uploadResponse.uploaded_files_ids];
      }

      const payload = {
        eval_criteria: evalCriteria,
        eval_summary: evaluation,
        additional_data: {
          ...additionalData,
          market_prices: marketPrices,
          image_ids: uploadFilesIds,
        },
      };

      await saveEvaluation(product, payload, archiveId as number);

      openPDFGeneration(product, {
        eval_id: archiveId,
      });
    } catch {
      if (errors && Object.keys(errors || {}).length > 0)
        scrollIntoView("#eval_heading");
      console.error("Error while generating PDF");
    }
    setPrintingPDF(false);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="container" style={{ paddingBottom: "250px" }}>
      {printingPDF && (
        <OverlayLoader message="Моля изчакайте докато генерираме вашият PDF документ." />
      )}
      <FixedFooter>
        <div className="row mt-3">
          <div className="col-xl-3 col-md-6 mb-2 ">
            <Button
              type="primary"
              onClick={() => {
                onBackClick && onBackClick(archiveId as number);
              }}
            >
              <IconLabel name="arrow-left" content="Назад" />
            </Button>
          </div>
          <div className="col-xl-3 col-md-6 mb-2">
            <Button
              type="primary"
              onClick={() => {
                evaluate().then((evaluation: any) => {
                  if (evaluation) scrollIntoView("#eval_summary");
                });
              }}
            >
              <IconLabel name="calculator" content="Преизчисли" />
            </Button>
          </div>
          <div className="col-xl-3 col-md-6 mb-2">
            <Button
              type="primary"
              onClick={printPdf}
              disabled={archiveId == null}
            >
              <span className="d-flex justify-content-center">
                <span className="me-4">
                  <IconLabel name="printer" content="Печат" />{" "}
                </span>
                {archiveId != null || <Loader size="small" />}
              </span>
            </Button>
          </div>
          <div className="col-xl-3 col-md-6 mb-2">
            <Button
              type="primary"
              onClick={() => {
                initialLoad();
              }}
            >
              <IconLabel
                name="arrow-clockwise"
                content="Ресетване на данните"
              />
            </Button>
          </div>
        </div>
      </FixedFooter>
      <div className="row pb-5" id="eval_heading">
        <div className="col">
          <div className="row justify-content-end">
            <div className={"col-auto"}>
              <NightModeToggle />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="d-flex align-items-center justify-content-center">
                <span>Определяне действителна стойност</span>
              </h2>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="card card-themed">
                <div className="card-body card-body-margin-reset">
                  <h4 className="text-center">
                    Превозно средство:{" "}
                    {[
                      vehicleData?.make,
                      vehicleData?.model,
                      vehicleData?.submodel,
                    ].join(" ")}
                  </h4>
                  {regNumber && (
                    <h5 className="text-center">
                      Регистрационен номер: {regNumber}
                    </h5>
                  )}
                  {vin && <h5 className="text-center">VIN: {vin}</h5>}

                  <h5 className="text-center">
                    Изходни данни{" "}
                    <p className="card-text text-alert">
                      (Полетата в червено са задължителни)
                    </p>
                  </h5>

                  <div className="row justify-content-center">
                    {/* <div className="col-auto ">
                      <Switch
                        checked={withMarketPriceEvaluation}
                        onChange={() => {
                          setWithMarketPriceEvaluation((prev) => !prev);
                        }}
                      >
                        Пазарна цена
                      </Switch>
                    </div> */}
                    <div className="col-auto ">
                      <Switch
                        checked={evalCriteria.system_evaluation || false}
                        onChange={() => {
                          setEvalCriteria((prev) => {
                            return {
                              ...prev,
                              system_evaluation: !(
                                prev.system_evaluation || false
                              ),
                            };
                          });
                        }}
                      >
                        Метод на AEI
                      </Switch>
                    </div>
                    <div className="col-auto ">
                      <Switch
                        checked={evalCriteria.reg49_evaluation as boolean}
                        onChange={() => {
                          setEvalCriteria((prev) => {
                            return {
                              ...prev,
                              reg49_evaluation: !(
                                prev.reg49_evaluation || false
                              ),
                            };
                          });
                        }}
                      >
                        Метод на Наредба 49
                      </Switch>
                    </div>
                  </div>

                  {(evalCriteria.reg49_evaluation ||
                    evalCriteria.system_evaluation) && (
                    <div className="row">
                      <div className="col-12 stripped-rows">
                        <div className="row py-3 text-alert">
                          <div className="col-xl-3 col-md-4">
                            <Input
                              type="number"
                              value={
                                evalCriteria?.starting_price !== undefined
                                  ? evalCriteria.starting_price
                                  : vehicleData?.prices[0].price || 0
                              }
                              onChange={(value) =>
                                setEvalCriteria((prev) => {
                                  return {
                                    ...prev,
                                    starting_price: Number(value),
                                  };
                                })
                              }
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              name="price"
                              label="Цена в ново (BGN)"
                            />
                          </div>
                          <div className="col-xl-4 col-md-6 d-flex align-items-end">
                            <div className="row mt-4">
                              <div className="col-auto">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setEvalCriteria((prev) => {
                                      return {
                                        ...prev,
                                        starting_price: Number(
                                          vehicleData?.prices[0].price || 0
                                        ),
                                      };
                                    });
                                  }}
                                >
                                  <Icon name="arrow-left" sz={6} />
                                </Button>
                              </div>
                              <div className="col-auto">
                                <p
                                  className="m-0"
                                  style={{ fontSize: "1.3em" }}
                                >
                                  АЕ цена:{" "}
                                  {formatNumber(
                                    vehicleData?.prices[0].price || 0
                                  )}{" "}
                                  BGN
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {vehicleData?.prices[0].price &&
                        evalCriteria.starting_price &&
                        vehicleData?.prices[0].price !==
                          evalCriteria.starting_price ? (
                          <div className="row  py-3">
                            <div className="col">
                              <p className="mt-3 mb-0  text-alert">
                                Внимание! Въведената цена не е от базата данни
                                на АЕИ!
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="row  py-3 text-alert">
                          <div className="col-xl-3 col-md-4 ">
                            <Datepicker
                              label="Дата на първа регистрация"
                              name="date_1"
                              onChange={(date) =>
                                setEvalCriteria((prev) => {
                                  return {
                                    ...prev,
                                    first_registration_date: date,
                                  };
                                })
                              }
                              date={
                                evalCriteria.first_registration_date ||
                                new Date()
                              }
                            />
                            <p className="text-muted mb-0 mt-1">
                              Формат: дд.мм.гггг
                            </p>
                          </div>
                          {errors?.first_registration_date && (
                            <p className=" col-12 alert alert-danger text-alert mb-0 mx-2">
                              {errors.first_registration_date.map(
                                (error, idx) => {
                                  return (
                                    <span
                                      key={idx}
                                      className="d-flex align-items-center"
                                    >
                                      <Icon
                                        name="exclamation-triangle"
                                        sz={4}
                                      />{" "}
                                      <span className="ms-2">{error}</span>
                                    </span>
                                  );
                                }
                              )}
                            </p>
                          )}
                        </div>

                        <div className="row  py-3 text-alert">
                          <div className="col-xl-3 col-md-4 ">
                            <Datepicker
                              label="Дата на оценка"
                              name="date_2"
                              date={evalCriteria.evaluation_data || new Date()}
                              onChange={(date) => {
                                setEvalCriteria((prev) => {
                                  return {
                                    ...prev,
                                    evaluation_data: date,
                                  };
                                });
                              }}
                            />
                            <p className="text-muted mb-0 mt-1">
                              Формат: дд.мм.гггг
                            </p>
                          </div>
                        </div>
                        {/* here only for debuggin, wanted from Antony */}
                        {/* {evalSummary?.reg49_type_formatted && (
                          <>
                            <div className="row  py-3">
                              <div className="col-12">
                                <p className="mb-0">Тип на ПС по Наредба 49:</p>
                                <p className="mb-0 mt-1 ms-3">
                                  {evalSummary.reg49_type_formatted}
                                </p>
                              </div>
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ['Товарни автомобили', 'Специални автомобили', 'Специализирани автомобили'] */}
          {(evalCriteria.reg49_evaluation ||
            evalCriteria.system_evaluation) && (
            <>
              <EvalInformation />

              <PriceCorrections
                withSuperstructure={superstructureBodyTypes.includes(
                  vehicleData?.vehicleType.name || ""
                )}
                withAutomaticTransmission={!(vehicleData?.autoTrans || false)}
              />
              <AdditionalEvalImagesUpload />
              <AdditionalEvalNotes />
            </>
          )}
          {withMarketPriceEvaluation && (
            <MarketPriceEvaluation
              priceId={priceId}
              product={product}
              vin={vin}
            />
          )}

          {/* EVALUATION */}
          <div id="eval_summary">
            {evalSummary && <EvaluationSummary evaluationData={evalSummary} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleEvaluation;
