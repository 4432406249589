import HeadingSlide from "../HeadingSlide/HeadingSlide";
import slide1 from "../../../../../resources/images/welcome-carusel-baner.png";
import "./HeadingSlider.css";

const HeadingSlider = () => {
  return (
    <div className="row aei-carousel-full-width ">
      <div
        id="heading-carousel"
        className="carousel slide aei-carousel"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner" style={{ height: "100%" }}>
          <div className="carousel-item active" style={{ height: "100%" }}>
            <HeadingSlide
              image={`url(${slide1})`}
              title="ИСТИНАТА ЗА АВТОМОБИЛА"
              content={
                "Само тук можете да намерите точната действителна стойност на автомобилите в България. Още по-хубавото е, че използвате най-високо технологичен софтуер разработен от професионални авто експерти."
              }
              // linkName="Научете повече"
              // linkPath={"/about-us"}
            />
          </div>
          {/* <div className="carousel-item">
            <HeadingSlide
              title="AutoExpert Market Price"
              content={
                "Специализирано онлайн приложение за определяне на цена на употребявани МПС произведени след 2000 година, предлагани на пазара в България и Европа."
              }
              linkName="Научете повече"
              linkPath={user ? "/aei-market-price" : "/info/aei-market-price"}
              />
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#heading-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#heading-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HeadingSlider;
