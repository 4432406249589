import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";
import preview from "../../../resources/images/laptop-vin-preview.png"; // TODO Change

const descriptionData = {
  title: "Специализирано онлайн приложение Технически данни.",
  description:
    "Приложението предоставя информация закодирана от производителя във VIN съгласно ISO 3779/1979.",
};

const PRODUCT_NAME = "tech-data";

const AutoExpertTechDataLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "AutoExpert TechData",
        description: "Специализирано онлайн приложение Технически данни.",
        image: preview,
        loginUrl: "/login?redirect=aei-tech-data",
      }}
      description={descriptionData}
    />
  );
};

export default AutoExpertTechDataLanding;
