import { useCallback, useEffect, useState } from "react";
import useAuthContext from "../../../context/authentication";

import { Button } from "../../../components";
import Loader from "../../../components/common/Loader/Loader";
import { Alert, ContentContainerWithSidebar } from "../../../components/common";
import { getProductEdit, saveProductChanges } from "../../../api/main";

import DraggableList from "../../../components/page-components/DragableList/DragableList";
import { Navigate, useParams } from "react-router-dom";
import RangeModule from "./components/RangeModule";
import { scrollIntoView } from "../../../utils/helpers";

export type ConfigurableModule = {
  name: string;
  display_name: string;
  type: string;
  parameters: any;
};
export interface Item {
  id: string;
  content: string;
}

const ApplicationEdit = () => {
  const { nightMode, company, user } = useAuthContext();

  const { applicationName } = useParams();
  const [frozenFilters, setFrozenFilters] = useState<Item[]>([]);
  const [currentFilters, setCurrentFilters] = useState<Item[]>([]);
  const [availableFilters, setAvailableFilters] = useState<Item[]>([]);
  const [applicationInfo, setApplicationInfo] = useState<any>({});
  const [configurableModules, setConfigurableModules] =
    useState<ConfigurableModule[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [saveError, setSaveError] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const fetchProductEditData = useCallback(async () => {
    setIsLoading(() => true);
    if (applicationName) {
      try {
        const response = await getProductEdit(applicationName);
        setApplicationInfo(() => {
          return {
            id: response.id,
            name: response.name,
            displayName: response.display_name,
          };
        });

        setFrozenFilters(() => {
          return response.fields
            .filter((field: any) => field.frozen)
            .map((field: any) => {
              return { id: String(field.value), content: field.label };
            });
        });

        setCurrentFilters(() => {
          return response.fields
            .filter((field: any) => field.frozen === false)
            .map((field: any) => {
              return { id: String(field.value), content: field.label };
            });
        });
        setAvailableFilters(() => {
          return response.available_fields.map((field: any) => {
            // console.log(field);
            return { id: String(field.value), content: field.label };
          });
        });
        setConfigurableModules(() => response.modules);
      } catch (err: any) {
        if (err.response?.data?.error?.message) {
          setError(() => err.response.data.error.message);
        }
      }
    }
    setIsLoading(() => false);
  }, [applicationName]);

  const saveCurrentFilters = async () => {
    setIsSaving(() => true);
    setSaveError(() => "");
    setSuccessMessage(() => "");
    try {
      const fields: any = frozenFilters.map((filter) => filter.id);
      currentFilters.forEach((filter) => {
        fields.push(filter.id);
      });

      const response = await saveProductChanges(applicationName as string, {
        fields: fields,
        modules: configurableModules,
      });
      setSuccessMessage(() => response.message);
    } catch (error) {
      setSaveError(() => (error as any).response.data.error.message);
    }
    setIsSaving(() => false);
    setTimeout(() => {
      scrollIntoView("#page-heading-go-to");
    }, 100);
  };

  useEffect(() => {
    fetchProductEditData();
  }, [fetchProductEditData]);

  if (
    !["company_super_admin", "company_admin"].includes(
      user?.role.name || "user"
    ) &&
    !company?.isIndividual
  )
    return <Navigate to="/" replace={true} />;

  return (
    <ContentContainerWithSidebar>
      <div className="row" id="page-heading-go-to">
        <div className="col-lg-8 col-md-12">
          <div className="card card-themed">
            <div className="card-body">
              {isLoading ? (
                <div className="row mt-5">
                  <div className="col-12">
                    <Loader />
                  </div>
                </div>
              ) : error ? (
                <>
                  <Alert type="danger">{error}</Alert>
                </>
              ) : (
                <>
                  {isSaving ? <Loader /> : null}
                  {successMessage ? (
                    <Alert type="success">{successMessage}</Alert>
                  ) : null}
                  {saveError ? <Alert type="danger">{saveError}</Alert> : null}
                  <div className="row">
                    <div className="col-12">
                      <h3 className="card-title text-center">
                        Конфигурация на <b>{applicationInfo.displayName}</b>
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-primary" role="alert">
                        <p> Задължителни фиксирани филтри:</p>
                        <p className="ms-2 fw-bold">
                          {frozenFilters.map((filter) => {
                            return ` ${filter.content},`;
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DraggableList
                        darkMode={nightMode}
                        leftListTitle={"Текущи филтри"}
                        leftList={currentFilters}
                        rightListTitle={"Налични филтри"}
                        rightList={availableFilters}
                        onDragResult={(left, right) => {
                          setCurrentFilters(left);
                          setAvailableFilters(right);
                        }}
                      ></DraggableList>
                    </div>
                  </div>
                  {configurableModules &&
                    configurableModules.length > 0 &&
                    configurableModules?.map((module) => {
                      if (module.type === "range") {
                        return (
                          <RangeModule
                            key={module.name}
                            module={module}
                            onChange={(min: number, max: number) => {
                              setConfigurableModules((prev) => {
                                if (prev) {
                                  const newModules = [...prev];
                                  return newModules.map((mod) => {
                                    if (mod.name === module.name) {
                                      return {
                                        ...mod,
                                        parameters: { min, max },
                                      };
                                    }
                                    return mod;
                                  });
                                }
                                return prev;
                              });
                            }}
                          />
                        );
                      }

                      return <></>;
                    })}

                  <div className="row justify-content-end align-items-center">
                    <div className="col-auto mt-3">
                      <Button
                        type="primary"
                        onClick={() => {
                          saveCurrentFilters();
                        }}
                      >
                        Запази промените
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default ApplicationEdit;
