import { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "../../components";

import "./Login.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useAuthContext from "../../context/authentication";
import GoogleAuth from "../../components/Socials/GoogleAuth";

const productsMap = (path: string) => {
  const maping: { [key: string]: string } = {
    "aei-vin": "AutoExpert VIN",
    "aei-new": "Цени на нови МПС в България",
    "aei-new-demo": "AutoExpert New Demo",
    "aei-market-price": "AutoExpert Market Price",
    "aei-easy": "AutoExpert Easy",
    "aei-tech-data": "AutoExpert TechData",
  };
  return maping[path] || "продуктите на Ауто Експерт";
};

const Login = () => {
  useEffect(() => {
    document.title = "Вход | AutoExpert International";
  }, []);

  const [isRememberMeChecked, setIsRememberMeCheked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user, socialLogin, login, errors } = useAuthContext();
  if (user) navigate("/");

  const handleLogin = async () => {
    try {
      const redirectTo = searchParams.get("redirect");
      await login(
        { email, password, rememberMe: isRememberMeChecked },
        redirectTo
      );
      setEmail("");
      setPassword("");
    } catch (e) {
      console.log(e);
    }
  };

  const handleSuccessGoogleLogin = async (token: string) => {
    try {
      const redirectTo = searchParams.get("redirect");
      await socialLogin("google", token, redirectTo);
    } catch (e) {
      console.log(e);
    }
  };

  let passwordError = null;
  let mailError = null;
  if (errors) {
    if (errors.email) {
      mailError = <p className="text-danger mb-0  mt-1 fs-5">{errors.email[0]}</p>;
    }
    if (errors.password) {
      passwordError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.password[0]}</p>
      );
    }
  }

  return (
    <div className="container aei-fonts fs-5">
      <div className="row pb-5 justify-content-center">
        <div className="col-lg-8">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row my-5">
                <div className="col">
                  <h2 className="text-center font-weight-bold">
                    Вход в{" "}
                    <b>{productsMap(searchParams.get("redirect") || "")}</b>
                  </h2>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    label="Е-мейл"
                    value={email}
                    onChange={(value) => setEmail(() => value as string)}
                  />
                  {mailError || ""}
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="password"
                    type="password"
                    placeholder="*****"
                    label="Парола"
                    value={password}
                    onChange={(value) => setPassword(() => value as string)}
                  />
                  {passwordError || ""}
                </div>
              </div>

              <div className="row mb-2 justify-content-center">
                <div className="col-xs-12 col-md-6">
                  <Checkbox
                    name="remember-me"
                    checked={isRememberMeChecked}
                    label={"Запомни ме на този компютър"}
                    onChange={() => {
                      setIsRememberMeCheked((old) => !old);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6 order-md-1 order-0">
                  <div className="row justify-content-center mb-4">
                    <div className="col-xs-12">
                      <Button
                        content="Вход"
                        type="primary"
                        className="fs-5"
                        onClick={() => handleLogin()}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center mb-4">
                    <div className="col-xs-12 ">
                      <GoogleAuth onSuccess={handleSuccessGoogleLogin} />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 order-md-0 order-1">
                  <div className="row mb-4">
                    <div className="col-xs-12">
                      <Link
                        to="/forgot-password"
                        className="btn Button Button-primary fs-5"
                      >
                        Забравена парола
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <Link
                        to="/create-account"
                        className="btn Button Button-primary fs-5"
                      >
                        Регистрирайте се
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mb-5">
                <div className="col text-center">
                  <p>
                    Имате проблем със системата?{" "}
                    <a
                      className="aei-primary-link"
                      href="mailto:contact@auto-expert.biz"
                    >
                      Изпратете ни съобщение
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
