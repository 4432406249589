import { useEffect, useState } from "react";
import { ConfigurableModule } from "../ApplicationEdit";
import { Input } from "../../../../components";

const RangeModule = ({
  module,
  onChange,
}: {
  module: ConfigurableModule;
  onChange?: (min: number, max: number) => void;
}) => {
  const [values, setValues] = useState<{ min: any; max: any }>(
    module.parameters
  );
  useEffect(() => {
    onChange && onChange(+values.min, +values.max);
  }, [values]);

  return (
    <div className="row">
      <div className="col-12">
        <h5>{module.display_name}</h5>
      </div>
      <div className="col-md-3 col-6">
        <label htmlFor="">От</label>
        <Input
          type="number"
          name="min"
          value={values.min}
          step={5}
          onChange={(e) => {
            setValues((prev) => ({ ...prev, min: e }));
          }}
        />{" "}
      </div>
      <div className="col-md-3 col-6">
        <label htmlFor="">До</label>
        <Input
          type="number"
          name="max"
          step={5}
          value={values.max}
          onChange={(e) => {
            setValues((prev) => ({ ...prev, max: e }));
          }}
        />
      </div>
    </div>
  );
};

export default RangeModule;
