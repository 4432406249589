import { formatNumber } from "../../../utils/helpers";
import Icon from "../../common/Icon/Icon";
import AEIResidualSummary from "./components/AEIResidualSummary";
import BaseSummary from "./components/BaseSummary";
import Reg49ResidualSummary from "./components/Reg49ResidualSummary";

type EvaluationSummaryProps = {
  evaluationData: any;
};

const EvaluationSummary = ({ evaluationData }: EvaluationSummaryProps) => {
  // console.log(evaluationData);
  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="">Оценка</h4>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">Възраст на автомобила:</div>
                  <div className="col-xs-6 col-md-3 text-end">
                    {evaluationData.age.years}{" "}
                    {evaluationData.age.years === 1 ? "година" : "години"}{" "}
                    {evaluationData.age.months > 0 && (
                      <>
                        и {evaluationData.age.months}{" "}
                        {evaluationData.age.months === 1 ? "месец" : "месеца"}
                      </>
                    )}
                  </div>
                </div>
                {evaluationData.base_values && (
                  <BaseSummary evaluationData={evaluationData.base_values} />
                )}
                {evaluationData.system_residual_values && (
                  <AEIResidualSummary
                    evaluationData={evaluationData.system_residual_values}
                  />
                )}

                {evaluationData.reg49_residual_values && (
                  <Reg49ResidualSummary
                    evaluationData={evaluationData.reg49_residual_values}
                  />
                )}
                {/* Show only if the average value is present */}
                {evaluationData.average_value > 0 && (
                  <>
                    <hr />
                    <div className="row fw-bold">
                      <div className="col-6">Средна стойност:</div>
                      <div className="col-xs-6 col-md-3 text-end">
                        {formatNumber(evaluationData.average_value)} лв.
                      </div>
                    </div>
                  </>
                )}
                <hr />
                <div className="row">
                  <div className="col-6">Препоръчителна продажна цена:</div>
                  <div className="col-xs-6 col-md-3 text-end">
                    {formatNumber(evaluationData.sell_value)} лв.
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">Препоръчителна изкупна цена:</div>
                  <div className="col-xs-6 col-md-3 text-end">
                    {formatNumber(evaluationData.buy_value)} лв.
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 mt-3">
                <h6 className="alert alert-danger text-alert bold d-flex align-items-center">
                  <Icon name="exclamation-triangle" sz={4} />
                  <b className="ms-3">
                    Точните цени на изкупуване и продажба се уточняват след
                    проверка на техническото състояние в авто сервиз и разговор
                    с търговеца.
                  </b>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationSummary;
