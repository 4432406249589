import "./NightModeToggle.css";
import useAuthContext from "../../../context/authentication";
import Switch from "../../common/Switch/Switch";

const NightModeToggle = () => {
  const { nightMode, configureNightMode } = useAuthContext();

  const handleNightModeToggle = (e: any) => {
    configureNightMode(e.target.checked);
  };

  return (
    <Switch
      id="nightModeToggle"
      checked={nightMode}
      onChange={handleNightModeToggle}
    >
      Нощен режим
    </Switch>
  );
};

export default NightModeToggle;
