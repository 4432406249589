import { useRouteError } from "react-router";
import { Footer, Header } from "../../../components";
import LinkButton from "../../../components/common/Button/LinkButton";

const NotFound = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <>
      <Header />
      <div className="pt-5 Theme-dark main-container">
        <main className="container" style={{ minHeight: "450px" }}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <h1 style={{ fontSize: "10em" }}>404</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <p>Страницата, която търсите не може да бъде намерена.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <LinkButton
                type="primary"
                size="lg"
                text={"Към начало"}
                to="/"
                auto
              />
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};
export default NotFound;
