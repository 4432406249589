import { useEffect, useState } from "react";

import { config } from "./tableConfig";
import { Button, Input } from "../../../components";
import {
  getCompanyBranches,
  getCompanyHistory,
  getCompanyOfficesForBranch,
  openCompanyHistoryPDFGeneration,
} from "../../../api/main";
import {
  Loader,
  Datepicker,
  Pagination,
  Select,
} from "../../../components/common";
import { CompanyHistoryEntryT } from "../../../api/types";

const initCriteria = {
  from: new Date(`${new Date().getFullYear() - 1}-01-01`),
  to: new Date(),
};

const History = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<CompanyHistoryEntryT[]>([]);
  const [branches, setBranches] = useState<{ value: string; label: string }[]>([
    {
      value: "",
      label: "Всички",
    },
  ]);
  const [offices, setOffices] = useState<{ value: string; label: string }[]>([
    {
      value: "",
      label: "Всички",
    },
  ]);
  const [searchCriteria, setSearchCriteria] = useState<{
    [key: string]: unknown;
  }>(initCriteria);
  const [pagination, setSetPagination] = useState<{
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  } | null>(null);
  const handleSearchHistory = async () => {
    setIsLoading(() => true);
    fetchHistory();
    setIsLoading(() => false);
  };
  const fetchBranches = async () => {
    try {
      const { data } = await getCompanyBranches();

      setBranches(() => {
        return [
          {
            value: "",
            label: "Всички",
          },
          ...data.map((branch: { id: string; name: string }) => ({
            value: branch.id,
            label: branch.name,
          })),
        ];
      });
    } catch {}
  };
  const fetchOffices = async (branch: string | number | undefined) => {
    if (branch) {
      try {
        const { data } = await getCompanyOfficesForBranch(branch);
        const itemIdx = data.findIndex((office: { id: string }) => {
          return office.id === searchCriteria.office_id;
        });
        if (itemIdx === -1) {
          setSearchCriteria((prevState) => ({
            ...prevState,
            office_id: "",
          }));
        }
        setOffices(() => {
          return [
            {
              value: "",
              label: "Всички",
            },
            ...data.map((office: { id: string; name: string }) => ({
              value: office.id,
              label: office.name,
            })),
          ];
        });
      } catch {}
    }
  };

  const fetchHistory = async (page?: number) => {
    setIsLoading(() => true);

    const response = await getCompanyHistory(searchCriteria, page);
    setHistory(() => []);
    setSetPagination(() => null);
    if (response.data) {
      setHistory(() => response.data);
    }
    if (response.meta) {
      setSetPagination(() => response.meta);
    }

    setIsLoading(() => false);
  };

  useEffect(() => {
    fetchBranches();
    fetchHistory();
  }, []);
  useEffect(() => {
    fetchHistory();
  }, [searchCriteria.user_id]);
  useEffect(() => {
    fetchOffices(searchCriteria.branch_id as string);
  }, [searchCriteria.branch_id]);

  const searchableHandler = (
    value: string | string[],
    key: string | string[]
  ) => {
    if (Array.isArray(value) || Array.isArray(key)) {
      const data: { [key: string]: string } = {};
      (key as string[]).forEach((k, i) => {
        data[k] = value[i];
      });

      setSearchCriteria((prevState) => ({
        ...prevState,
        ...data,
      }));
      return;
    }

    setSearchCriteria((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const rows: any =
    history && history.length > 0 ? (
      history.map((row) => (
        <tr key={row.id}>
          {config.map((col) => (
            <td key={Math.random()}>
              {col.searchable
                ? col.render(row, searchableHandler)
                : col.render(row)}
            </td>
          ))}
          <td>
            {!["internal-vin-decoding"].includes(row.module.name) ? (
              <Button
                type="primary"
                size="sm"
                content="Печат"
                onClick={() => {
                  openCompanyHistoryPDFGeneration(row.id);
                }}
              />
            ) : (
              ""
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={config.length + 1}>
          <div className="d-flex justify-content-center">
            Не бяха намерени резултати.
          </div>
        </td>
      </tr>
    );

  return (
    <div className="content-container with-sidebar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card card-themed">
              <div className="card-body">
                <h3 className="card-title text-center">
                  История на компанията
                </h3>
                <div className="row my-3 align-items-center justify-content-around">
                  <div className="col-xl-4 col-lg-6 mt-3">
                    <Input
                      name="custom_identifier"
                      placeholder="Фирмено ID"
                      label="Фирмено ID"
                      value={
                        (searchCriteria?.custom_identifier as string) || ""
                      }
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          custom_identifier: value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-6 mt-3">
                    <Input
                      name="name"
                      label="Име на потребител"
                      placeholder="Име на потребител"
                      value={(searchCriteria?.user_name as string) || ""}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          user_name: value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-6 mt-3">
                    <Input
                      name="name"
                      label="Имейл"
                      placeholder="Имейл"
                      value={(searchCriteria?.email as string) || ""}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          email: value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row  align-items-center justify-content-around">
                  <div className="col-lg-6 ">
                    <Select
                      label="Клон"
                      id="branch"
                      value={String(searchCriteria.branch_id || "")}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          branch_id: value,
                        }));
                      }}
                      options={branches}
                    />
                  </div>
                  <div className="col-lg-6 ">
                    <Select
                      label="Офис"
                      id="office"
                      value={String(searchCriteria.office_id || "")}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          office_id: value,
                        }));
                      }}
                      options={offices}
                    />
                  </div>
                </div>
                <div className="row my-3 align-items-center justify-content-around">
                  <div className="col-xl-3 col-lg-6 mt-3">
                    <Input
                      name="vin"
                      placeholder="VIN"
                      value={(searchCriteria?.vin as string) || ""}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          vin: value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-6 mt-3">
                    <Input
                      name="reg_number"
                      placeholder="Рег. номер"
                      value={(searchCriteria?.reg_number as string) || ""}
                      onChange={(value) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          reg_number: value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-auto mt-3">
                    <p className="fw-bold m-0">Период</p>
                  </div>
                  <div className="col-sm-4   col-xl-2 col-lg-3 mt-3">
                    <Datepicker
                      date={(searchCriteria?.from as Date) || new Date()}
                      onChange={(date) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          from: date,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-auto mt-3 ">-</div>
                  <div className="col-sm-4   col-xl-2 col-lg-3 mt-3">
                    <Datepicker
                      date={(searchCriteria?.to as Date) || new Date()}
                      onChange={(date) => {
                        setSearchCriteria((prevState) => ({
                          ...prevState,
                          to: date,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-auto mt-3">
                    <Button
                      disabled={isLoading}
                      onClick={handleSearchHistory}
                      size="sm"
                      type="primary"
                      content="Търси"
                    />
                  </div>
                  <div className="col-auto mt-3">
                    <Button
                      onClick={() => setSearchCriteria(() => initCriteria)}
                      size="sm"
                      type="secondary"
                      content="Изчисти филтрите"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    Общ брой записи: {pagination?.total || 0}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-md-responsive">
                      <table className="table table-striped">
                        <thead className="sticky-top Table-header">
                          <tr>
                            {config.map((col) => (
                              <th key={Math.random()} scope="col">
                                {col.title}
                              </th>
                            ))}
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan={config.length + 1}>
                                <div className="d-flex justify-content-center p-5">
                                  <Loader />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            rows
                          )}
                        </tbody>
                        <tfoot className="Table-header">
                          <tr>
                            {config.map((col) => (
                              <th key={Math.random()} scope="col">
                                {col.title}
                              </th>
                            ))}
                            <th scope="col"></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                  centered
                  pagination={pagination}
                  onChange={(page) => fetchHistory(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
