import { ProductT } from "../../../api/types";
import useAuthContext from "../../../context/authentication";

type PricingProps = {
  product: ProductT;
};

const name2text = (name: string) => {
  const mapping: { [key: string]: string } = {
    "vehicle-filters": "Филтриране на автомобили",
    "vin-decoding": "Декодиране на VIN",
    "vehicle-mp": "Пазарна цена",
    evaluation: "Оценка",
  };
  return mapping[name];
};

const Pricing = ({ product }: PricingProps) => {
  const { user } = useAuthContext();
  const pricingTexts = product.modules.map((productModule, index) => {
    let costText = String(productModule.cost);
    if (costText === "0.00") {
      costText = "Цена:  Безплатно";
    } else {
      costText = `Цена:  ${costText} лв.`;
    }

    // Special case for vehicle-filters module
    // If the user has a license for this module, show the license end date
    // If the user is a demo user, show that the module is free
    if (productModule.name === "vehicle-filters") {
      if (product.license?.is_active) {
        costText = `Предплатено до ${product.license.end_date}`;
      } else if (user?.is_demo) {
        costText = "Цена:  Безплатно";
      }
    }

    return (
      <div className="row " key={index}>
        <div className="col-md-7 col-lg-5">
          <div className="row justify-content-between">
            <div className="col-auto">
              <p className="text-muted my-0">
                {name2text(productModule.name)}:
              </p>
            </div>
            <div className="col-auto">
              <span className="text-price">{costText}</span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    // <div className="row">
    //   <div className="col">
    //     <div className="row">
    //       <div className="col">
    //         <h6>Ценова листа:</h6>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col">{pricingTexts}</div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
};

export default Pricing;
