import LinkButton from "../../../../components/common/Button/LinkButton";
import styles from "./HeadingSlide.module.css";

const HeadingSlide = ({ title, content, linkName, linkPath, image }: any) => {
  return (
    <div
      className="col text-center d-flex flex-column justify-content-center align-items-center HeadingSlide-hero image-bg pb-3"
      style={{
        height: "100%",
        backgroundImage: image,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        
      }}
    >
      <div className="row z-index-1">
        <div className="col">
          <h1 className={styles["hero-title"]}>{title}</h1>
        </div>
      </div>
      {content && (
      <div className="row mt-4">
        <div className="col-md-8 offset-md-2">
          <p className={styles['hero-content']}>{content}</p>
        </div>
      </div>)}
      {linkPath && linkName && (
      <div className="row mt-5">
        <div className="col">
          <LinkButton classes={"Button-semi-rounded"} to={linkPath} text={linkName} type="primary" size="lg" />
        </div>
      </div>)}
    </div>
  );
};
export default HeadingSlide;
