type LicensePricingProps = {
  data: { text: string; price: string }[];
};

const LicensePricing = ({ data }: LicensePricingProps) => {
  return (
    <div className="row my-3">
      <div className="col">
        <div className="row">
          <div className="col">
            <h5>
              Цени за 1 потребител и неограничен брой ползвания на системата в
              оказания период
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="table-md-responsive">
              <table className="table">
                <thead className="">
                  <tr>
                    <th scope="col">Пакет</th>
                    <th scope="col">Цена</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.text}</td>
                        <td>{item.price} лв.</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicensePricing;
