import { useEffect, useState } from "react";
import {
  Button,
  ContentContainerWithSidebar,
  FieldErrorMsg,
  Input,
  Loader,
  Select,
  Switch,
} from "../../../components/common";
import useAuthContext, {
  CompanyUserEditData,
} from "../../../context/authentication";
import { useParams } from "react-router-dom";
import {
  getCompanyUser,
  getCompanyRoles,
  getCompanyBranches,
  getCompanyOfficesForBranch,
  createCompanyUser,
  updateCompanyUser,
} from "../../../api/main";
import { role2displayName } from "../../../utils/helpers";

type UserDetailsProps = {
  mode: "edit" | "create";
};

const UserDetails = ({ mode }: UserDetailsProps) => {
  const { userId } = useParams();

  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [userData, setUserData] = useState<CompanyUserEditData | null>(null);
  const [companyUserRoles, setCompanyUserRoles] = useState<
    { value: string; label: string }[]
  >([]);
  const [branches, setBranches] = useState<{ value: string; label: string }[]>(
    []
  );
  const [offices, setOffices] = useState<{ value: string; label: string }[]>(
    []
  );
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});
  const [localErrors, setLocalErrors] = useState<{
    [key: string]: string[];
  }>({});

  const resetPasswordErrors = () => {
    if (localErrors.password) {
      setLocalErrors((old) => {
        const newLocalErrors = { ...old };
        delete newLocalErrors.password;
        return newLocalErrors;
      });
    }
  };

  const fetchUserData = async () => {
    setIsLoading(() => true);
    try {
      const { data } = await getCompanyUser(userId as string);
      setUserData(() => ({
        ...data,
        branch: data.branch,
        office: data.office,
        firstName: data.first_name,
        lastName: data.last_name,
        isActive: data.is_active,
      }));
    } catch {
      setUserData(() => null);
    }
    setIsLoading(() => false);
  };
  const fetchCompanyUserRoles = async () => {
    setIsLoading(() => true);
    try {
      const { data } = await getCompanyRoles();
      setCompanyUserRoles(() => {
        return data.map((role: { id: string; name: string }) => ({
          value: role.id,
          label: role2displayName(role.name),
        }));
      });
    } catch {
      setUserData(() => null);
    }
    setIsLoading(() => false);
  };
  const fetchBranches = async () => {
    try {
      const { data } = await getCompanyBranches();
      setBranches(() => {
        return data.map((branch: { id: string; name: string }) => ({
          value: branch.id,
          label: branch.name,
        }));
      });
    } catch {
      setUserData(() => null);
    }
  };
  const fetchOffices = async (branch: string | number | undefined) => {
    if (branch) {
      try {
        const { data } = await getCompanyOfficesForBranch(branch);
        setOffices(() => {
          return data.map((branch: { id: string; name: string }) => ({
            value: branch.id,
            label: branch.name,
          }));
        });
      } catch {
        setUserData(() => null);
      }
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      fetchUserData();
    }
    fetchBranches();
  }, []);

  useEffect(() => {
    fetchOffices(userData?.branch);
  }, [userData?.branch]);

  useEffect(() => {
    if (
      user?.role.name &&
      ["company_super_admin", "company_admin"].includes(user?.role.name)
    ) {
      fetchCompanyUserRoles();
    }
  }, [user]);

  const handleSave = async () => {
    setIsSaving(() => true);
    setSuccessMessage(() => null);
    setErrors(() => ({}));
    setLocalErrors(() => ({}));

    const payload: any = {
      email: userData?.email,
      phone: userData?.phone,
      first_name: userData?.firstName,
      last_name: userData?.lastName,
      branch_id: userData?.branch,
      office_id: userData?.office,
      role_id: userData?.role?.id,
      password: userData?.password,
    };
    try {
      if (mode === "edit") {
        payload.is_active = userData?.isActive;
        const response = await updateCompanyUser(userId as string, payload);
        setSuccessMessage(() => response.success);
      } else {
        const response = await createCompanyUser(payload);
        setSuccessMessage(() => response.success);
        setUserData(() => null);
      }
    } catch (e: any) {
      if (e.response?.data?.errors) {
        setErrors(() => e.response.data.errors);
      }
    }
    setIsSaving(() => false);
  };

  return (
    <ContentContainerWithSidebar>
      <div className="row">
        <div className="col-8">
          <div className="card card-themed">
            <div className="card-body">
              <h3 className="card-title text-center">
                {mode === "edit"
                  ? "Редакция на потребителн"
                  : "Създаване на потребител"}
              </h3>
              {isLoading ? (
                <div className="row my-5 ">
                  <div className="col">
                    <Loader />
                  </div>
                </div>
              ) : (
                <>
                  {successMessage && (
                    <div className="row">
                      <div className="col-12">
                        <p className="alert alert-success mb-0">
                          {successMessage}
                        </p>
                      </div>
                    </div>
                  )}
                  <FieldErrorMsg errors={errors?.max_active_users || []} />

                  {mode === "edit" && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <Switch
                          id="nightModeToggle"
                          checked={userData?.isActive || false}
                          onChange={(e) => {
                            setUserData((old) => {
                              return {
                                ...old,
                                isActive: e.target.checked,
                              } as CompanyUserEditData;
                            });
                          }}
                        >
                          <span className="me-2">Активен акаунт</span>
                        </Switch>
                      </div>
                    </div>
                  )}
                  <div className="row my-3">
                    <div className="col-lg-6 col-md-12 ">
                      <div className="form-group">
                        <Input
                          label="Имейл"
                          placeholder="Имейл"
                          type="email"
                          disabled={mode === "edit"}
                          noReset={mode === "edit"}
                          value={userData?.email || ""}
                          id="profile-email"
                          name="profile-email"
                          onChange={(email) => {
                            setUserData((old) => {
                              return { ...old, email } as CompanyUserEditData;
                            });
                          }}
                        />
                        <FieldErrorMsg errors={errors?.email || []} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 ">
                      <div className="form-group">
                        <Input
                          label="Телефон"
                          type="text"
                          value={userData?.phone || ""}
                          id="phone"
                          name="phone"
                          onChange={(phone) => {
                            setUserData((old) => {
                              return { ...old, phone } as CompanyUserEditData;
                            });
                          }}
                        />
                      </div>
                      <FieldErrorMsg errors={errors?.phone || []} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Input
                          label="Име"
                          placeholder="Име"
                          type="text"
                          value={userData?.firstName || ""}
                          id="firstName"
                          name="firstName"
                          onChange={(firstName) => {
                            setUserData((old) => {
                              return {
                                ...old,
                                firstName,
                              } as CompanyUserEditData;
                            });
                          }}
                        />
                      </div>
                      <FieldErrorMsg errors={errors?.first_name || []} />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <Input
                              label="Фамилия"
                              placeholder="Фамилия"
                              type="text"
                              value={userData?.lastName || ""}
                              id="lastName"
                              name="lastName"
                              onChange={(lastName) => {
                                setUserData((old) => {
                                  return {
                                    ...old,
                                    lastName,
                                  } as CompanyUserEditData;
                                });
                              }}
                            />
                          </div>
                          <FieldErrorMsg errors={errors?.last_name || []} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          label="Клон"
                          id="branch"
                          placeholder="Изберете клон"
                          value={userData?.branch || ""}
                          onChange={(branchId) => {
                            setUserData((old) => {
                              return {
                                ...old,
                                branch: branchId,
                                office: "",
                              } as CompanyUserEditData;
                            });
                          }}
                          options={branches}
                        />
                        <FieldErrorMsg errors={errors?.branch_id || []} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Select
                          label="Офис"
                          id="office"
                          value={userData?.office || ""}
                          placeholder="Изберете офис"
                          onChange={(officeId) => {
                            setUserData((old) => {
                              return {
                                ...old,
                                office: officeId,
                              } as CompanyUserEditData;
                            });
                          }}
                          options={offices}
                        />
                        <FieldErrorMsg errors={errors?.office_id || []} />
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    {user?.role.name &&
                    ["company_super_admin", "company_admin"].includes(
                      user?.role.name
                    ) &&
                    !["company_super_admin"].includes(
                      String(userData?.role?.name)
                    ) ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Select
                            label="Роля"
                            id="role"
                            value={String(userData?.role?.id || "")}
                            placeholder="Изберете роля"
                            onChange={(role_id) => {
                              setUserData((old) => {
                                return {
                                  ...old,
                                  role: {
                                    id: role_id,
                                  },
                                } as CompanyUserEditData;
                              });
                            }}
                            options={companyUserRoles}
                          />
                        </div>
                        <FieldErrorMsg errors={errors?.role_id || []} />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Input
                          label="Парола"
                          placeholder="Парола"
                          type="password"
                          id="password"
                          name="password"
                          value={userData?.password || ""}
                          onChange={(password) => {
                            resetPasswordErrors();
                            setUserData((old) => {
                              return {
                                ...old,
                                password,
                              } as CompanyUserEditData;
                            });
                          }}
                        />
                      </div>
                      <FieldErrorMsg
                        errors={errors?.password || localErrors.password || []}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-end align-items-center">
                    {isSaving && (
                      <div className="col-1 ">
                        <Loader />
                      </div>
                    )}
                    <div className="col-auto mt-3">
                      <Button
                        disabled={isSaving}
                        onClick={handleSave}
                        type="primary"
                        content={
                          mode === "edit" ? "Запази промените" : "Създай"
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentContainerWithSidebar>
  );
};

export default UserDetails;
