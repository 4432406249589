import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import Button from "../../../common/Button/Button";
import Icon from "../../../common/Icon/Icon";
import Switch from "../../../common/Switch/Switch";
import Input from "../../../common/Input/Input";
import useEvaluationContext from "../../../../context/evaluation";

interface OptionsSelectModalProps {
  title?: string;
  isShown?: boolean;
  disabled?: boolean;
  options: any;
  standardOptions: any;
  onConfirm?: (
    options: { name: string; cost: number; id: string; abbr?: string }[]
  ) => void;
  onClose?: () => void;
}

const OptionsSelectModal = ({
  onClose,
  onConfirm,
  options,
  standardOptions,
  isShown,
}: OptionsSelectModalProps) => {
  const modalRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [customOptionName, setSetCustomOptionName] = useState<string>("");
  const [customOptionPrice, setSetCustomOptionPrice] = useState<string>("");
  const [customOptions, setCustomOptions] = useState<
    { name: string; cost: number; id: string }[]
  >([]);

  const { evalCriteria, additionalData } = useEvaluationContext();

  useEffect(() => {
    if (evalCriteria.options_correction === undefined) {
      setSelectedOptions(() => []);
      setCustomOptions(() => []);
    } else {
      // console.log(evalCriteria.options_correction);
      const { added_options } = additionalData;
      // console.log(added_options);
      if (added_options) {
        const optionIds: number[] = added_options
          .filter((option: any) => !option.id.toString().startsWith("custmo_"))
          .map((option: any) => option.id);
        const customOptions: { name: string; cost: number; id: string }[] =
          added_options.filter((option: any) =>
            option.id.toString().startsWith("custmo_")
          );

        setSelectedOptions(() => optionIds);
        setCustomOptions(() => customOptions);
      }
    }
  }, [evalCriteria]);

  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    // Initialize the modal
    const bsModal = new Modal(modal, {});

    // Handle cleanup when the component unmounts
    return () => bsModal.dispose();
  }, []);

  const handleConfirm = () => {
    const extractedOptions = options
      .filter((option: any) => selectedOptions.includes(option.id))
      .map((option: any) => ({ ...option, cost: Number(option.cost) }));
    const mergedOptions = [...extractedOptions, ...customOptions];

    onConfirm && onConfirm(mergedOptions);
  };

  const handleAddCustomOption = () => {
    if (customOptionName === "" || customOptionPrice === "") return;
    const newCustomOption = {
      id: `custmo_${Math.ceil(Math.random() * 1000)}`,
      name: customOptionName,
      cost: Number(customOptionPrice),
    };
    setCustomOptions((prevState) => [...prevState, newCustomOption]);
    setSetCustomOptionName("");
    setSetCustomOptionPrice("");
  };

  let modal = <></>;

  if (isShown) {
    modal = (
      <>
        <div
          className="modal fade show modal-xl"
          style={{ display: "block" }}
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row justify-items-between">
                    <div className="col-auto">
                      <h5 className="modal-title">Опции</h5>
                    </div>
                    <div className="col-auto ms-auto">
                      <Button onClick={onClose} type="primary" size="sm">
                        <Icon name="x" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        type="primary"
                        content="Отказ"
                        size="sm"
                        onClick={() => {
                          setSelectedOptions(() => []);
                          onClose && onClose();
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        type="primary"
                        content="Потвърди"
                        size="sm"
                        onClick={handleConfirm}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Допълнително оборудване</h4>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "3%" }}></th>
                            <th scope="col" style={{ width: "10%" }}>
                              Код
                            </th>
                            <th scope="col" style={{}}>
                              Наименование
                            </th>
                            <th scope="col" style={{ width: "20%" }}>
                              Цена (BGN)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {options.map((option: any, index: any) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Switch
                                    checked={selectedOptions.includes(
                                      option.id
                                    )}
                                    onChange={() => {
                                      setSelectedOptions((prevState: any) => {
                                        if (prevState.includes(option.id)) {
                                          return prevState.filter(
                                            (id: number) => id !== option.id
                                          );
                                        }
                                        return [...prevState, option.id];
                                      });
                                    }}
                                  ></Switch>
                                </td>
                                <td>{option.abbr}</td>
                                <td>{option.name}</td>
                                <td>{option.cost}</td>
                              </tr>
                            );
                          })}
                          {customOptions.map((option: any) => {
                            return (
                              <tr>
                                <td>
                                  <Button
                                    type="primary"
                                    size="sm"
                                    onClick={() => {
                                      setCustomOptions((prevState) => {
                                        return prevState.filter(
                                          (opt) => opt.id !== option.id
                                        );
                                      });
                                    }}
                                  >
                                    <Icon name="x" />
                                  </Button>
                                </td>
                                <td>-</td>
                                <td>{option.name}</td>
                                <td>{option.cost.toFixed(2)}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <Button
                                type="primary"
                                onClick={() => handleAddCustomOption()}
                              >
                                <Icon name="plus" />
                              </Button>
                            </td>
                            <td colSpan={2}>
                              <Input
                                noReset
                                name="custome-option-name"
                                value={customOptionName}
                                onChange={(val) => {
                                  setSetCustomOptionName(() => String(val));
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                name="custome-option-price"
                                type="number"
                                noReset
                                value={customOptionPrice}
                                onChange={(val) => {
                                  setSetCustomOptionPrice(() => String(val));
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-6">
                      <h4>Стандартно оборудване</h4>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "3%" }}>
                              Код
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              Брой
                            </th>
                            <th scope="col" style={{}}>
                              Наименование
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {standardOptions.map((option: any, index: any) => {
                            return (
                              <tr key={index}>
                                <td>{option.abbr}</td>
                                <td>{option.count}</td>
                                <td>{option.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        type="primary"
                        content="Отказ"
                        size="sm"
                        onClick={() => {
                          setSelectedOptions(() => []);
                          onClose && onClose();
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <Button
                        type="primary"
                        content="Потвърди"
                        size="sm"
                        onClick={handleConfirm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" onClick={onClose}></div>
      </>
    );
  }

  return modal;
};

export default OptionsSelectModal;
