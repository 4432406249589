import App from "../App";
import Home from "../pages/Home/Home";
import { Login } from "../pages/Login";
import { NotFound } from "../pages/errors";
import { Register } from "../pages/Register";
import { GuestRoutes } from "../utils/GuestRoutes";
import { createBrowserRouter } from "react-router-dom";
import { PrivateRoutes } from "../utils/PrivateRoutes";
import { AutoExpertVIN } from "../pages/AutoExpertVIN";
import { AutoExpertNew } from "../pages/AutoExpertNew";
import { AuthProvider } from "../context/authentication";
import { AutoExpertEasy } from "../pages/AutoExpertEasy";
import ResetPassword from "../pages/ForgotPassword/ResetPassword";
import { AutoExpertEasyLanding } from "../pages/AutoExpertEasyLanding";
import { ApplicationsLayout, LandingLayout, ProfileLayout } from "../layouts";
import { AutoExpoertMarketPrice } from "../pages/AutoExpertMarketPrice";
import RequestResetPassword from "../pages/ForgotPassword/RequestResetPassword";
import AutoExpertVINLanding from "../pages/AutoExpertVINLanding/AutoExpertVINLanding";
import AutoExpertNewLanding from "../pages/AutoExpertNewLanding/AutoExpertNewLanding";
import AutoExpertInsValueLanding from "../pages/AutoExpertInsValueLanding/AutoExpertInsValueLanding";
import AutoExpertMarketPriceLanding from "../pages/AutoExpertMarketPriceLanding/AutoExpertMarketPriceLanding";
import AboutUs from "../pages/AboutUs/AboutUs";
import {
  Settings as ProfileSettings,
  History as ProfileHistory,
} from "../pages/Profile";
import { Applications, ApplicationEdit } from "../pages/Shared";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import { AutoExpertNewDemo } from "../pages/AutoExpertNewDemo";
import AutoExpertNewDemoLanding from "../pages/AutoExpertNewDemoLanding/AutoExpertNewDemoLanding";
import {
  PorscheLoginPage,
  OZKLoginPage,
  OZKEvaluationPage,
} from "../pages/VIP";
import AdministrationLayout from "../layouts/AdministrationLayout";
import {
  CompanyUsers,
  CompanyHistory,
  CompanyUserDetails,
  CompanySettings,
} from "../pages/Company";
import AutoExpertTechDataLanding from "../pages/AutoExpertTechDataLanding/AutoExpertTechDataLanding";
import { AutoExpertTechData } from "../pages/AutoExpertTechData";
import { AutoExpertEasyEdit } from "../pages/AutoExpertEasyEdit";
import { AutoExpertInsValue } from "../pages/AutoExpertInsValue";
import { getIsExperimental } from "../utils/env";

const createRouter = () => {
  const privateRoutes = [
    {
      path: "/aei-market-price",
      element: <AutoExpoertMarketPrice />,
    },
    {
      path: "/aei-ins-value",
      element: <AutoExpertInsValue />,
    },
    {
      path: "/aei-tech-data",
      element: <AutoExpertTechData />,
    },
    {
      path: "/aei-vin",
      element: <AutoExpertVIN />,
    },
    { path: "/aei-new", element: <AutoExpertNew /> },
    { path: "/aei-new-demo", element: <AutoExpertNewDemo /> },
    { path: "/aei-easy", element: <AutoExpertEasy /> },
    { path: "/aei-easy/:evalId/edit", element: <AutoExpertEasyEdit /> },
    {
      path: "/clients/ozk/identification",
      element: <OZKEvaluationPage />,
    },
  ];
  const profileRoutes = [
    {
      path: "/profile/settings",
      element: <ProfileSettings />,
    },
    {
      path: "/profile/history",
      element: <ProfileHistory />,
    },
    {
      path: "/profile/applications",
      element: <Applications />,
    },
    {
      path: "/profile/applications/:applicationName",
      element: <ApplicationEdit />,
    },
  ];
  const administrationRoutes = [
    {
      path: "/administration/settings",
      element: <CompanySettings />,
    },
    {
      path: "/administration/applications",
      element: <Applications />,
    },
    {
      path: "/administration/applications/:applicationName",
      element: <ApplicationEdit />,
    },
    {
      path: "/administration/users",
      element: <CompanyUsers />,
    },
    {
      path: "/administration/users/create",
      element: <CompanyUserDetails mode="create" />,
    },
    {
      path: "/administration/users/:userId",
      element: <CompanyUserDetails mode="edit" />,
    },
    {
      path: "/administration/history",
      element: <CompanyHistory />,
    },
  ];

  const guestRoutes = [
    {
      path: "/info/aei-tech-data",
      element: <AutoExpertTechDataLanding />,
    },
    {
      path: "/info/aei-ins-value",
      element: <AutoExpertInsValueLanding />,
    },
    {
      path: "/info/aei-market-price",
      element: <AutoExpertMarketPriceLanding />,
    },
    {
      path: "/info/aei-easy",
      element: <AutoExpertEasyLanding />,
    },
    {
      path: "/info/aei-vin",
      element: <AutoExpertVINLanding />,
    },
    {
      path: "/info/aei-new",
      element: <AutoExpertNewLanding />,
    },
    {
      path: "/info/aei-new-demo",
      element: <AutoExpertNewDemoLanding />,
    },
    {
      path: "clients/porsche/login",
      element: <PorscheLoginPage />,
    },
    {
      path: "clients/ozk/login",
      element: <OZKLoginPage />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "create-account",
      element: <Register />,
    },
    {
      path: "forgot-password",
      element: <RequestResetPassword />,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
  ];

  // TODO: Remove this when the page is ready
  // if (getIsExperimental()) {
  //   guestRoutes.push();
  //   privateRoutes.push();
  // }
  // guestRoutes.push();
  // privateRoutes.push();

  return createBrowserRouter([
    {
      element: (
        <AuthProvider>
          <App />
        </AuthProvider>
      ),
      errorElement: <NotFound />,
      children: [
        {
          element: <LandingLayout />,
          children: [
            { path: "/", element: <Home /> },
            {
              path: "/email-verification/:code",
              element: <EmailVerification />,
            },
            { path: "/about-us", element: <AboutUs /> },
            {
              element: <GuestRoutes />,
              children: guestRoutes,
            },
          ],
        },
        {
          element: <PrivateRoutes />,
          children: [
            {
              element: <ApplicationsLayout />,
              children: privateRoutes,
            },
            {
              element: <AdministrationLayout />,
              children: administrationRoutes,
            },
            {
              element: <ProfileLayout />,
              children: profileRoutes,
            },
          ],
        },
      ],
    },
  ]);
};

export { createRouter };
