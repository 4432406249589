import { useState, useEffect } from "react";
import { getProductInfo, getStatistics } from "../../api/main";
import Description, {
  DescriptionProps,
} from "./components/Description/Description";
import { formatStatistics } from "../../utils/helpers";
import { SystemStatistics } from "../../utils/types";
import Hero, { HeroProps } from "./components/Hero/Hero";
import Prices from "./components/Prices/Prices";
import VIPClients, { Client } from "./components/VIPClients/VIPClients";

export type ServiceLandingPageProps = {
  productName: string;
  hero: HeroProps;
  description?: DescriptionProps;
  clients?: Array<Client>;
  noPrices?: boolean;
};
type ServiceLandingPagePrices = {
  name: string;
  price: string;
  is_internal: boolean;
};

const ServiceLandingPage = ({
  productName,
  hero,
  description,
  clients,
  noPrices = false
}: ServiceLandingPageProps) => {
  const [stats, setStats] = useState<SystemStatistics | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prices, setPrices] = useState<ServiceLandingPagePrices[]>();
  const [mainModulePrice, setMainModulePrice] = useState<string>("");

  const getPrices = async () => {
    const product = await getProductInfo(productName);
    const priceInfo = product.prices.license || product.prices.modules;
    const prices: ServiceLandingPagePrices[] = [];
    priceInfo.forEach((item: any) => {
      prices.push({
        name: item.name,
        price: item.price,
        is_internal: item.is_internal,
      });
      setPrices(() => prices);
    });

    switch (productName) {
      case "new":
      case "easy":
        const idx = product.prices.modules.findIndex(
          (item) => item.type === "evaluation"
        );
        if (idx !== -1) {
          setMainModulePrice(() => product.prices.modules[idx].price);
        }
        break;
      default:
        break;
    }
  };
  const fetchStatistics = async () => {
    setIsLoading(true);
    const response = await getStatistics();

    setStats(formatStatistics(response));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStatistics();
    getPrices();
  }, []);

  return (
    <div className="container">
      <Hero {...hero} />
      <div className="row">
        {description && (
          <div className="col-md-6">
            <Description
              isLoading={isLoading}
              title={description.title}
              description={description?.description}
              stats={stats}
              showStats={description?.showStats}
            />
          </div>
        )}
        {!noPrices && prices && prices.length > 0 && (
          <div className="col-md-6">
            <Prices prices={prices} mainModulePrice={mainModulePrice} />
          </div>
        )}
      </div>
      {clients && <VIPClients clients={clients} />}
    </div>
  );
};

export default ServiceLandingPage;
