import "./AutoExpertInsValue.css";
import { Button } from "../../components";
import { useEffect, useMemo, useState } from "react";
import Switch from "../../components/common/Switch/Switch";
import VINInput from "../../components/page-components/VINInput/VINInput";
import useAuthContext from "../../context/authentication";
import {
  chargeProduct,
  getProduct,
  getVehiclePrices,
  openPDFGeneration,
} from "../../api/main";
import DynamicSelect from "../../components/page-components/DynamicSelect/DynamicSelect";
import {
  VehicleT,
  parseVehicles,
  groupByImageHash,
} from "../../utils/vehicles";
import { buildFieldStructure, scrollIntoView } from "../../utils/helpers";
import VehicleDataTable from "../../components/page-components/VehicleDataTable/VehicleDataTable";
import NightModeToggle from "../../components/page-components/NightModeToggle/NightModeToggle";
import { ProductModuleT, DecodedVinResponse, ProductT } from "../../api/types";
import { AEIFilterFieldsT } from "../../utils/types";
import {
  CORRECTION_RANGE,
  EVALUATION,
  VEHICLE_FILTERS,
  VIN_DECODING,
} from "../../utils/constants";
import Pricing from "../../components/page-components/Pricing/Pricing";
import Loader from "../../components/common/Loader/Loader";
import ProductAccessDenied from "../../components/page-components/ProductAccessDenied/ProductAccessDenied";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import RegistrationNumberInput from "../../components/page-components/RegistrationNumberInput/RegistrationNumberInput";
import Evaluation from "./Evaluation";

const MAX_EMPTY_CRITERIA = 1;
const PRODUCT_NAME = "ins-value";

// TODO: extract fetch logic to a custom hook
const AutoExpertInsValue = () => {
  useEffect(() => {
    document.title = "AEI InsValue | AutoExpert International";
  }, []);
  const { userBalance, getUserBalance } = useAuthContext();
  const [registerNumber, setRegisterNumber] = useState<string>("");

  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [fields, setFields] = useState<AEIFilterFieldsT[]>([]);
  const [evalConfiguration, setEvalConfiguration] = useState<any>();
  const [vindecoderConf, setVindecoderConf] = useState<ProductModuleT | null>();
  const [correctionRangeConf, setCorrectionRangeConf] =
    useState<ProductModuleT | null>();
  const [product, setProduct] = useState<ProductT>();
  const [criterion, setCriterion] = useState<any>({});
  const [vehicles, setVehicles] = useState<{ [key: string]: VehicleT[] }>({});
  const [priceId, setPriceId] = useState<number | null>(null);

  const [vin, setVin] = useState<string>("");

  const [automaticCharge, setAutomaticCharge] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const fetchVehicles = async () => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      for (const [key, value] of Object.entries(criterion)) {
        if (value === "-1") {
          delete criterion[key];
        }
      }
      if (!product) {
        return;
      }

      const response = await getVehiclePrices(product?.name, criterion);
      if ("error" in response) {
        setErrorMessage(response.error.message);
        setIsLoading(false);
        return;
      }

      const parsedVehicles = parseVehicles(response);

      setVehicles(() => groupByImageHash(parsedVehicles));
      scrollIntoView("#results");
    } catch {}
    setIsLoading(false);
  };

  const fetchProductConfig = async () => {
    try {
      setPageIsLoading(() => true);
      const response = await getProduct(PRODUCT_NAME);
      setProduct(() => response);
      if (response.can_use === false) {
        setPageIsLoading(() => false);
        return setAccessDenied(() => true);
      }

      // extract the VinDecoder configuration
      setVindecoderConf(() =>
        response.modules.find(
          (productModule) => productModule.name === VIN_DECODING
        )
      );
      setCorrectionRangeConf(() =>
        response.modules.find(
          (productModule) => productModule.name === CORRECTION_RANGE
        )
      );

      // extract the Vehicle Filters configuration
      const vFConf = response.modules.find(
        (productModule: any) => productModule.name === VEHICLE_FILTERS
      );

      setFields(() => buildFieldStructure(vFConf?.fields));

      // extract the Vehicle Filters configuration
      const evalConf = response.modules.find(
        (productModule: any) => productModule.name === EVALUATION
      );
      setEvalConfiguration(() => evalConf);
      // configuration
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setAccessDenied(() => true);
      }
    }
    setPageIsLoading(() => false);
  };

  const costText = useMemo(() => {
    return evalConfiguration?.cost && !evalConfiguration?.licensed ? (
      <span className="text-price">Цена: {evalConfiguration.cost} лв.</span>
    ) : (
      ""
    );
  }, [evalConfiguration]);

  useEffect(() => {
    fetchProductConfig();
    getUserBalance();
  }, []);

  const handleChange = (
    fieldName: string,
    selectedOption: string,
    manualChange: boolean = false
  ) => {
    const selectedIndex = fields.findIndex(
      (field: any) => field.name === fieldName
    );

    setFields((prevState: any) => {
      const newState = [...prevState];
      newState[selectedIndex].selectedOption = selectedOption;

      if (!newState[selectedIndex].maintainFilters) {
        for (let index = selectedIndex + 1; index < newState.length; index++) {
          newState[index].active = false;
          delete newState[index].selectedOption;
        }
      }
      if (manualChange) {
        for (let index = 0; index < newState.length; index++) {
          newState[index].decodedVinFilter = null;
        }
        setVin(() => "");
      }

      if (newState[selectedIndex + 1]) {
        newState[selectedIndex + 1].active = true;
      }
      const criterion: any = {};
      newState.forEach((field: any) => {
        if (field.selectedOption) {
          criterion[field.name] = field.selectedOption;
        }
      });
      setCriterion(() => criterion);

      return newState;
    });
  };

  const handleDecodedVIN = async (
    decodedInfo: DecodedVinResponse,
    vinValue: string
  ) => {
    resetFilters();
    getUserBalance();
    setVin(() => vinValue);

    await new Promise((resolve) => setTimeout(resolve, 1));
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      const decodedData = decodedInfo.find(
        (item) => item.filter && item.filter.name === field.name
      );
      setFields((prevState: any) => {
        const newState = [...prevState];
        newState[index].decodedVinFilter = decodedData?.filter;
        return newState;
      });
    }
  };

  const handleLabelChange = (fieldName: string, label: string) => {
    const selectedIndex = fields.findIndex(
      (field: any) => field.name === fieldName
    );
    setFields((prevState: any) => {
      const newState = [...prevState];
      newState[selectedIndex].selectedOptionLabel = label;
      return newState;
    });
  };

  let formFields: JSX.Element[] = [];
  if (fields) {
    formFields = fields.map((field: any, index) => {
      const last = index === fields.length - 1;
      const isMultiple = field.name === "body_type";
      return (
        <DynamicSelect
          product={product?.name}
          key={field.name}
          field={field}
          isMultiple={isMultiple}
          criterion={criterion}
          isLastField={last}
          withoutLastFieldAdditionalOption
          onError={(message: string) => {
            setErrorMessage(message);
          }}
          onLabelChange={(fieldName: string, label: string) =>
            label !== "" && handleLabelChange(fieldName, label)
          }
          onChange={(
            fieldName: string,
            selectedOption: string,
            manualChange: boolean
          ) => {
            handleChange(fieldName, selectedOption, manualChange);
          }}
        />
      );
    });
  }

  useEffect(() => {
    const hasEnoughFields: boolean = (fields.length &&
      fields.length - Object.keys(criterion).length <=
        MAX_EMPTY_CRITERIA) as boolean;

    // Check if the criterion has enough fields to fetch prices
    // and the fields are not empty (not yet fetched)
    if (hasEnoughFields && !isLoading) {
      fetchVehicles();
    } else {
      setVehicles(() => ({}));
      setPriceId(() => null);
    }
  }, [criterion]);

  let vehicleDisplay: JSX.Element[] = [];
  if (Object.keys(vehicles).length) {
    for (const key in vehicles) {
      vehicleDisplay.push(
        <div key={key}>
          <VehicleDataTable
            data={vehicles[key]}
            vin={vin}
            product={product?.name}
            onEvaluationClick={(priceId: number) => {
              setPriceId(priceId);
              setTimeout(() => {
                scrollIntoView("#eval_data");
              }, 100);
            }}
          />
          <hr className="my-2" />
        </div>
      );
    }
  }
  const resetFilters = () => {
    setPriceId(null);
    setFields((prevState: any) => {
      const newState = [...prevState];
      newState.forEach((field: any) => {
        field.active = false;
        delete field.selectedOption;
        delete field.selectedOptionLabel;
        delete field.decodedVinFilter;
      });
      newState[0].active = true;
      setCriterion(() => ({}));
      return newState;
    });
  };

  return accessDenied ? (
    <ProductAccessDenied product={product} />
  ) : pageIsLoading ? (
    <Loader />
  ) : (
    <>
      {!automaticCharge ? (
        <ConfirmationModal
          title="Платена услуга"
          onClose={() => setIsConfirmModalVisible(() => false)}
          onConfirm={() => {
            setIsConfirmModalVisible(() => false);
          }}
          message={
            <div>
              Сигурни ли сте, че искате да продължите? <br />
              {costText}
            </div>
          }
          isShown={isConfirmModalVisible}
        />
      ) : (
        <></>
      )}
      {errorMessage !== "" ? (
        <ErrorModal
          title={"Грешка"}
          message={errorMessage}
          isShown={true}
          onClose={() => setErrorMessage(() => "")}
        />
      ) : (
        <></>
      )}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row justify-content-end">
              <div className={"col-auto"}>
                <NightModeToggle />
              </div>
            </div>
            <div className="row">
              <div className="col ">
                <h2 className="d-flex align-items-center justify-content-center mb-2">
                  <span>Удостоверение за застрахователна стойност (УЗС)</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <h4>Наличен баланс: {userBalance || 0} лв.</h4>
              </div>
              <div className="col-auto">
                <Switch
                  id="autoCharge"
                  checked={automaticCharge}
                  onChange={(e) =>
                    setAutomaticCharge(() => {
                      return e.target.checked;
                    })
                  }
                >
                  <p
                    className={`text-${automaticCharge ? "success" : "danger"}`}
                  >
                    Съгласен съм да бъда автоматично таксуван за наличните
                    услуги.
                  </p>
                </Switch>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {product && <Pricing product={product} />}
              </div>
            </div>
            {userBalance && userBalance < 5 ? (
              <div className="row">
                <div className="col-auto">
                  <p className="alert alert-warning">
                    Внимание! Вашият баланс е нисък. За да продължите да
                    използвате приложението безпроблемно, моля добавете
                    средства. Благодарим Ви!
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="row">
              <div className="col">
                <h5></h5>
              </div>
            </div>
            {vindecoderConf && product ? (
              <VINInput
                product={product?.name}
                configuration={vindecoderConf}
                onDecode={handleDecodedVIN}
                regNumber={registerNumber}
                withConfirmation={!automaticCharge}
              >
                <RegistrationNumberInput
                  onChange={(value) => setRegisterNumber(value)}
                />
              </VINInput>
            ) : (
              <div className="row mb-2">
                <RegistrationNumberInput
                  onChange={(value) => setRegisterNumber(value)}
                />
              </div>
            )}
            <div className="row">
              <div className="col border border-dark rounded-1 p-3">
                <div className="row  justify-content-between">
                  <div className="col-md-8">
                    <h4 className="mt-2 ">ФИЛТРИРАНЕ НА ТЕХНИЧЕСКИ ДАННИ</h4>
                  </div>
                  <div className="col-md-4 col-lg-3 mb-3">
                    <Button type="primary" onClick={resetFilters}>
                      Изчисти филтрите
                    </Button>
                  </div>
                </div>
                <div className="row">{formFields}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <div className={"container-fluid"}>
        <div className="row">
          <div className="col" id="results">
            {vehicleDisplay}
          </div>
        </div>
      </div>

      {priceId && (
        <Evaluation
          priceId={priceId}
          evalCost={costText}
          vin={vin}
          regNumber={registerNumber}
          automaticCharge={automaticCharge}
          correctionRange={correctionRangeConf?.parameters}
          onCharge={() => {
            getUserBalance();
          }}
          onError={(errMsg: string) => {
            setErrorMessage(errMsg);
          }}
        />
      )}
    </>
  );
};

export default AutoExpertInsValue;
