import { formatNumber } from "../../../utils/helpers";

type SummaryProps = {
  evaluationData: {
    original_price: number;
    base_residual_price: number;
    residual_price: number;
    correction: number;
    correction_percent: number;
  };
};

const Summary = ({ evaluationData }: SummaryProps) => {
  return (
    <>
      <div className="row fw-bold">
        <div className="col-6">Цена в ново:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.original_price)} лв.
        </div>
      </div>

      <div className="row fw-bold">
        <div className="col-6 ">Овехтена стойност:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.base_residual_price)} лв.
        </div>
      </div>
      {evaluationData.correction !== 0 && (
        <div className="row fw-bold">
          <div className="col-6 ">
            Корекция ({evaluationData.correction_percent}%):
          </div>
          <div className="col-xs-6 col-md-3 text-end">
            {evaluationData.correction > 0 ? "+" : ""}
            {formatNumber(evaluationData.correction)} лв.
          </div>
        </div>
      )}

      <div className="row fw-bold">
        <div className="col-6 ">Застрахователна стойност:</div>
        <div className="col-xs-6 col-md-3 text-end">
          {formatNumber(evaluationData.residual_price)} лв.
        </div>
      </div>
      <hr />
    </>
  );
};

export default Summary;
