import { UserEntryT } from "../../../api/types";
import { Badge } from "../../../components/common";
import { role2displayName } from "../../../utils/helpers";

const config = [
  {
    title: "Последно влизане",
    render: (row: UserEntryT) => {
      return row.last_login_at || "Няма активност";
    },
  },
  {
    title: "Име",
    render: (row: UserEntryT) => {
      return row.full_name || "N/A";
    },
  },
  {
    title: "Имейл",
    render: (row: UserEntryT) => {
      return row.email || "N/A";
    },
  },
  {
    title: "Клон",
    render: (row: UserEntryT) => {
      return row.branch.name || "N/A";
    },
  },
  {
    title: "Офис",
    render: (row: UserEntryT) => {
      return row.office.name || "N/A";
    },
  },
  {
    title: "Роля",
    render: (row: UserEntryT) => {
      return role2displayName(row.role.name) || "N/A";
    },
  },
  {
    title: "Статус",
    render: (row: UserEntryT) => {
      return row.is_active ? <Badge type="added">Активен</Badge> : <Badge type="removed">Неактивен</Badge>;
    },
  },
];

export { config };
