import { useEffect, useState } from "react";
import Switch from "../../components/common/Switch/Switch";
import useAuthContext from "../../context/authentication";
import { getProduct } from "../../api/main";
import VINInput from "../../components/page-components/VINInput/VINInput";
import NightModeToggle from "../../components/page-components/NightModeToggle/NightModeToggle";
import { ProductModuleT, ProductT } from "../../api/types";
import { EVALUATION, VIN_DECODING } from "../../utils/constants";

import Loader from "../../components/common/Loader/Loader";
import TechData from "../../components/page-components/TechData/TechData";
import ProductAccessDenied from "../../components/page-components/ProductAccessDenied/ProductAccessDenied";

const PRODUCT_NAME = "tech-data";

// TODO: extract fetch logic to a custom hook
const AutoExpoertTechData = () => {
  useEffect(() => {
    document.title = "AEI TechData | AutoExpert International";
  }, []);
  const { userBalance, getUserBalance } = useAuthContext();

  //accessDenied
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const [vindecoderConf, setVindecoderConf] = useState<ProductModuleT | null>();
  const [product, setProduct] = useState<ProductT>();
  const [evalConfiguration, setEvalConfiguration] = useState<any>();

  const [automaticCharge, setAutomaticCharge] = useState<boolean>(false);
  const [decodedData, setDecodedData] = useState<any>(null); // TODO: type this
  const [vin, setVin] = useState<string | null>(null); // TODO: type this

  const fetchProductConfig = async () => {
    try {
      setPageIsLoading(() => true);
      const response = await getProduct(PRODUCT_NAME);
      setProduct(() => response);
      if (response.can_use === false) {
        setPageIsLoading(() => false);
        return setAccessDenied(() => true);
      }

      // extract the VinDecoder configuration
      setVindecoderConf(() =>
        response.modules.find(
          (productModule) => productModule.name === VIN_DECODING
        )
      );
      // extract the Vehicle Filters configuration
      const evalConf = response.modules.find(
        (productModule: any) => productModule.name === EVALUATION
      );
      setEvalConfiguration(() => evalConf);
      // configuration
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setAccessDenied(() => true);
      }
    }
    setPageIsLoading(() => false);
  };

  useEffect(() => {
    fetchProductConfig();
    getUserBalance();
  }, []);

  return accessDenied ? (
    <ProductAccessDenied product={product} />
  ) : pageIsLoading ? (
    <Loader />
  ) : (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row justify-content-end">
              <div className={"col-auto"}>
                <NightModeToggle />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2 className="d-flex align-items-center justify-content-center mb-2">
                  Технически Данни
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <h4>Наличен баланс: {userBalance || 0} лв.</h4>
              </div>
              <div className="col-auto">
                <Switch
                  id="autoCharge"
                  checked={automaticCharge}
                  onChange={(e) =>
                    setAutomaticCharge(() => {
                      return e.target.checked;
                    })
                  }
                >
                  <p
                    className={`text-${automaticCharge ? "success" : "danger"}`}
                  >
                    Съгласен съм да бъда автоматично таксуван за наличните
                    услуги.
                  </p>
                </Switch>
              </div>
            </div>
            {userBalance && userBalance < 5 ? (
              <div className="row">
                <div className="col-auto">
                  <p className="alert alert-warning">
                    Внимание! Вашият баланс е нисък. За да продължите да
                    използвате приложението безпроблемно, моля добавете
                    средства. Благодарим Ви!
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {vindecoderConf && product ? (
              <VINInput
                product={product.name}
                configuration={vindecoderConf}
                withConfirmation={!automaticCharge}
                withoutVisualisation={true}
                onDecode={(vinData, vin) => {
                  // console.log(vinData, vin);
                  setDecodedData(() => vinData);
                  setVin(() => vin);
                  getUserBalance();
                }}
              />
            ) : (
              <></>
            )}
            <div className="row">
              <div className="col">
                {decodedData && (
                  <TechData
                    data={decodedData}
                    vin={vin as string}
                    automaticCharge={automaticCharge}
                    configuration={evalConfiguration}
                    onCharge={() => getUserBalance()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoExpoertTechData;
