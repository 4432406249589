import { StatisticsResponse } from "../api/types";
import { BaseFieldT, SystemStatistics } from "./types";
import coupe from "../../resources/assets/body-type-icons/coupe.svg";
import combi from "../../resources/assets/body-type-icons/combi.svg";
import hback from "../../resources/assets/body-type-icons/hback.svg";
import jeep from "../../resources/assets/body-type-icons/jeep.svg";
import van from "../../resources/assets/body-type-icons/van.svg";
import other from "../../resources/assets/body-type-icons/other.svg";
import sedan from "../../resources/assets/body-type-icons/sedan.svg";
import suv from "../../resources/assets/body-type-icons/suv.svg";

/**
 * Convert role to display name
 * @param role
 * @returns {string}
 * @example
 * role2displayName('admin') // => 'Administrator'
 * role2displayName('moderator') // => 'Moderator'
 * role2displayName('user') // => 'User'
 */
function role2displayName(role: string): string {
  switch (role) {
    case "company_super_admin":
      return "Супер администратор";
    case "company_admin":
      return "Администратор";
    case "branch_admin":
      return "Клон администратор";
    case "office_admin":
      return "Офис администратор";
    case "user":
      return "Потребител";
    default:
      return "Непозната роля";
  }
}

/**
 * Fields that are translated to Bulgarian
 * @type {{year: string, make: string, model: string, engine_capacity: string, doors: string, seats: string, gearbox: string, body_type: string, engine_power_hp: string, engine_power_kw: string, submodel: string, fuel_type: string}}
 * @example
 * field2label['year'] // => 'Година на производство. (B)'
 */
const field2label: { [key: string]: string } = {
  year: "Година производство (B)",
  make: "Марка (D.1)",
  model: "Модел (D.2)",
  engine_capacity: "Обем на двигателя, ccm (P.1)",
  doors: "Брой врати (S.1)",
  seats: "Брой места",
  gearbox: "Скоростна кутия",
  net_weight: "Нето маса, кг",
  total_weight: "Общо маса, кг",
  load_capacity: "Товароносимост, кг",
  width: "Ширина, мм",
  length: "Дължина, мм",
  wheel_base: "Междуосие, мм",
  body_type: "Каросерия",
  engine_power_hp: "Мощност, hp",
  engine_power_kw: "Мощност, kW (P.2)",
  engine_power: "Мощност, kW/hp (P.2)",
  submodel: "Подмодел",
  fuel_type: "Гориво",
};

/**
 * Fields that are translated to Bulgarian
 *
 */
const countryCode2name: { [key: string]: string } = {
  BG: "България",
  DE: "Германия",
};

/**
 * Fields that are translated to Bulgarian
 *
 */
const generalCorrection2name: { [key: string]: string } = {
  paint: "Боядисване",
  engine: "Двигател",
  body: "Каросерия",
  interior: "Интериор",
  trunk: "Багажник",
};

/**
 * Fields that are wider by default
 * @type {{doors: number, engine_power_hp: number, engine_power_kw: number, body_type: number}}
 * @example
 * field2Width['doors'] // => 2
 * field2Width['engine_power_hp'] // => 2
 */
const field2Width: { [key: string]: number } = {
  year: 3,
  make: 2,
  model: 2,
  doors: 2,
  engine_power_hp: 2,
  engine_power_kw: 2,
  engine_power: 2,
  fuel_type: 2,
  seats: 2,
  body_type: 3,
  submodel: 2,
};

/**
 * Fields that are grouped by default
 */
const field2Grouped: { [key: string]: boolean } = {
  year: true,
  make: true,
  model: true,
};

/**
 * Fields that should maintain the next filters
 */
const field2MaintainingFilters: { [key: string]: boolean } = {
  year: false,
};
/**
 * Fields that are grouped by default
 */
const field2Sort: { [key: string]: "asc" | "desc" } = {
  year: "desc",
};

/**
 * Build field structure for table
 *
 * @param fields
 * @returns {Array}
 *
 * @example
 * buildFieldStructure(['year', 'make', 'model'])
 * // => [{label: 'Година на производство. (B)', name: 'year', active: true, width: 3, grouped: true}, {label: 'Марка (D.1)', name: 'make', active: false, width: 3, grouped: true}, {label: 'Модел (D.2)', name: 'model', active: false, width: 3, grouped: false}]
 **/
const buildFieldStructure = (fields: any): BaseFieldT[] => {
  const fieldStructure: BaseFieldT[] = [];
  let isFirst = true;
  fields.forEach((field: string) => {
    fieldStructure.push({
      label: field2label[field] || "Unknown",
      sort: field2Sort[field] || "asc",
      maintainFilters: field2MaintainingFilters[field] || false,
      name: field,
      active: isFirst,
      width: field2Width[field] || 3,
      grouped: field2Grouped[field] || false,
    });
    isFirst = false;
  });
  return fieldStructure;
};

const formatDate = (dt: string) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `${month}/${year}`;
};

const formatStatistics = (data: StatisticsResponse): SystemStatistics => {
  const { make_count, model_count, submodel_count, price_count } = data;

  const result = {
    makeCount: "-",
    modelCount: "-",
    submodelCount: "-",
    priceCount: "-",
  };
  try {
    result.makeCount = make_count.toLocaleString().replace(",", " ");
  } catch (error) {}
  try {
    result.modelCount = model_count.toLocaleString().replace(",", " ");
  } catch (error) {}
  try {
    result.submodelCount = submodel_count.toLocaleString().replace(",", " ");
  } catch (error) {}
  try {
    result.priceCount = price_count.toLocaleString().replace(",", " ");
  } catch (error) {}

  return result;
};

/**
 * Scroll to element
 * @param selector
 * @param after
 * @example
 * scrollIntoView('.results-container', 500)
 * // => Scroll to element with class 'results-container' after 500ms
 * */
const scrollIntoView = (selector: string, after: number = 500) => {
  const resultsContainer = document.querySelector(selector);
  setTimeout(() => {
    resultsContainer?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }, after);
};

/**
 * Formats number from 1000 to 1 000
 *
 * @param number
 * @returns string
 */
const formatNumber = (number: number): string => {
  return number.toLocaleString("en").replace(/,/g, " ");
};

const getBodyTypeIcon = (bodyType: string) => {
  switch (bodyType) {
    case "Купе":
    case "Coupe":
    case "Roadster":
      return coupe;
    case "Комби":
    case "Combi":
    case "SW":
      return combi;
    case "Хечбек":
    case "Хетчбек":
    case "HBack":
    case "FBack":
      return hback;
    case "Джип/SUV":
    case "Джип":
      return jeep;
    case "Ван":
    case "Пикап":
    case "Ван/Пикап":
    case "Van":
    case "Pickup":
    case "Combi Van":
      return van;
    case "Други":
      return other;
    case "Седан":
    case "Sedan":
    case "LBack":
    case "Lim":
      return sedan;
    case "SUV":
      return suv;
    case "SUV":
      return suv;
    // case "Bus":
    // case "Бус":
    // ---
    // case "Cabrio":
    // case "Кабриолет":
    case "Всички":
      return '';
    default:
      return other;
  }

};

export {
  formatDate,
  formatNumber,
  scrollIntoView,
  getBodyTypeIcon,
  formatStatistics,
  role2displayName,
  countryCode2name,
  buildFieldStructure,
  generalCorrection2name,
};
