import { useEffect, useState } from "react";
import Loader from "../../common/Loader/Loader";
import { ProductTypeT } from "../../../api/types";
import { getMarketPrice } from "../../../api/main";
import MarketPriceItem from "../MarketPriceItem/MarketPriceItem";
import useEvaluationContext from "../../../context/evaluation";

const loaderElement = (
  <div className="row">
    <div
      className="col d-flex align-items-center justify-content-center"
      style={{ height: "60px" }}
    >
      <Loader />
    </div>
  </div>
);

type MarketPriceEvaluationProps = {
  priceId: string;
  product: ProductTypeT;
  vin?: string;
};

const MarketPriceEvaluation = ({
  priceId,
  product,
  vin,
}: MarketPriceEvaluationProps) => {
  const { marketPrices, setMarketPrices } = useEvaluationContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const loadMarketPrice = async () => {
    // If we have already fetched the market prices, we don't need to do it again
    // for the same vehicle and price id
    if (marketPrices && marketPrices.length > 0) {
      setIsLoading(() => false);
      return;
    }
    setError(() => undefined);
    setIsLoading(() => true);
    try {
      const response = await getMarketPrice(product, priceId, vin);
      setMarketPrices(() => response);
    } catch (e: any) {
      setError(() => e.response.data.message);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    setIsLoading(() => true);
    loadMarketPrice();
  }, [priceId]);

  let marketPriceComponent: any = <></>;
  if (marketPrices) {
    marketPriceComponent = marketPrices.map((marketPriceItem, index) => (
      <MarketPriceItem key={index} marketPriceItem={marketPriceItem} />
    ));
  }
  if (error) {
    marketPriceComponent = (
      <div className="row mt-3">
        <div className="col">
          <div className="row mb-0">
            <div className="col">
              <p className="mb-0">
                <b>Грешка:</b>
              </p>
              <p className="ms-3 mb-0">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="">Пазарна Цена</h4>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    {isLoading ? loaderElement : marketPriceComponent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPriceEvaluation;
