import { VehicleT } from "../../../utils/vehicles";
import BasicTooltip from "../../common/BasicTooltip/BasicTooltip";

const config = [
  {
    title: "Подмодели",
    render: (row: VehicleT) => {
      return row.submodel || "N/A";
    },
  },
  {
    title: "Каросерия/Врат/Места",
    render: (row: VehicleT) => {
      return (
        <>
          <BasicTooltip
            id={`bodyType-${Math.random().toString(36).substring(2, 9)}`}
            tip={row.bodyType.description}
          >
            {row.bodyType.name}
          </BasicTooltip>
          {`/${row.doors}/${row.seats}`}
        </>
      );
    },
  },
  {
    title: "Мощност kW/к.с",
    render: (row: VehicleT) => {
      return `${row.engine.power_kw}/${row.engine.power_hp}`;
    },
  },
  {
    title: "Двигател",
    render: (row: VehicleT) => {
      return row.engine.name || "N/A";
    },
  },
  {
    title: "Гориво",
    render: (row: VehicleT) => {
      return row.fuelName || "N/A";
    },
  },
  {
    title: "Нето маса, кг",
    render: (row: VehicleT) => {
      return row.netWeight || "N/A";
    },
  },
  {
    title: "Товароносимост, кг",
    render: (row: VehicleT) => {
      return row.loadCapacity || "N/A";
    },
  },
  {
    title: "Общо маса",
    render: (row: VehicleT) => {
      return row.totalWeight || "N/A";
    },
  },
  {
    title: "Междуосие, мм",
    render: (row: VehicleT) => {
      return row.wheelBase || "N/A";
    },
  },
  {
    title: "Д/Ш/В, мм",
    render: (row: VehicleT) => {
      return `${row.length}/${row.width}/${row.height}`;
    },
  }
];

export { config };
