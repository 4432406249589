import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";

import preview from "../../../resources/images/laptop-marketprice-preview.png";

const descriptionData = {
  title:
    "Специализирано онлайн приложение за определяне на цена на употребявани МПС.",
  description:
    "Приложението предоставя информация за пазарната цена на употребявани МПС произведени след 2000 година, предлагани на пазара в България и Европа.",
};
const PRODUCT_NAME = "market-price";

const AutoExpertMarketPriceLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "АутоЕксперт пазарна стойност на ПС",
        description: (
          <p>
            Пазарната стойност на ПС се използва често и с най-различни цели
            като покупко-продажба на МПС, застраховане и др.
          </p>
        ),
        image: preview,
        loginUrl: "/login?redirect=aei-market-price",
      }}
      description={descriptionData}
    />
  );
};
export default AutoExpertMarketPriceLanding;
