import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import { axiosInstance } from "../api/main";


interface ErrorContextProps {
  error: string | null;
  setError: (error: string | null) => void;
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.data && error.response.data && error.response.data.error.message && error.response.data.error.code === 4001) {
            setError(error.response.data.error.message);
          }
          return Promise.reject(error);
        }
      );
  }, []);
  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {error && (
        <ErrorModal
          title="Грешка"
          isShown={!!error}
          message={error}
          onClose={() => window.location.reload()}
          onConfirm={() => window.location.reload()}
        />
      )}
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};
