import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";
import preview from "../../../resources/images/laptop-vin-preview.png"; // TODO Change

const descriptionData = {
  title: "Специализирано онлайн приложение за идентификация на МПС по VIN.",
  description:
    "Приложението предоставя информация закодирана от производителя във VIN съгласно ISO 3779/1979.",
};

const PRODUCT_NAME = "vin";

const AutoExpertVINLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "АутоЕксперт VIN/рама/шаси декодиране",
        description: (
          <>
            <p>
              AutoExpert VIN ще Ви помогне да идентифицирате автомобила по
              неговия номер на шаси/рама/VIN (Vehicle Identification Number).
              VIN се състои от 17 символа - главни латински букви (без I, O и Q)
              и цифрите от 0 до 9. VIN е уникален номер за всяко ПС в света. Той
              се поставя от производителя на това ПС и служи за неговото
              идентифициране. Различните производители кодират различна
              информация в него.
            </p>
            <p>
              Продуктът е предназначен за търговци на автомобили, авто експерти,
              застрахователи, лизингови компании, банки, данъчни служби, митници
              и др.
            </p>
          </>
        ),
        image: preview,
        loginUrl: "/login?redirect=aei-vin",
      }}
      description={descriptionData}
    />
  );
};

export default AutoExpertVINLanding;
