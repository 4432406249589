import preview from "../../../resources/images/laptop-insval-preview.png";
import ServiceLandingPage from "../../components/ServiceLandingPage/ServiceLandingPage";

const PRODUCT_NAME = "ins-value";

const AutoExpertInsValueLanding = () => {
  return (
    <ServiceLandingPage
      productName={PRODUCT_NAME}
      hero={{
        title: "AutoExpert InsValue",
        description:
          "Този АЕИ продукт е предназначен само за застрахователни компании. Застрахователната стойност на едно МПС е необходима при изповядване на сделка по покупко-продажба на МПС пред нотариус.",
        image: preview,

        loginUrl: "/login?redirect=aei-ins-value",
      }}
      noPrices={true}
    />
  );
};

export default AutoExpertInsValueLanding;
