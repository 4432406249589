import "./AutoExpertMartketPrice.css";

import { AEIFilterFieldsT } from "../../utils/types";
import { Button } from "../../components";
import { useEffect, useState } from "react";
import Alert from "../../components/common/Alert/Alert";
import Switch from "../../components/common/Switch/Switch";
import useAuthContext from "../../context/authentication";
import {
  getProduct,
  attachGraphImage,
  getVehiclePrices,
  openPDFGeneration,
} from "../../api/main";
import VINInput from "../../components/page-components/VINInput/VINInput";
import DynamicSelect from "../../components/page-components/DynamicSelect/DynamicSelect";
import {
  buildFieldStructure,
  countryCode2name,
  scrollIntoView,
} from "../../utils/helpers";
import NightModeToggle from "../../components/page-components/NightModeToggle/NightModeToggle";
import { ProductModuleT, DecodedVinResponse, ProductT } from "../../api/types";
import {
  VIN_DECODING,
  VEHICLE_FILTERS,
  AEI_MARKET_PRICE,
  VEHICLE_MARKET_PRICE,
} from "../../utils/constants";

import Loader from "../../components/common/Loader/Loader";
import Pricing from "../../components/page-components/Pricing/Pricing";
import SearchButton from "../../components/page-components/SearchButton/SearchButton";
import MarketPriceItem from "../../components/page-components/MarketPriceItem/MarketPriceItem";
import MarketPriceGraph from "../../components/page-components/MarketPriceGraph/MarketPriceGraph";
import ProductAccessDenied from "../../components/page-components/ProductAccessDenied/ProductAccessDenied";

const MAX_EMPTY_CRITERIA = 1;
const MIN_CRITERIA = 3;
const PRODUCT_NAME = "market-price";

// TODO: extract fetch logic to a custom hook
const AutoExpoertMarketPrice = () => {
  useEffect(() => {
    document.title = "AEI MarketPrice | AutoExpert International";
  }, []);
  const { userBalance, getUserBalance } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vinMatcher, setVinMatcher] = useState<boolean>(false);
  const [fields, setFields] = useState<AEIFilterFieldsT[]>([]);
  const [vindecoderConf, setVindecoderConf] = useState<ProductModuleT | null>();
  const [marketPricesConf, setMarketPricesConf] =
    useState<ProductModuleT | null>();
  const [product, setProduct] = useState<ProductT>();
  const [criterion, setCriterion] = useState<any>({});
  const [marketPrices, setMarketPrices] = useState<any>(null);
  const [marketPricesArchiveId, setMarketPricesArchiveId] = useState<
    number | null
  >(null);

  const [marketPriceData, setMarketPricesData] = useState<JSX.Element[]>([]);

  //accessDenied
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const [vin, setVin] = useState<string>("");

  const [automaticCharge, setAutomaticCharge] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const fetchMarketPrices = async () => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      for (const [key, value] of Object.entries(criterion)) {
        if (value === "-1") {
          delete criterion[key];
        }
      }
      if (vin !== "") {
        criterion.vin = vin;
      }

      const response = await getVehiclePrices(AEI_MARKET_PRICE, criterion);
      if ("error" in response) {
        setErrorMessage(response.error.message);
        setIsLoading(false);
        return;
      }

      setMarketPrices(() => response.prices);
      setMarketPricesArchiveId(() => response.archive_id);
      scrollIntoView("#results");
    } catch {
      setErrorMessage("Възникна грешка при търсенето на данните.");
    }
    setIsLoading(false);
  };

  const fetchProductConfig = async () => {
    try {
      setPageIsLoading(() => true);
      const response = await getProduct(PRODUCT_NAME);
      setProduct(() => response);

      if (response.can_use === false) {
        setPageIsLoading(() => false);
        return setAccessDenied(() => true);
      }
      // extract the VinDecoder configuration
      setVindecoderConf(() =>
        response.modules.find(
          (productModule) => productModule.name === VIN_DECODING
        )
      );
      setMarketPricesConf(() =>
        response.modules.find(
          (productModule) => productModule.name === VEHICLE_MARKET_PRICE
        )
      );

      // extract the Vehicle Filters configuration
      const vFConf = response.modules.find(
        (productModule: any) => productModule.name === VEHICLE_FILTERS
      );
      setFields(() => buildFieldStructure(vFConf?.fields));
      // configuration
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setAccessDenied(() => true);
      }
    }
    setPageIsLoading(() => false);
  };

  const handleGeneratedImage = async (image: string, market: string) => {
    try {
      if (marketPricesArchiveId) {
        await attachGraphImage(
          PRODUCT_NAME,
          {
            market,
            image,
          },
          marketPricesArchiveId
        );
      }
    } catch (error) {
      setErrorMessage("Възникна грешка при генерирането на PDF файла.");
    }
  };

  const onSearch = async () => {
    try {
      await fetchMarketPrices();
      await getUserBalance();
    } catch (error) {
      setErrorMessage("Възникна грешка при търсенето на данните.");
    }
  };

  useEffect(() => {
    fetchProductConfig();
    getUserBalance();
  }, []);

  const handleChange = (
    fieldName: string,
    selectedOption: string | Array<string>,
    manualChange: boolean = false
  ) => {
    const selectedIndex = fields.findIndex(
      (field: any) => field.name === fieldName
    );

    setFields((prevState: any) => {
      const newState = [...prevState];
      newState[selectedIndex].selectedOption = selectedOption;

      if (!newState[selectedIndex].maintainFilters) {
        for (let index = selectedIndex + 1; index < newState.length; index++) {
          newState[index].active = false;
          delete newState[index].selectedOption;
        }
      }
      if (manualChange) {
        for (let index = 0; index < newState.length; index++) {
          newState[index].decodedVinFilter = null;
        }
        setVinMatcher(() => false);
        setVin(() => "");
      }

      if (newState[selectedIndex + 1]) {
        newState[selectedIndex + 1].active = true;
      }
      const criterion: any = {};
      newState.forEach((field: any) => {
        if (field.selectedOption) {
          criterion[field.name] = field.selectedOption;
        }
      });
      setCriterion(() => criterion);

      return newState;
    });
    // this will reset the market prices
    if (marketPrices) {
      setMarketPrices(() => null);
      setMarketPricesArchiveId(() => null);
      setMarketPricesData(() => []);
    }
  };

  const handleDecodedVIN = async (
    decodedInfo: DecodedVinResponse,
    vinValue: string
  ) => {
    resetFilters();
    getUserBalance();
    setVin(() => vinValue);
    setVinMatcher(() => true);
    await new Promise((resolve) => setTimeout(resolve, 2000));

    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      const decodedData = decodedInfo.find(
        (item) => item.filter && item.filter.name === field.name
      );
      setFields((prevState: any) => {
        const newState = [...prevState];
        newState[index].decodedVinFilter = decodedData?.filter;
        return newState;
      });
    }
  };

  const handleLabelChange = (
    fieldName: string,
    item:
      | {
          value: string | number;
          label: string;
          selected?: boolean;
        }
      | Array<{
          value: string | number;
          label: string;
          selected?: boolean;
        }>
  ) => {
    const selectedIndex = fields.findIndex(
      (field: any) => field.name === fieldName
    );
    const items = Array.isArray(item) ? item : [item];

    setFields((prevState: any) => {
      const newState = [...prevState];
      newState[selectedIndex].selectedOptionLabel = items
        .map((x) => x.label)
        .join(", ");
      return newState;
    });
  };

  let formFields: JSX.Element[] = [];
  if (fields) {
    formFields = fields.map((field: any, index) => {
      const last = index === fields.length - 1;
      const inMinCriteria = index + 1 <= MIN_CRITERIA;

      let withDefaultAll = !inMinCriteria;

      if (inMinCriteria && field.label && field.label.indexOf("*") === -1) {
        field.label = `${field.label} *`;
      }
      let isMultiple = false;
      if (field.name === "body_type") {
        isMultiple = true;
      }
      
      return (
        <DynamicSelect
          product={PRODUCT_NAME}
          key={field.name}
          field={field}
          criterion={criterion}
          isLastField={last}
          // withVinMatcher={vinMatcher}
          withDefaultAll={withDefaultAll}
          withSimilarityLabels={true}
          isMultiple={isMultiple}
          withoutLastFieldAdditionalOption={true}
          onError={(message: string) => {
            setErrorMessage(message);
          }}
          onLabelChange={(
            fieldName: string,
            item:
              | {
                  value: string | number;
                  label: string;
                  selected?: boolean;
                }
              | Array<{
                  value: string | number;
                  label: string;
                  selected?: boolean;
                }>
              | null
          ) => {
            item && handleLabelChange(fieldName, item);
          }}
          onChange={(
            fieldName: string,
            selectedOption: string | Array<string>,
            manualChange: boolean
          ) => {
            handleChange(fieldName, selectedOption, manualChange);
          }}
        />
      );
    });
  }

  useEffect(() => {
    if (marketPrices && marketPrices.length) {
      const mpPriceBuffer: JSX.Element[] = [];
      marketPrices.forEach((marketPriceItem: any, index: any) => {
        mpPriceBuffer.push(
          <div key={index}>
            {marketPriceItem.graph && marketPriceItem.graph.price ? (
              <MarketPriceGraph
                market={countryCode2name[marketPriceItem.market]}
                data={marketPriceItem.graph}
                year={marketPriceItem.graph.year || 0}
                price={marketPriceItem.graph.price || 0}
                onGeneratedImage={(image: string) =>
                  handleGeneratedImage(image, marketPriceItem.market)
                }
              />
            ) : (
              <></>
            )}

            <MarketPriceItem marketPriceItem={marketPriceItem} />
            {index + 1 !== marketPrices.length && <hr />}
          </div>
        );
      });
      setMarketPricesData(() => mpPriceBuffer);
    }
  }, [marketPrices]);

  const resetFilters = () => {
    setFields((prevState: any) => {
      const newState = [...prevState];
      newState.forEach((field: any) => {
        field.active = false;
        delete field.selectedOption;
        delete field.selectedOptionLabel;
        delete field.decodedVinFilter;
      });
      newState[0].active = true;
      setCriterion(() => ({}));
      return newState;
    });

    setVinMatcher(() => false);
    setMarketPrices(() => null);
    setMarketPricesArchiveId(() => null);
    setMarketPricesData(() => []);
  };
  return accessDenied ? (
    <ProductAccessDenied product={product} />
  ) : pageIsLoading ? (
    <Loader />
  ) : (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row justify-content-end">
              <div className={"col-auto"}>
                <NightModeToggle />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2 className="d-flex justify-content-center">Пазарна цена</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <h4>Наличен баланс: {userBalance || 0} лв.</h4>
              </div>
              <div className="col-auto">
                <Switch
                  id="autoCharge"
                  checked={automaticCharge}
                  onChange={(e) =>
                    setAutomaticCharge(() => {
                      return e.target.checked;
                    })
                  }
                >
                  <p
                    className={`text-${automaticCharge ? "success" : "danger"}`}
                  >
                    Съгласен съм да бъда автоматично таксуван за наличните
                    услуги.
                  </p>
                </Switch>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {product && <Pricing product={product} />}
              </div>
            </div>
            {userBalance && userBalance < 5 ? (
              <div className="row">
                <div className="col-auto">
                  <p className="alert alert-warning">
                    Внимание! Вашият баланс е нисък. За да продължите да
                    използвате приложението безпроблемно, моля добавете
                    средства. Благодарим Ви!
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {vindecoderConf && product ? (
              <div className="row">
                <VINInput
                  product={product?.name}
                  configuration={vindecoderConf}
                  onDecode={handleDecodedVIN}
                  withConfirmation={!automaticCharge}
                  onChange={(vin) => setVin(() => vin)}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="row">
              <div className="col border border-dark rounded-1 p-3">
                <div className="row  justify-content-between">
                  <div className="col-md-8">
                    <h4 className="mt-2 ">ФИЛТРИРАНЕ НА ТЕХНИЧЕСКИ ДАННИ</h4>
                  </div>
                  <div className="col-md-4 col-lg-3 mb-3">
                    <Button type="primary" onClick={resetFilters}>
                      Изчисти филтрите
                    </Button>
                  </div>
                </div>
                <div className="row">{formFields}</div>
                <div className="row">
                  <div className="col">
                    <p className="text-muted m-0">* Задължителни полета</p>
                  </div>
                </div>
                <div className="row">
                  <SearchButton
                    configurations={marketPricesConf}
                    filtersConfirmMessage={fields.map(
                      (field: any, index: number) => {
                        return (
                          <div key={index}>
                            <p className="m-0">
                              <b>{field.label}</b>:{" "}
                              {field.selectedOptionLabel
                                ? field.selectedOptionLabel
                                : "Не е селектиран"}
                            </p>
                          </div>
                        );
                      }
                    )}
                    withConfirmation={!automaticCharge}
                    disabled={
                      Object.keys(criterion).length < MIN_CRITERIA || isLoading
                    }
                    onConfirm={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <div className={"container"}>
        <div className="row">
          <div className="col" id="results">
            {errorMessage !== "" ? (
              <Alert type="danger">{errorMessage}</Alert>
            ) : marketPriceData.length > 0 ? (
              <>
                <div className="row border rounded-1 border-dark mb-3 p-2 pt-2">
                  <div className="col">
                    <div className="row">{marketPriceData}</div>
                    <div className="row justify-content-end mt-4">
                      <div className="col-auto">
                        <Button
                          type="primary"
                          size="md"
                          disabled={marketPricesArchiveId === null}
                          onClick={() => {
                            openPDFGeneration(PRODUCT_NAME, {
                              aid: marketPricesArchiveId,
                            });
                          }}
                        >
                          Печат
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : marketPrices && marketPrices.length === 0 ? (
              <Alert type="danger">
                {"Не е намерена информация за тези филтри."}
              </Alert>
            ) : isLoading ? (
              <Loader />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoExpoertMarketPrice;
