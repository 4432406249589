// Products
const AEI_VIN = "vin";
const AEI_NEW = "new";
const AEI_MARKET_PRICE = "market-price";
// Configuration of the products
const VIN_DECODING = "vin-decoding";
const CORRECTION_RANGE = "correction-range";
const VEHICLE_FILTERS = "vehicle-filters";
const VEHICLE_MARKET_PRICE = "vehicle-mp";
const EVALUATION = "evaluation";

export {
  // Products
  AEI_VIN,
  AEI_NEW,
  AEI_MARKET_PRICE,
  // Configuration of the products
  CORRECTION_RANGE,
  VIN_DECODING,
  VEHICLE_FILTERS,
  VEHICLE_MARKET_PRICE,
  EVALUATION,
};
