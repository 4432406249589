import styles from "./Hero.module.css";
import LinkButton from "../../../common/Button/LinkButton";
import { Button } from "../../..";
import laptopOver from "../../../../../resources/images/laptop.png";

export type HeroProps = {
  title: string;
  description: string | JSX.Element;
  subtext?: string | JSX.Element;
  image?: string;
  video?: string;
  loginUrl: string;
  secondaryAction?: {
    title: string;
    onClick: () => void;
  };
};
const Hero = ({
  title,
  description,
  image,
  video,
  loginUrl,
  secondaryAction,
  subtext,
}: HeroProps) => {
  return (
    <>
      <div className="Hero row align-items-center">
        <div className="col-lg-6 mb-3">
          <h1 className="display-4 text-center">{title}</h1>
          <p>{description}</p>

          <div className="row">
            <div
              className={`col-md-6 ${
                secondaryAction ? "" : "offset-md-3"
              } mt-2`}
            >
              <LinkButton
                to={loginUrl}
                size="md"
                type="primary"
                text="Вход в приложението"
              />
            </div>
            {secondaryAction && (
              <div className="col-md-6 mt-2">
                <Button
                  onClick={secondaryAction.onClick}
                  type="secondary"
                  size="md"
                  content={secondaryAction.title}
                />
              </div>
            )}
          </div>
          {subtext && <p className="mt-2 fs-6">* {subtext}</p>}
        </div>
        <div className="col-lg-6">
          <div className="d-flex justify-content-center">
            {image ? (
              <img
                className="img-fluid"
                alt="Снимка на лаптоп с визуализирана вътрешната ни система."
                src={image}
              />
            ) : (
              <></>
            )}
            {video ? (
              <div className="position-relative">
                <img src={laptopOver} className="position-absolute top-0 start-0 img-fluid" alt="" />
                <video
                  className={`img-fluid ${styles.video}`} 
                  // autoPlay
                  loop
                  muted
                  controls
                  // playsInline
                  src={video}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
