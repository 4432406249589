import styles from "./DBStatCard.module.css";
import Separator from "../common/Separator/Separator";

interface DBStatCardProps {
  title: string;
  value: number | string;
  condensed?: boolean;
}

const DBStatCard = ({ title, value, condensed }: DBStatCardProps) => {
  return (
    <>
      <div className="row">
        <div className="col">
          {condensed ? (
            <h4>{value}</h4>
          ) : (
            <h2 className={`${styles["title"]} fw-bold`}>
              {value}
            </h2>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {condensed ? (
            <h5>{title}</h5>
          ) : (
            <h3 className={`${styles["subtitle"]} fw-bold`}>
              {title}
            </h3>
          )}
        </div>
      </div>
      {condensed ? null : <Separator shadow primaryColored tickness={2} />}
    </>
  );
};
export default DBStatCard;
