import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "bootstrap-icons/font/bootstrap-icons.css";
import { RouterProvider } from "react-router-dom";

import "./index.css";

import { createRouter } from "./app/routes/main";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createRouter();

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
