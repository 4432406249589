import { useEffect, useState } from "react";
import Button from "../../common/Button/Button";
import IconLabel from "../../common/IconLabel/IconLabel";
import OptionsSelectModal from "./components/OptionsSelectModal";
import PriceCorrection from "../PriceCorrection/PriceCorrection";
import useEvaluationContext from "../../../context/evaluation";

const VehicleOptions = () => {
  const { vehicleData, evalCriteria, setEvalCriteria, additionalData, setAdditionalData } =
    useEvaluationContext();

  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [addedOptions, setAddedOptions] = useState<
    { name: string; cost: number; id: string; abbr?: string }[]
  >(additionalData.added_options || []);

  useEffect(() => {
    if (evalCriteria.options_correction === undefined) {
      setAddedOptions(() => []);
      setAdditionalData((prevVal: any) => {
        delete prevVal.added_options;
        return prevVal;
      });
    }
  }, [evalCriteria]);

  return (
    <>
      <OptionsSelectModal
        options={vehicleData?.prices[0].additionalOptions || []}
        standardOptions={vehicleData?.prices[0].standardOptions || []}
        isShown={showOptionsModal}
        onClose={() => setShowOptionsModal(() => false)}
        onConfirm={(selectedOptions) => {
          setAddedOptions(() => selectedOptions);
          setAdditionalData((prevVal: any) => {
            return {
              ...prevVal,
              added_options: selectedOptions,
            };
          });
          const accumulatedPrice = selectedOptions.reduce((prevVal, curVal) => {
            return (prevVal += curVal.cost);
          }, 0);
          setEvalCriteria((prevVal) => ({
            ...prevVal,
            options_correction: {
              value: accumulatedPrice,
              type: prevVal.options_correction?.type || "number",
            },
          }));
          setShowOptionsModal(() => false);
        }}
      />
      <div className="row py-3">
        <div className="col-12">
          <div className="row">
            <div className="col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <PriceCorrection
                label="Корекция за допълнително оборудване"
                size="lg"
                data={{
                  value: evalCriteria.options_correction?.value || 0,
                  type: evalCriteria.options_correction?.type || "number",
                }}
                onChange={(data) => {
                  setEvalCriteria((prevVal) => ({
                    ...prevVal,
                    options_correction: {
                      value: data.value,
                      type: data.type,
                    },
                  }));
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="mb-1">Опции</label>
              <Button
                type="primary"
                onClick={() => {
                  setShowOptionsModal(() => true);
                }}
              >
                <IconLabel name="card-checklist" content={"Списък"} />
              </Button>
            </div>
          </div>
          {addedOptions.length > 0 ? (
            <>
              <div className="row mt-3">
                <div className="col-12">
                  <label>Описание</label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "20%" }}>
                          Код
                        </th>
                        <th scope="col" style={{ width: "20%" }}>
                          Наименование
                        </th>
                        <th scope="col" style={{ width: "20%" }}>
                          Цена (BGN)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedOptions.map((option) => (
                        <tr key={option.id}>
                          <td>{option.abbr ? option.abbr : "-"}</td>
                          <td>{option.name}</td>
                          <td>{option.cost.toFixed()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleOptions;
