import React, { Fragment } from "react";
import { Footer, Header } from "./../components";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {

  return (
    <Fragment>
      <Header></Header>
      <div className="pt-5 Theme-light main-container">
        <main className="container-fluid" style={{ minHeight: "550px" }}>
          <Outlet />
        </main>
      </div>
      <Footer></Footer>
    </Fragment>
  );
};

export default LandingLayout;
