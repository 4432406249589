import "./Footer.css";
import mainLogo from "../../../resources/images/aei-logo.svg";
import { Link } from "react-router-dom";
import { getApiBaseUrl } from "../../utils/env";
const Footer = () => {
  return (
    <div className="Footer aei-fonts  ">
      <div className="container-fluid px-5">
        <div className="row pt-4 ">
          <div className="col-12 text-center ">
            <h3 className="text-center title">
              <span className="f-weight-bold d-block">Работно време:</span>
              <span className="d-block font-regular">08:30 - 17:00 часа</span>
              <span
                className="d-block font-regular"
                style={{ marginBottom: "40px" }}
              >
                от понеделник до петък
              </span>
              <span className="f-weight-bold d-block">Почивни дни:</span>
              <span className="d-block font-regular">събота и неделя</span>
            </h3>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12 text-center Footer-contact-container">
            <div className="row pb-2">
              <div className="col">
                <a
                  href="mailto:contact@auto-expert.biz"
                  className="Footer-contact d-inline-block"
                >
                  Пишете ни
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="tel:+359884662265" className="Footer-contact d-inline-block">
                  Обадете се сега
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 text-center Footer-contact-container"></div>
        </div>
        <hr className="Footer-hr" />
        <div className="row pt-4 position-relative">
          <div
            className="col-lg-6 offset-lg-3 text-center "
            style={{ marginBottom: "20px", zIndex: 15 }}
          >
            <div className="d-flex w-100 justify-content-around align-items-center">
              <a href="#softwear" className="Footer-link">
                Софтуер
              </a>

              <a
                href={`${getApiBaseUrl()}/offers/aei-offer.pdf`}
                target="_blank"
                rel="noreferrer"
                className="Footer-link"
              >
                Цени
              </a>
              <div className="d-lg-none ">
                <img src={mainLogo} alt="" width={"45px"} />
              </div>
              <Link to={"/about-us"} className="Footer-link">
                За нас
              </Link>
              <a href="tel:+359884662265" className="Footer-link">
                Контакти
              </a>
            </div>
          </div>
          <div
            className="position-absolute top-2 d-none d-lg-block"
            style={{ zIndex: 10 }}
          >
            <img src={mainLogo} alt="" width={"125px"} />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12 text-center Footer-copy-container">
            <h5 className="text-center">
              AutoExpert International (AEI) ® {new Date().getFullYear()}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
