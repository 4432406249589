import Textarea from "../../../common/Textarea/Textarea";
import useEvaluationContext from "../../../../context/evaluation";

const AdditionalEvalNotes = () => {
  const { additionalData, setAdditionalData } = useEvaluationContext();

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card card-themed">
          <div className="card-body">
            <h4 className="text-center">Бележки</h4>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <Textarea
                      label=""
                      id="additional-notes"
                      placeholder="Въведете бележки или коментари към оценката"
                      value={additionalData.notes || ""}
                      rows={5}
                      onChange={(val) =>
                        setAdditionalData((old: any) => ({
                          ...old,
                          notes: val,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalEvalNotes;
