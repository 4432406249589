import Icon from "../../../../common/Icon/Icon";
import "./InfoButton.css";

const InfoButton = ({ ...props }: any) => {
  return (
    <button
      className="btn rounded-circle border-0 btn-sm InfoButton-themed"
      {...props}
    >
      <Icon name="plus" />
    </button>
  );
};

export default InfoButton;
