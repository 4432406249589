import bulstradLogo from "../../../../../resources/images/partners/bulstrad-logo.svg";
import colonadLogo from "../../../../../resources/images/partners/colonnade-logo.svg";
import ozkLogo from "../../../../../resources/images/partners/ozk-logo.svg";
import porscheLogo from "../../../../../resources/images/partners/porsche-logo.svg";
import dziLogo from "../../../../../resources/images/partners/dzi-logo.svg";

export const partnerLogos = [
  {
    logo: bulstradLogo,
    url: "https://www.bulstrad.bg/",
    alt: "bulstrad Logo",
  },
  {
    logo: dziLogo,
    url: "https://www.dzi.bg/",
    alt: "dzi Logo",
  },

  {
    logo: ozkLogo,
    url: "https://www.ozk.bg/",
    alt: "ozk Logo",
  },
  {
    logo: porscheLogo,
    url: "http://www.porschebulgaria.bg/",
    alt: "porsche Logo",
  },
  {
    logo: colonadLogo,
    url: "https://www.colonnade.bg/",
    alt: "colonad logo",
  },
];
