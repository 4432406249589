import "./Login.css";
import { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import useAuthContext from "../../../../context/authentication";
import { SystemStatistics } from "../../../../utils/types";
import { getStatistics } from "../../../../api/main";
import { formatStatistics } from "../../../../utils/helpers";
import { Loader } from "../../../../components/common";
import DBStatCard from "../../../../components/DBStatCard/DBStatCard";
import porscheLogo from "../../../../../resources/images/partners/porsche-logo.png";

const Login = () => {
  useEffect(() => {
    document.title = "Porsche VIP - Вход | AutoExpert International";
  }, []);

  const [isRememberMeChecked, setIsRememberMeCheked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const [stats, setStats] = useState<SystemStatistics | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchStatistics = async () => {
    setIsLoading(true);
    const response = await getStatistics();

    setStats(formatStatistics(response));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  const { user, login, errors } = useAuthContext();
  if (user) navigate("/");

  const handleLogin = async () => {
    try {
      const redirectTo = "aei-easy";
      await login(
        { email, password, rememberMe: isRememberMeChecked },
        redirectTo
      );
      setEmail("");
      setPassword("");
    } catch (e) {
      console.log(e);
    }
  };

  let passwordError = null;
  let mailError = null;
  if (errors) {
    if (errors.email) {
      mailError = <p className="text-danger mb-0  mt-1 fs-5">{errors.email[0]}</p>;
    }
    if (errors.password) {
      passwordError = (
        <p className="text-danger mb-0 mt-1 fs-5">{errors.password[0]}</p>
      );
    }
  }

  return (
    <div className="container glossy porsche-container aei-fonts fs-5">
      <div className="row my-3">
        <div className="col-10  offset-1  text-center">
          <img src={porscheLogo} alt="" width={150} />
        </div>
      </div>
      <div className="row my-3">
        <div className="col-10  offset-1">
          <h3 className="text-center font-weight-bold">
            Специализирано Интернет приложение на Porsche Bulgaria за определяне
            действителна стойност на МПС
          </h3>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-10 offset-1">
          <h4 className="text-center font-weight-bold">
            В текущите бази данни на AutoExpert International (AEI) има
            технически данни и цени на внасяни и произвеждани в България
            превозни средства след 01.01.1995 г.
          </h4>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-8 offset-md-2">
          <div className="row ">
            <div className="col text-center">
              <h4>В момента в базата данни има:</h4>
            </div>
          </div>
          {isLoading ? (
            <div className="row text-center justify-content-around">
              <Loader />
            </div>
          ) : (
            <div className="row text-center justify-content-around">
              <div className="col-lg-2 col-md-6">
                <DBStatCard
                  title="марки"
                  value={stats?.makeCount || ""}
                  condensed
                />
              </div>
              <div className="col-lg-2 col-md-6">
                <DBStatCard
                  title="модели"
                  value={stats?.modelCount || ""}
                  condensed
                />
              </div>
              <div className="col-lg-2 col-md-6">
                <DBStatCard
                  title="подмодели"
                  value={stats?.submodelCount || ""}
                  condensed
                />
              </div>
              <div className="col-lg-2 col-md-6">
                <DBStatCard
                  title="цени"
                  value={stats?.priceCount || ""}
                  condensed
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row pb-5 justify-content-center">
        <div className="col-lg-8">
          <div className="row justify-content-center">
            <div className="col">
              <div className="row mb-1">
                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    label="Е-мейл"
                    value={email}
                    onChange={(value) => setEmail(() => value as string)}
                  />
                  {mailError || ""}
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    sizetype="lg"
                    name="password"
                    type="password"
                    placeholder="*****"
                    label="Парола"
                    value={password}
                    onChange={(value) => setPassword(() => value as string)}
                  />
                  {passwordError || ""}
                </div>
              </div>

              <div className="row justify-content-center mb-2">
                <div className="col-xs-12 col-md-6">
                  <Checkbox
                    name="remember-me"
                    checked={isRememberMeChecked}
                    label={"Запомни ме на този компютър"}
                    onChange={() => {
                      setIsRememberMeCheked((old) => !old);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6 order-md-1 order-0">
                  <div className="row justify-content-center mb-4">
                    <div className="col-xs-12">
                      <Button
                        content="Вход"
                        type="primary"
                        className="fs-5"
                        onClick={() => handleLogin()}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 order-md-0 order-1">
                  <div className="row mb-4">
                    <div className="col-xs-12">
                      <Link
                        to="/forgot-password"
                        className="btn Button Button-primary fs-5"
                      >
                        Забравена парола
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mb-5">
                <div className="col text-center">
                  <p>
                    Имате проблем със системата?{" "}
                    <a
                      className="aei-primary-link"
                      href="mailto:contact@auto-expert.biz"
                    >
                      Изпратете ни съобщение
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
