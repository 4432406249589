import { useEffect, useRef, useState } from "react";

import "./ContactUsForm.css";

import { Modal } from "bootstrap";
import Icon from "../../common/Icon/Icon";
import Input from "../../common/Input/Input";
import Select from "../../common/Select/Select";
import Loader from "../../common/Loader/Loader";
import Button from "../../common/Button/Button";
import Textarea from "../../common/Textarea/Textarea";
import { sendContactUsForm } from "../../../api/main";

const PHONE_REGEX = /^([0-9]){0,10}$/;

const validateContactUsForm = (
  reason: string,
  message: string,
  contactPhone: string
) => {
  const errors: { field: string; message: string }[] = [];
  if (reason === "-1") {
    errors.push({ field: "reason", message: "Моля изберете тема" });
  }
  if (message.length < 10) {
    errors.push({
      field: "message",
      message: "Моля въведете съобщение с поне 10 символа",
    });
  }
  if (contactPhone.length < 10) {
    errors.push({
      field: "contactPhone",
      message: "Моля въведете валиден телефонен номер",
    });
  }
  return errors;
};

const ContactUsForm = () => {
  const [isShown, setIsShown] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("-1");
  const [contactPhone, setContactPhone] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [reasonError, setReasonError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [contactPhoneError, setContactPhoneError] = useState("");

  const handleContactPhoneChange = (phone: string | number) => {
    setContactPhoneError(() => "");
    const phoneNumber = String(phone);
    setContactPhone((old) => {
      if (!PHONE_REGEX.test(phoneNumber)) return old;
      return phoneNumber;
    });
  };

  const modalRef = useRef(null);
  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    // Initialize the modal
    const bsModal = new Modal(modal, {});

    // Handle cleanup when the component unmounts
    return () => bsModal.dispose();
  }, []);

  const handleConfirm = async () => {
    const errors = validateContactUsForm(reason, message, contactPhone);
    if (errors.length === 0) {
      try {
        setIsLoading(() => true);
        const response = await sendContactUsForm(reason, message, contactPhone);
        // console.log(response);
        setIsSuccess(() => true);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(() => false);
    }
    errors.forEach((error) => {
      switch (error.field) {
        case "reason":
          setReasonError(() => error.message);
          break;
        case "message":
          setMessageError(() => error.message);
          break;
        case "contactPhone":
          setContactPhoneError(() => error.message);
          break;
      }
    });
  };
  const handleClose = () => {
    setMessage(() => "");
    setIsSuccess(() => false);
    setReason(() => "-1");
    setReason(() => "-1");
    setContactPhone(() => "");
    setIsShown(() => false);
  };
  let modalBody = null;
  let modalControls = null;

  if (isLoading) {
    modalBody = (
      <div className="row">
        <div className="col d-flex align-items-center justify-content-center" style={{height:'60px'}}>
          <Loader />
        </div>
      </div>
    );
    modalControls = <></>;
  } else if (isSuccess) {
    modalBody = (
      <div className="row">
        <div className="col">
          <div className="alert alert-success" role="alert">
            Вашето съобщение беше изпратено успешно!
          </div>
        </div>
      </div>
    );
    modalControls = (
      <>
        <div className="col-6 offset-3">
          <Button
            type="primary"
            content="Затвори"
            size="sm"
            onClick={handleClose}
          />
        </div>
      </>
    );
  } else {
    modalBody = (
      <>
        <div className="row">
          <div className="col mb-3">
            <Input
              label="Телефон за връзка"
              name="contact_phone"
              // placeholder="089123456789"
              value={contactPhone}
              onChange={handleContactPhoneChange}
            ></Input>
            {contactPhoneError && (
              <div className="text-danger mt-1">{contactPhoneError}</div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col mb-3 ">
            <Select
              id="reason"
              label="Тема"
              // TODO: from API
              options={[
                { value: "-1", label: "Избери тема" },
                { value: "1", label: "Мнение" },
                { value: "2", label: "Препоръка" },
                { value: "3", label: "Проблем" },
                { value: "4", label: "Друго" },
              ]}
              value={reason}
              onChange={(selectedReason) => {
                setReasonError(() => "");
                setReason(() => selectedReason);
              }}
            ></Select>
            {reasonError && (
              <div className="text-danger mt-1">{reasonError}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Textarea
              label="Съобщение"
              id="userMessage"
              placeholder="С какво можем да помогнем?"
              value={message}
              rows={5}
              onChange={(val) => {
                setMessageError(() => "");
                setMessage(val);
              }}
            />
            {messageError && (
              <div className="text-danger mt-1">{messageError}</div>
            )}
          </div>
        </div>
      </>
    );
    modalControls = (
      <>
        <div className="col-6">
          <Button
            type="primary"
            content="Отказ"
            size="sm"
            onClick={handleClose}
          />
        </div>

        <div className="col-6">
          <Button
            type="primary"
            content="Изпрати"
            size="sm"
            onClick={handleConfirm}
          />
        </div>
      </>
    );
  }
  const modal = isShown ? (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row justify-items-between">
                  <div className="col-auto">
                    <h5 className="modal-title">Обратна връзка</h5>
                  </div>
                  <div className="col-auto ms-auto">
                    <Button onClick={handleClose} type="primary" size="sm">
                      <Icon name="x" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="container-fluid">{modalBody}</div>
            </div>
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row">{modalControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={handleClose}></div>
    </>
  ) : (
    <></>
  );
  return (
    <>
      {modal}
      <Button
        type="primary"
        className="Button-contact-us"
        onClick={() => setIsShown(() => true)}
      >
        <Icon name="envelope-at" />
      </Button>
    </>
  );
};
export default ContactUsForm;
