import { useMemo, useState } from "react";
import Input from "../../common/Input/Input";
import Alert from "../../common/Alert/Alert";
import Loader from "../../common/Loader/Loader";
import Button from "../../common/Button/Button";
import { DecodedVinResponse, ProductTypeT } from "../../../api/types";
import { decodeVIN, openPDFGeneration } from "../../../api/main";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { ErrorResponse } from "react-router-dom";

const MAX_SHOWN_ITEMS = 14;

/**
 * Helper function building the given item from the decoded VIN response
 *
 * @param item
 * @param idx
 * @returns
 */
const buildItem = (item: any, idx: string) => {
  return (
    <div className="col-md-6 col-lg-6 col-xlg-4 " key={idx}>
      <div className="row  p-2 m-1  h-100 justify-content-between border-bottom border-bottom-themed">
        <div className="col-4 align-self-center">
          <strong>{item.name}</strong>
        </div>
        <div className="col-8 align-self-center text-end">{item.value}</div>
      </div>
    </div>
  );
};

interface VINInputProps {
  children?: React.ReactNode;
  product: ProductTypeT;
  onDecode?: (decodedInfo: any, vin: string, error?: string) => void;
  onChange?: (vin: string) => void;
  configuration?: any;
  withConfirmation?: boolean;
  regNumber?: string;
  withoutVisualisation?: boolean;
  withFreeDecoding?: boolean;
}
const VIN_REGEX = /^([A-HJ-NPR-Z0-9]){0,17}$/;

const VINInput = ({
  children,
  product,
  onDecode,
  configuration,
  withConfirmation,
  onChange,
  regNumber,
  withoutVisualisation = false,
  withFreeDecoding = false,
}: VINInputProps) => {
  const [vin, setVin] = useState<string>("");
  const [vinForPrint, setVinForPrint] = useState<string>("");
  const [newVin, setNewVin] = useState<boolean>(true);
  const [vinHistory, setVinHistory] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidVIN, setIsValidVIN] = useState<boolean>(true);
  const [vinErrorMessage, setVinErrorMessage] = useState<string>("");
  const [decodedInfo, setDecodedInfo] = useState<DecodedVinResponse | null>(
    null
  );

  const [showMore, setShowMore] = useState<boolean>(false);

  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);

  const costText = useMemo(() => {
    return configuration?.cost && !configuration?.licensed ? (
      <span className="text-price">Цена: {configuration.cost} лв.</span>
    ) : (
      ""
    );
  }, [configuration]);

  const handlePDFGeneration = () => {
    openPDFGeneration("vin", { vin: vinForPrint, product });
  };

  const handleVinInput = (value: any) => {
    if (isValidVIN === false) {
      setIsValidVIN(true);
    }
    const newVinInput = (value as string).toUpperCase();
    if (newVinInput.length > 17) return;
    if (!VIN_REGEX.test(newVinInput)) return;

    setVin(() => newVinInput);
    onChange && onChange(newVinInput);
    if (!newVin) setNewVin(() => true);
  };

  const handleDecoding = async (useFreeDecoder: boolean = false) => {
    if (!newVin) return;

    setVinErrorMessage(() => "");
    setDecodedInfo(() => null);
    setIsValidVIN(() => true);
    setShowMore(() => false);

    if (!vin) return;
    if (!VIN_REGEX.test(vin) || vin.length !== 17) {
      setVinErrorMessage("Невалиден VIN номер.");
      setIsValidVIN(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await decodeVIN(product, vin, regNumber, useFreeDecoder);

      if ("error" in response) {
        setVinErrorMessage(response.error.message);
        setIsLoading(false);
        return;
      }

      setDecodedInfo(() => response as DecodedVinResponse);

      if (vinHistory.indexOf(vin) === -1 && useFreeDecoder === false) {
        setVinHistory((old) => [...old, vin]);
      }
      onDecode && onDecode(response, vin);
      setVinForPrint(() => vin);
    } catch (e: any) {
      if ("error" in e.response.data) {
        setIsValidVIN(false);
        setVinErrorMessage(e.response.data.error.message);
        setIsLoading(false);
        setDecodedInfo(() => null);
      }
    }
    if (newVin) setNewVin(() => false);
    setIsLoading(false);
  };

  const showConfirmModal = () => {
    if (!newVin) return;
    if (!vin) return;

    if (vinHistory.indexOf(vin) !== -1) {
      handleDecoding();
      return;
    }

    setIsConfirmModalVisible(() => true);
  };

  const decodedVinDataBlock = useMemo(() => {
    if (!decodedInfo) return <></>;

    const numShownItems = Math.min(decodedInfo.length, MAX_SHOWN_ITEMS);

    const shownItems = [];
    const hiddenItems = [];

    for (let idx = 0; idx < numShownItems; idx++) {
      const item = decodedInfo[idx];
      if (!item["name"]) continue;
      shownItems.push(buildItem(item, idx.toString()));
    }

    for (let idx = numShownItems; idx < decodedInfo.length; idx++) {
      const item = decodedInfo[idx];
      if (!item["name"]) continue;
      hiddenItems.push(buildItem(item, idx.toString()));
    }

    return (
      <div className="row">
        <div className="col">
          <div className="row">{shownItems}</div>
          {hiddenItems.length > 0 ? (
            <div className="row mt-3">
              <div className="col">
                <div className="row justify-content-center ">
                  <div className="col-auto">
                    <Button
                      type="primary"
                      content={showMore ? "Скрий" : "Покажи още"}
                      onClick={() => setShowMore((old) => !old)}
                    />
                  </div>
                </div>
                <div className={`row mt-3 ${showMore ? "" : "d-none"}`}>
                  {hiddenItems}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }, [decodedInfo, showMore]);

  return (
    <div className="row">
      {withConfirmation ? (
        <ConfirmationModal
          title="Платена услуга"
          onClose={() => setIsConfirmModalVisible(() => false)}
          onConfirm={() => {
            handleDecoding();
            setIsConfirmModalVisible(() => false);
          }}
          message={
            <div>
              Сигурни ли сте, че искате да продължите? <br />
              {costText}
            </div>
          }
          isShown={isConfirmModalVisible}
        />
      ) : (
        <></>
      )}
      <div className="col ">
        <div className="row mb-2 justify-content-center">
          {children}
          <div className={withFreeDecoding ? "col" : "col-lg-5 col-md-7 "}>
            <div className="row align-items-end">
              <div className="col">
                <Input
                  label="Декодиране на VIN"
                  name="vin"
                  value={vin}
                  // placeholder="Въведете вин"
                  onChange={handleVinInput}
                />
              </div>
              <div className={`col-md-4 ${withFreeDecoding ? "" : "mt-2"}`}>
                <Button
                  type="primary"
                  content={withFreeDecoding ? "Точно декодиране" : "Декодирай"}
                  disabled={isLoading}
                  size="sm"
                  onClick={withConfirmation ? showConfirmModal : ()=>handleDecoding(false)}
                />
              </div>
              {withFreeDecoding && (
                <div className="col-md-4">
                  <Button
                    type="primary"
                    content="Приблизително декодиране"
                    disabled={isLoading}
                    size="sm"
                    onClick={() => handleDecoding(true)}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                {vinErrorMessage !== "" && (
                  <div className="mt-2">
                    <Alert type="danger">{vinErrorMessage}</Alert>
                  </div>
                )}
                <p className="text-muted my-0">
                  Въведени {vin.length}/17 символа.
                </p>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div
            className={`row p-5 mb-3 ${
              !withoutVisualisation ? "border rounded-1 border-dark " : ""
            }`}
          >
            <div className="col">
              <Loader />
            </div>
          </div>
        ) : (
          <></>
        )}
        {decodedInfo && !isLoading && !withoutVisualisation ? (
          <div className="row border rounded-1 border-dark mb-3 pb-3 pt-2">
            <div className="col">
              <div className="row">{decodedVinDataBlock}</div>
              <div className="row justify-content-end mt-4">
                <div className="col-auto">
                  <Button
                    type="primary"
                    size="md"
                    onClick={handlePDFGeneration}
                  >
                    Печат
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default VINInput;
