import { useEffect, useState } from "react";

import topLeftCorner from "../../../resources/assets/top-left-poly.svg";
import topRightCorner from "../../../resources/assets/top-right-poly.svg";
import triangleCirle from "../../../resources/assets/triangle-circle.svg";
import triangleUpDown from "../../../resources/assets/triangle-up-down-mini-content.svg";
import trianglesMiniContent from "../../../resources/assets/triangles-mini-content.svg";
import topLeftContentMiniCorner from "../../../resources/assets/top-left-mini-content-poly-second.svg";
import topLeftContentMiniCornerMobile from "../../../resources/assets/top-left-mini-content-poly.svg";
import bottomRightContentMiniCornerMobile from "../../../resources/assets/bottom-right-mini-content-poly.svg";
import topLeftContentCorner from "../../../resources/assets/top-left-mini-poly.svg";
import bottomRightContentCorner from "../../../resources/assets/bottom-right-content-poly.svg";
import topLeftContentSecondCorner from "../../../resources/assets/top-left-content-poly.svg";
import bottomRightContentSecondCorner from "../../../resources/assets/bottom-right-mini-second-content-poly.svg";
import bottomRightMiniContentCorner from "../../../resources/assets/bottom-right-mini-triangle-content-poly.svg";

import styles from "./Home.module.css";
import InfoCard from "./components/InfoCard/InfoCard";
import DBStatCard from "../../components/DBStatCard/DBStatCard";
import PartnerLogos from "./components/PartnerLogos/PartnerLogos";
import HeadingSlider from "./components/HeadingSlider/HeadingSlider";
import { getStatistics } from "../../api/main";
import Loader from "../../components/common/Loader/Loader";
import { formatStatistics } from "../../utils/helpers";
import { SystemStatistics } from "../../utils/types";
import DBStatProgress from "../../components/DBStatProgress/DBStatProgress";
import useAuthContext from "../../context/authentication";

const Home = () => {
  useEffect(() => {
    document.title = "Начало | AutoExpert International";
  }, []);
  const [stats, setStats] = useState<SystemStatistics | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuthContext();

  const fetchStatistics = async () => {
    setIsLoading(true);
    const response = await getStatistics();

    setStats(formatStatistics(response));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <HeadingSlider />
      </div>
      <div className="container pt-0 aei-fonts">
        <img
          alt="floating asset"
          src={topLeftCorner}
          className={`position-absolute ${styles["left-corner"]}`}
        />
        <img
          alt="floating asset"
          src={topRightCorner}
          className={`position-absolute ${styles["right-corner"]}`}
        />

        <div className="row">
          <div className="col-xs-12">
            <h1 className={`text-center fw-bold ${styles["main-title"]}`}>
              Софтуер за нашите клиенти
            </h1>
          </div>
        </div>
        <div className="row gx-4 align-items-stretch ">
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert VIN"}
              asset={
                <>
                  <img
                    alt="floating asset"
                    src={topLeftContentMiniCornerMobile}
                    className={`position-absolute d-none d-md-block ${styles["top-left-mini-content-corner-mobile"]}`}
                  />
                  <img
                    alt="floating asset"
                    src={bottomRightContentMiniCornerMobile}
                    className={`position-absolute d-none d-md-block ${styles["bottom-right-mini-content-corner-mobile"]}`}
                  />
                </>
              }
              collapse_asset={
                <>
                  <img
                    alt="floating asset"
                    src={topLeftContentMiniCornerMobile}
                    className={`position-absolute d-block d-md-none ${styles["top-left-mini-content-corner-mobile"]}`}
                  />
                  <img
                    alt="floating asset"
                    src={bottomRightContentMiniCornerMobile}
                    className={`position-absolute d-block d-md-none ${styles["bottom-right-mini-content-corner-mobile"]}`}
                  />
                </>
              }
              redirectTo={user ? "/aei-vin" : "/info/aei-vin"}
              content={
                <>
                  <p>
                    AutoExpert VIN ще Ви помогне да идентифицирате автомобила по
                    неговия номер на шаси/рама/VIN (Vehicle Identification
                    Number).
                  </p>
                </>
              }
            />
          </div>
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert New"}
              asset={
                <img
                  alt="floating asset"
                  src={bottomRightContentSecondCorner}
                  className={`position-absolute d-none d-md-block ${styles["bottom-right-second-mini-content-corner"]}`}
                />
              }
              collapse_asset={
                <>
                  <img
                    alt="floating asset"
                    src={bottomRightMiniContentCorner}
                    className={`position-absolute d-block d-md-none ${styles["bottom-right-triangles-mini-content-mobile"]}`}
                  />
                </>
              }
              redirectTo={user ? "/aei-new" : "/info/aei-new"}
              content={
                <>
                  <p>
                    Със своите 40+ технически данни за автомобилите, този
                    продукт и AutoExpert VIN ще Ви помогне за идентифициране на
                    автомобили.
                  </p>
                  <p>
                    Информацията, която ще намерите тук е посочена като източник
                    за изчисляване на действителна стойност в наредба 49 на КФН
                    към МФ на Република България.
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className="row gx-4 align-items-stretch mt-0">
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert Easy"}
              asset={
                <img
                  alt="floating asset"
                  src={topLeftContentCorner}
                  className={`position-absolute d-none d-md-block ${styles["top-left-mini-content-corner"]}`}
                />
              }
              redirectTo={user ? "/aei-easy" : "/info/aei-easy"}
              collapse_asset={
                <img
                  alt="floating asset"
                  src={topLeftContentMiniCorner}
                  className={`position-absolute d-block d-md-none ${styles["top-left-mini-content-corner-second"]}`}
                />
              }
              content={
                <>
                  <p>
                    Този софтуер е предназначен за изготвяне на автотехнически
                    експертизи (АТЕ). Само в 2 прозореца се изготвя
                    професионална АТЕ. В първият прозорец се извършва
                    идентификация на превозното средство, а във втория -
                    изчисляване на неговата действителна стойност.
                  </p>
                  <p>
                    Действителната стойност може да бъде изчислена по 2 метода:
                    метод на АЕИ и метод по наредба 49 на КФН на Република
                    България.
                  </p>
                </>
              }
            />
          </div>
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert MarketPrice"}
              asset={
                <img
                  alt="floating asset"
                  src={bottomRightContentCorner}
                  className={`position-absolute d-none d-md-block ${styles["bottom-right-mini-content-corner"]}`}
                />
              }
              collapse_asset={
                <img
                  alt="floating asset"
                  src={triangleCirle}
                  className={`position-absolute d-block d-md-none ${styles["triangle-circle"]}`}
                />
              }
              redirectTo={user ? "/aei-market-price" : "/info/aei-market-price"}
              content={
                <>
                  <p>
                    Този продукт е предназначен за определяне на най-вероятната
                    пазарна стойност на употребявани автомобили.
                  </p>
                  <p>
                    Той може да работи както самостоятелно, така и съвместно с
                    всички останали продукти на АЕИ.
                  </p>
                </>
              }
            />
          </div>
        </div>

        <div className="row gx-4 align-items-stretch mb-4">
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert VIP"}
              collapse_asset={
                <>
                  <img
                    alt="floating asset"
                    src={trianglesMiniContent}
                    className={`position-absolute d-block d-md-none ${styles["triangles-mini-content-mobile"]}`}
                  />
                </>
              }
              asset={
                <img
                  alt="floating asset"
                  src={topLeftContentSecondCorner}
                  className={`position-absolute d-none d-md-block ${styles["top-left-second-mini-content-corner"]}`}
                />
              }
              content={
                <>
                  <p>
                    Този продукт е предназначен за фирми, които имат нужда от
                    информацията на АЕИ, но не желаят да я имплементират в свои
                    системи, уеб сайтове и приложения.
                  </p>
                  <p>
                    Този продукт е къстъмизирана версия на всички продукти на
                    АЕИ. За разлика от AutoExpert API, продукта се намира на
                    сървъри на АЕИ и АЕИ се грижи за неговата поддръжка и
                    актуализация.
                  </p>
                </>
              }
            />
          </div>
          <div className="col-md-6 mb-4">
            <InfoCard
              title={"AutoExpert API"}
              asset={
                <img
                  alt="floating asset"
                  src={triangleUpDown}
                  className={`position-absolute d-none d-md-block ${styles["triangle-up-down"]}`}
                />
              }
              collapse_asset={
                <>
                  <img
                    alt="floating asset"
                    src={triangleUpDown}
                    className={`position-absolute d-block d-md-none ${styles["triangle-up-down-mobile"]}`}
                  />
                </>
              }
              content={
                <>
                  <p>
                    API (Application Programming Interface) е набор от
                    дефинирани правила и протоколи, които позволяват различни
                    софтуерни приложения да комуникират помежду си.
                  </p>
                  <p>
                    Чрез AutoExpert API Вашата фирма може да има достъп до
                    базите данни на АутоЕксперт Интернешънъл и да я визуализират
                    в свои приложения и софтуер.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="my-0 my-md-5 ">
        <div className="container pt-0 pt-mb-5">
          <div className="row mb-4">
            <div className="col text-center">
              <h2 className={`${styles["portfolio-title"]} fw-bold`}>
                Нашата база от данни в числа
              </h2>
            </div>
          </div>
          {isLoading ? (
            <div className="row text-center justify-content-around">
              <Loader />
            </div>
          ) : (
            <>
              <div className="d-none d-md-block">
                <div className="row text-center justify-content-around gx-5">
                  <div className="col-lg-3 col-md-6">
                    <DBStatCard title="МАРКИ" value={stats?.makeCount || ""} />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <DBStatCard
                      title="МОДЕЛИ"
                      value={stats?.modelCount || ""}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <DBStatCard
                      title="ПОДМОДЕЛИ"
                      value={stats?.submodelCount || ""}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <DBStatCard title="ЦЕНИ" value={stats?.priceCount || ""} />
                  </div>
                </div>
              </div>
              <div className="d-md-none">
                <div className="row">
                  <div className="col-12">
                    <DBStatProgress
                      title="МАРКИ КОЛИ"
                      value={stats?.makeCount || ""}
                      percentage={70}
                    />
                  </div>
                  <div className="col-12">
                    <DBStatProgress
                      title="МОДЕЛИ"
                      value={stats?.modelCount || ""}
                      percentage={80}
                    />
                  </div>
                  <div className="col-12">
                    <DBStatProgress
                      title="ПОДМОДЕЛИ"
                      value={stats?.submodelCount || ""}
                      percentage={88}
                    />
                  </div>
                  <div className="col-12">
                    <DBStatProgress
                      title="ЦЕНИ"
                      value={stats?.priceCount || ""}
                      percentage={98}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-0 mt-lg-5 ">
        <div className="container pt-lg-5 pt-0 pb-5">
          <div className="row mb-4">
            <div className="col text-center">
              <h2 className={`${styles["partners-title"]} fw-bold`}>
                Част от нашите партньори
              </h2>
            </div>
          </div>
          <PartnerLogos />
        </div>
      </div>
    </>
  );
};

export default Home;
