import { Fragment } from "react";
import { Header } from "./../components";
import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../context/authentication";
import ContactUsForm from "../components/page-components/ContactUsForm/ContactUsForm";
import { SidebarMenu } from "../components/page-components";
import { ErrorProvider } from "../context/error";

const ProfileLayout = () => {
  const { nightMode } = useAuthContext();
  return (
    <Fragment>
      <Header></Header>
      <div
        className={`${
          nightMode ? "Theme-dark" : "Theme-light"
        } main-container position-relative`}
      >
        <main className="container-fluid" style={{ minHeight: "100vh" }}>
          <ErrorProvider>
            <ContactUsForm />
            <SidebarMenu />
            <Outlet />
          </ErrorProvider>
        </main>
      </div>
    </Fragment>
  );
};

export default ProfileLayout;
